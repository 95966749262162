import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import CloseIcon from "@mui/icons-material/Close";

const PopupFS = ({ title, children, open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Dialog
      fullScreen
      open={open}
      fullWidth
      sx={{
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiPaper-root": {
                background: colors.grey[900],
              },
            }
          : {
              "& .MuiPaper-root": {
                background: "#fcfcfc",
              },
            }),
      }}
    >
      <DialogTitle>
        <Typography variant="h4" color={colors.grey[100]}>
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: colors.grey[100],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default PopupFS;
