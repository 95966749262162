import {
  Box,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDataContext} from "../../../context/DataContext";


const ParticipacionEsperada = ({
  actividadID,
  touchedParticipacionEsperada,
  errorsParticipacionEsperada,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const {
    participantesEsperados,
    rowPairs, setRowPairs } =
    useDataContext();
    const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);

    const handleParticipacionEsperadaChange = (event) => {
      setRowPairs((prevRowPairs) =>
        prevRowPairs.map((actividad) =>
          actividad.id === actividadID
            ? { ...actividad, selectedParticipantesEsperados: event.target.value }
            : actividad
        )
      );
    };
  


  return (
    <Box
      height={isNonMobile ? "55vh" : isNonMobileMedium ? "55vh" : "50vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "0px" : "10px"}
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      rowGap={1}
      sx={{
        "& > div": {
          gridColumn: isNonMobile ? undefined : "span 4",
        },
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
            fontWeight="bold"
          >
            11. Participación Esperada
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                11.1. Número de participantes esperados
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                select
                SelectProps={{
                  sx: { fontSize: isNonMobile ? undefined : "12px" },
                }}
                label="Participantes esperados"
                onChange={handleParticipacionEsperadaChange}
                value={actividadData.selectedParticipantesEsperados}
                name="participacionEsperada"
                error={
                  !!touchedParticipacionEsperada &&
                  !!errorsParticipacionEsperada
                }
                helperText={
                  touchedParticipacionEsperada && errorsParticipacionEsperada
                }
              >
                {participantesEsperados.map((participantesEsperados) => (
                  <MenuItem
                    key={participantesEsperados.id}
                    value={participantesEsperados.id}
                  >
                    {participantesEsperados.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ParticipacionEsperada;
