import { Box, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardsEval from "../../../components/CardEval";
import GradingIcon from "@mui/icons-material/Grading";
import ChecklistIcon from "@mui/icons-material/Checklist";
import BookIcon from "@mui/icons-material/Book";
import { useDataContext } from "../../../context/DataContext";

const InstrumentoDeEvaluacion = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { instrumentoEvaluacion } = useDataContext();

  const idEval1 = instrumentoEvaluacion.filter(
    (instrumentoEvaluacion) => instrumentoEvaluacion.id === 1
  );
  const idEval2 = instrumentoEvaluacion.filter(
    (instrumentoEvaluacion) => instrumentoEvaluacion.id === 2
  );
  const idEval4 = instrumentoEvaluacion.filter(
    (instrumentoEvaluacion) => instrumentoEvaluacion.id === 4
  );

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      rowGap={1}
      gridTemplateColumns="100%"
      gridTemplateRows={
        isNonMobile
          ? "150px auto"
          : isNonMobileMedium
          ? "150px auto"
          : "100px auto"
      }
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Typography
          variant={isNonMobile ? "h4" : "h5"}
          color={colors.grey[100]}
          marginBottom={isNonMobile ? "20px" : "5px"}
          fontWeight="bold"
        >
          18. Instrumento de Evaluación
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Stack direction="row" columnGap={10}>
          {idEval1.map((instrumentoEvaluacion, id) => (
            <CardsEval
              variant="h5"
              evalTitle="ENCUESTA DE SATISFACCIÓN"
              bottomCheckbox="30px"
              titleLeft={isNonMobile ? "0px" : "0px"}
              evalIcon={
                <GradingIcon
                  style={{ fontSize: isNonMobile ? "100px" : "80px" }}
                />
              }
              evalKey={instrumentoEvaluacion.id}
              valueCheckbox={instrumentoEvaluacion.id}
            />
          ))}
          {idEval2.map((instrumentoEvaluacion, id) => (
            <CardsEval
              variant="h5"
              evalTitle="ENCUESTA DE BIDIRECCIONALIDAD"
              bottomCheckbox="23px"
              titleLeft={isNonMobile ? "0px" : "-9px"}
              evalIcon={
                <ChecklistIcon
                  style={{ fontSize: isNonMobile ? "100px" : "80px" }}
                />
              }
              evalKey={instrumentoEvaluacion.id}
              valueCheckbox={instrumentoEvaluacion.id}
            />
          ))}
          {/* {idEval3.map((instrumentoEvaluacion, id) => (
            <CardsEval
              bottomTitle="10px"
              titleLeft={isNonMobile ? "0px" : "0px"}
              bottomIcon="18px"
              bottomCheckbox="55px"
              evalTitle="LISTA DE COTEJO DE APRENDIZAJES"
              evalIcon={
                <FormatListNumberedIcon
                  style={{ fontSize: isNonMobile ? "100px" : "80px" }}
                />
              }
              evalKey={instrumentoEvaluacion.id}
              valueCheckbox={instrumentoEvaluacion.id}
            />
          ))} */}
          {idEval4.map((instrumentoEvaluacion, id) => (
            <CardsEval
              variant="h5"
              titleLeft={isNonMobile ? "0px" : "0px"}
              bottomCheckbox="30px"
              evalTitle="ENCUENTRO DE ANÁLISIS CUALITATIVO"
              evalIcon={
                <BookIcon
                  style={{ fontSize: isNonMobile ? "85px" : "70px" }}
                />
              }
              evalKey={instrumentoEvaluacion.id}
              valueCheckbox={instrumentoEvaluacion.id}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default InstrumentoDeEvaluacion;
