import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Typography,
  useTheme,
  Grow,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { tokens } from "../theme";
import CloseIcon from "@mui/icons-material/Close";
const initialFormState = {
  estudiantes: "",
  docentes: "",
  colaboradores: "",
  investigadores: "",
  externos: "",
  gradoConformidadInterno: "",
  gradoConformidadExterno: "",
  pais: "",
  region: "",
  ciudad: "",
  ciudadesSeleccionadas: [],
  participacionMedio: {
    codiseno: false,
    complementacion: false,
    coevaluacion: false,
    retroalimentacion: false,
    beneficiario: false,
  },
  asignaturasAprobacion: [],
  resultadosAprendizaje: "",
  objetivosLogrados: "",
  resultadosAlcanzados: "",
};
const PopupSeguimiento = ({ open, onClose, actividadId, actividadCode }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [archivosAdjuntos, setArchivosAdjuntos] = useState([]);
  const fileInputRef = useRef(null);
  const [actividadData, setActividadData] = useState(null);
  const [asignaturas, setAsignaturas] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [paises, setPaises] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [camposFaltantes, setCamposFaltantes] = useState([]);
  const [formData, setFormData] = useState(initialFormState);

  const MAX_FILE_SIZE = 20 * 1024 * 1024;
  const ALLOWED_TYPES = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const cargarPaises = async () => {
    try {
      console.log("ingreso a carga d epais");
      const response = await axios.get("https://vime.usek.cl/api/pais");
      setPaises(response.data);
    } catch (error) {
      console.error("Error al cargar países:", error);
    }
  };

  const cargarRegiones = async (paisId) => {
    try {
      const response = await axios.get(
        `https://vime.usek.cl/api/region/pais/${paisId}`
      );
      setRegiones(response.data);
    } catch (error) {
      console.error("Error al cargar regiones:", error);
    }
  };

  const cargarCiudades = async (regionId) => {
    try {
      const response = await axios.get(
        `https://vime.usek.cl/api/ciudad/region/${regionId}`
      );
      setCiudades(response.data);
    } catch (error) {
      console.error("Error al cargar ciudades:", error);
    }
  };
  useEffect(() => {
    cargarPaises();
  }, []);
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        alert(`El archivo ${file.name} excede el tamaño máximo de 5MB.`);
        return false;
      }
      if (!ALLOWED_TYPES.includes(file.type)) {
        alert(`El tipo de archivo de ${file.name} no está permitido.`);
        return false;
      }
      return true;
    });

    setArchivosAdjuntos((prev) => [...prev, ...validFiles]);
  };

  useEffect(() => {
    const fetchActividadData = async () => {
      if (open && actividadId) {
        setFormData(initialFormState);
        setArchivosAdjuntos([]);
        setCamposFaltantes([]);
        try {
          let url;
          if (actividadCode === 2) {
            url = `https://vime.usek.cl/api/segimientoactividadpid/${actividadId}`;
          } else if (actividadCode === 1) {
            url = `https://vime.usek.cl/api/segimientoactividadid/${actividadId}`;
          }

          if (url) {
            const response = await axios.get(url);
            setActividadData(response.data);
            // Aquí puedes actualizar el estado formData con los datos recibidos
            updateFormData(response.data);
          }
        } catch (error) {
          console.error("Error fetching actividad data:", error);
        }
      }
    };

    fetchActividadData();
  }, [open, actividadId, actividadCode]);

  const updateFormData = (data) => {
    // Reiniciar el formulario con el estado inicial
    setFormData(initialFormState);

    // Si data es un array, asumimos que son asignaturas
    if (Array.isArray(data)) {
      setAsignaturas(
        data.map((asignatura) => ({
          id: asignatura.id,
          id_carrera: asignatura.idcarrera,
          nombre_asignatura: asignatura.asignatura,
          idasignatura: asignatura.idasignatura,
        }))
      );
    } else {
      // Si data no es un array, asumimos que son los datos de la actividad
      setFormData((prevData) => ({
        ...prevData,
        estudiantes: data.estudiantes || "",
        docentes: data.docentes || "",
        colaboradores: data.colaboradores || "",
        investigadores: data.investigadores || "",
        externos: data.externos || "",
        gradoConformidadInterno: data.gradoConformidadInterno || "",
        gradoConformidadExterno: data.gradoConformidadExterno || "",
        pais: data.pais || "",
        region: data.region || "",
        ciudad: data.ciudad || "",
        ciudadesSeleccionadas: data.ciudadesSeleccionadas || [],
        participacionMedio: {
          codiseno: data.participacionMedio?.codiseno || false,
          complementacion: data.participacionMedio?.complementacion || false,
          coevaluacion: data.participacionMedio?.coevaluacion || false,
          retroalimentacion:
            data.participacionMedio?.retroalimentacion || false,
          beneficiario: data.participacionMedio?.beneficiario || false,
        },
        asignaturasAprobacion: data.asignaturasAprobacion || [],
        resultadosAprendizaje: data.resultadosAprendizaje || "",
        objetivosLogrados: data.objetivosLogrados || "",
        resultadosAlcanzados: data.resultadosAlcanzados || "",
      }));

      // Si hay asignaturas en los datos de la actividad, actualizarlas también
      if (Array.isArray(data.asignaturas)) {
        setAsignaturas(
          data.asignaturas.map((asignatura) => ({
            id: asignatura.id,
            id_carrera: asignatura.idcarrera,
            nombre_asignatura: asignatura.asignatura,
            idasignatura: asignatura.idasignatura,
          }))
        );
      }
    }

    // Reiniciar campos faltantes
    setCamposFaltantes([]);
  };
  const handleRemoveFile = (fileName) => {
    setArchivosAdjuntos((prev) =>
      prev.filter((file) => file.name !== fileName)
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Actualizar formData
    if (
      [
        "estudiantes",
        "docentes",
        "colaboradores",
        "investigadores",
        "externos",
      ].includes(name)
    ) {
      // Lógica para campos numéricos
      const numValue =
        value === "" ? "" : Math.min(100000, Math.max(0, parseInt(value) || 0));
      setFormData((prev) => ({ ...prev, [name]: numValue.toString() }));
    } else if (name.startsWith("participacionMedio.")) {
      // Lógica para participacionMedio
      const subField = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        participacionMedio: {
          ...prev.participacionMedio,
          [subField]: value,
        },
      }));
    } else {
      // Lógica para otros campos
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    // Actualizar campos faltantes
    setCamposFaltantes((prevCampos) => {
      let newCampos = [...prevCampos];

      // Número de participantes
      const participantesFields = [
        "estudiantes",
        "docentes",
        "colaboradores",
        "investigadores",
        "externos",
      ];
      if (participantesFields.includes(name)) {
        const allParticipantesFilled = participantesFields.every(
          (field) => formData[field] !== "" || (field === name && value !== "")
        );
        if (allParticipantesFilled) {
          newCampos = newCampos.filter(
            (campo) => campo !== "Número de participantes"
          );
        } else if (!newCampos.includes("Número de participantes")) {
          newCampos.push("Número de participantes");
        }
      }

      // Grado de satisfacción
      if (
        name === "gradoConformidadInterno" ||
        name === "gradoConformidadExterno"
      ) {
        if (
          formData.gradoConformidadInterno !== "" &&
          formData.gradoConformidadExterno !== "" &&
          (name === "gradoConformidadInterno"
            ? value !== ""
            : formData.gradoConformidadInterno !== "") &&
          (name === "gradoConformidadExterno"
            ? value !== ""
            : formData.gradoConformidadExterno !== "")
        ) {
          newCampos = newCampos.filter(
            (campo) => campo !== "Grado de satisfacción"
          );
        } else if (!newCampos.includes("Grado de satisfacción")) {
          newCampos.push("Grado de satisfacción");
        }
      }

      // Participación del medio
      if (name.startsWith("participacionMedio.")) {
        const subField = name.split(".")[1];
        const updatedParticipacionMedio = {
          ...formData.participacionMedio,
          [subField]: value,
        };
        setFormData((prev) => ({
          ...prev,
          participacionMedio: updatedParticipacionMedio,
        }));

        // Actualizar campos faltantes
        const anyParticipacionChecked = Object.values(
          updatedParticipacionMedio
        ).some((v) => v);
        setCamposFaltantes((prev) => {
          if (anyParticipacionChecked) {
            return prev.filter((campo) => campo !== "Participación del medio");
          } else if (!prev.includes("Participación del medio")) {
            return [...prev, "Participación del medio"];
          }
          return prev;
        });
      }

      // Campos de texto largo
      const longTextFields = {
        resultadosAprendizaje: "Resultados de aprendizaje",
        objetivosLogrados: "Objetivos logrados",
        resultadosAlcanzados: "Resultados alcanzados",
      };
      if (name in longTextFields) {
        if (value !== "") {
          newCampos = newCampos.filter(
            (campo) => campo !== longTextFields[name]
          );
        } else if (!newCampos.includes(longTextFields[name])) {
          newCampos.push(longTextFields[name]);
        }
      }

      return newCampos;
    });

    // Lógica adicional para cargar regiones y ciudades
    if (name === "pais") {
      cargarRegiones(value);
      setFormData((prev) => ({ ...prev, region: "", ciudad: "" }));
      setCiudades([]);
    } else if (name === "region") {
      cargarCiudades(value);
      setFormData((prev) => ({ ...prev, ciudad: "" }));
    }
  };

  useEffect(() => {
    // Validar Alcance territorial
    if (formData.ciudadesSeleccionadas.length > 0) {
      setCamposFaltantes((prev) =>
        prev.filter((campo) => campo !== "Alcance territorial")
      );
    }

    // Validar Participación del medio
    const anyParticipacionChecked = Object.values(
      formData.participacionMedio
    ).some((v) => v);
    if (anyParticipacionChecked) {
      setCamposFaltantes((prev) =>
        prev.filter((campo) => campo !== "Participación del medio")
      );
    }
  }, [formData.ciudadesSeleccionadas, formData.participacionMedio]);

  const handleAsignaturaChange = (event) => {
    const asignaturaId = event.target.value;
    const asignaturaSeleccionada = asignaturas.find(
      (a) => a.idasignatura === asignaturaId
    );

    if (asignaturaSeleccionada) {
      // Verificar si la asignatura ya está en la lista
      const yaExiste = formData.asignaturasAprobacion.some(
        (a) => a.idasignatura === asignaturaSeleccionada.idasignatura
      );

      if (!yaExiste) {
        setFormData((prev) => ({
          ...prev,
          asignaturasAprobacion: [
            ...prev.asignaturasAprobacion,
            {
              ...asignaturaSeleccionada,
              porcentajeAprobacion: "",
            },
          ],
        }));
      } else {
        // Mostrar un mensaje al usuario de que la asignatura ya está en la lista
        alert("Esta asignatura ya ha sido seleccionada.");
      }
    }

    // Resetear el valor del select después de la selección
    event.target.value = "";
  };

  const handlePorcentajeAprobacionChange = (idasignatura, porcentaje) => {
    const porcentajeNumerico = Math.min(
      100,
      Math.max(0, parseInt(porcentaje) || 0)
    );
    setFormData((prev) => ({
      ...prev,
      asignaturasAprobacion: prev.asignaturasAprobacion.map((asignatura) =>
        asignatura.idasignatura === idasignatura
          ? {
              ...asignatura,
              porcentajeAprobacion: porcentajeNumerico.toString(),
            }
          : asignatura
      ),
    }));
  };

  const handleRemoveAsignatura = (idasignatura) => {
    setFormData((prev) => ({
      ...prev,
      asignaturasAprobacion: prev.asignaturasAprobacion.filter(
        (asignatura) => asignatura.idasignatura !== idasignatura
      ),
    }));
  };

  const handleAgregarCiudad = () => {
    if (formData.pais && formData.region && formData.ciudad) {
      const nuevaCiudad = `${formData.ciudad}, ${formData.region}, ${formData.pais}`;
      setFormData((prev) => ({
        ...prev,
        ciudadesSeleccionadas: [...prev.ciudadesSeleccionadas, nuevaCiudad],
        pais: "",
        region: "",
        ciudad: "",
      }));

      // Actualizar campos faltantes
      setCamposFaltantes((prev) =>
        prev.filter((campo) => campo !== "Alcance territorial")
      );
    }
  };

  const handleEliminarCiudad = (ciudadAEliminar) => {
    setFormData((prev) => ({
      ...prev,
      ciudadesSeleccionadas: prev.ciudadesSeleccionadas.filter(
        (ciudad) => ciudad !== ciudadAEliminar
      ),
    }));
  };
  const handleConfirmSubmit = async () => {
    try {
      const form = new FormData();

      // Añadir los datos del formulario
      const dataToSend = {
        ...formData,
        actividadId,
        actividadCode,
      };

      Object.keys(dataToSend).forEach((key) => {
        if (typeof dataToSend[key] === "object" && dataToSend[key] !== null) {
          // Para objetos y arrays, los convertimos a JSON string
          form.append(key, JSON.stringify(dataToSend[key]));
        } else {
          form.append(key, dataToSend[key]);
        }
      });

      // Añadir los archivos
      archivosAdjuntos.forEach((file, index) => {
        form.append("files", file);
      });

      // Enviar todo junto en una sola solicitud
      const response = await axios.post(
        "https://vime.usek.cl/api/guardarseguimiento",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Respuesta del servidor:", response.data);

      // Si todo va bien, cerramos los diálogos
      setOpenConfirmDialog(false);
      onClose(); // Cierra el popup principal

      // Opcional: Mostrar un mensaje de éxito
      window.location.href = "/formPrograma";
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      alert(
        "Hubo un error al guardar el seguimiento. Por favor, intente de nuevo."
      );
    }
  };
  const handleSubmit = () => {
    const camposFaltantes = [];

    if (
      !formData.estudiantes ||
      !formData.docentes ||
      !formData.colaboradores ||
      !formData.investigadores ||
      !formData.externos
    ) {
      camposFaltantes.push("Número de participantes");
    }
    if (
      !formData.gradoConformidadInterno ||
      !formData.gradoConformidadExterno
    ) {
      camposFaltantes.push("Grado de satisfacción");
    }
    if (formData.ciudadesSeleccionadas.length === 0) {
      camposFaltantes.push("Alcance territorial");
    }

    if (!Object.values(formData.participacionMedio).some((value) => value)) {
      camposFaltantes.push("Participación del medio");
    }
    if (!formData.resultadosAprendizaje) {
      camposFaltantes.push("Resultados de aprendizaje");
    }
    if (!formData.objetivosLogrados) {
      camposFaltantes.push("Objetivos logrados");
    }
    if (!formData.resultadosAlcanzados) {
      camposFaltantes.push("Resultados alcanzados");
    }
    if (archivosAdjuntos.length === 0) {
      camposFaltantes.push("Debe adjuntar al menos un documento");
    }
    setCamposFaltantes(camposFaltantes);

    if (camposFaltantes.length === 0) {
      setOpenConfirmDialog(true);
    }
  };
  return (
    <>
      <Dialog
        TransitionComponent={Grow}
        open={open}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            background:
              theme.palette.mode === "dark" ? colors.grey[900] : "#fcfcfc",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4" color={colors.grey[100]} fontWeight="bold">
            Seguimiento de Actividad {actividadId}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: colors.grey[100],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" sx={{ mt: 2 }}>
            <Typography variant="subtitle1">
              Número de participantes:
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="estudiantes"
                  label="Estudiantes"
                  variant="outlined"
                  value={formData.estudiantes}
                  onChange={handleChange}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0, max: 100000 },
                    inputMode: "numeric",
                  }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="docentes"
                  label="Docentes"
                  variant="outlined"
                  value={formData.docentes}
                  onChange={handleChange}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0, max: 100000 },
                    inputMode: "numeric",
                  }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="colaboradores"
                  label="Colaboradores/as"
                  variant="outlined"
                  value={formData.colaboradores}
                  onChange={handleChange}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0, max: 100000 },
                    inputMode: "numeric",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="investigadores"
                  label="Investigadores/as"
                  variant="outlined"
                  value={formData.investigadores}
                  onChange={handleChange}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0, max: 100000 },
                    inputMode: "numeric",
                  }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="externos"
                  label="Externos"
                  variant="outlined"
                  value={formData.externos}
                  onChange={handleChange}
                  type="number"
                  InputProps={{
                    inputProps: { min: 0, max: 100000 },
                    inputMode: "numeric",
                  }}
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Grado de satisfacción:
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              name="gradoConformidadInterno"
              label="Grado de conformidad interno"
              variant="outlined"
              value={formData.gradoConformidadInterno}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              name="gradoConformidadExterno"
              label="Grado de conformidad externo"
              variant="outlined"
              value={formData.gradoConformidadExterno}
              onChange={handleChange}
            />

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Alcance territorial:
            </Typography>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={3}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="pais-label">País</InputLabel>
                  <Select
                    labelId="pais-label"
                    name="pais"
                    value={formData.pais}
                    label="País"
                    onChange={handleChange}
                  >
                    {paises.map((pais) => (
                      <MenuItem key={pais.id} value={pais.id}>
                        {pais.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="region-label">Estado/Región</InputLabel>
                  <Select
                    labelId="region-label"
                    name="region"
                    value={formData.region}
                    label="Estado/Región"
                    onChange={handleChange}
                    disabled={!formData.pais}
                  >
                    {regiones.map((region) => (
                      <MenuItem key={region.id} value={region.id}>
                        {region.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="ciudad-label">Ciudad/Comuna</InputLabel>
                  <Select
                    labelId="ciudad-label"
                    name="ciudad"
                    value={formData.ciudad}
                    label="Ciudad/Comuna"
                    onChange={handleChange}
                    disabled={!formData.region}
                  >
                    {ciudades.map((ciudad) => (
                      <MenuItem key={ciudad.id} value={ciudad.id}>
                        {ciudad.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleAgregarCiudad}
                  size="small"
                  sx={{ minWidth: "80px", height: "36px" }}
                  disabled={!formData.ciudad}
                >
                  Agregar
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
              {formData.ciudadesSeleccionadas.map((ciudad, index) => (
                <Chip
                  key={index}
                  label={ciudad}
                  onDelete={() => handleEliminarCiudad(ciudad)}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Participación del medio en la gestión de la actividad por
              subcategorías:
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.participacionMedio.codiseno}
                      onChange={handleChange}
                      name="participacionMedio.codiseno"
                    />
                  }
                  label="Codiseño"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.participacionMedio.complementacion}
                      onChange={handleChange}
                      name="participacionMedio.complementacion"
                    />
                  }
                  label="Coimplementación"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.participacionMedio.coevaluacion}
                      onChange={handleChange}
                      name="participacionMedio.coevaluacion"
                    />
                  }
                  label="Coevaluación"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.participacionMedio.retroalimentacion}
                      onChange={handleChange}
                      name="participacionMedio.retroalimentacion"
                    />
                  }
                  label="Retroalimentación"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.participacionMedio.beneficiario}
                      onChange={handleChange}
                      name="participacionMedio.beneficiario"
                    />
                  }
                  label="El medio solo será beneficiario"
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Asignaturas asignadas y porcentaje de aprobación:
            </Typography>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={8}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="asignatura-label">Asignatura</InputLabel>
                  <Select
                    labelId="asignatura-label"
                    value=""
                    label="Asignatura"
                    onChange={handleAsignaturaChange}
                  >
                    {asignaturas
                      .filter(
                        (asignatura) =>
                          !formData.asignaturasAprobacion.some(
                            (a) => a.idasignatura === asignatura.idasignatura
                          )
                      )
                      .map((asignatura) => (
                        <MenuItem
                          key={asignatura.idasignatura}
                          value={asignatura.idasignatura}
                        >
                          {asignatura.nombre_asignatura}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {formData.asignaturasAprobacion.map((asignatura, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mt: 1 }}
              >
                <Typography variant="body1" sx={{ flexGrow: 1 }}>
                  {asignatura.nombre_asignatura}
                </Typography>
                <TextField
                  label="% Aprobación"
                  type="number"
                  value={asignatura.porcentajeAprobacion}
                  onChange={(e) =>
                    handlePorcentajeAprobacionChange(
                      asignatura.idasignatura,
                      e.target.value
                    )
                  }
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 100,
                    },
                  }}
                  sx={{ width: "100px", mr: 1 }}
                />
                <IconButton
                  onClick={() =>
                    handleRemoveAsignatura(asignatura.idasignatura)
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Resultados de aprendizaje enriquecidos a través de esta actividad:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              margin="normal"
              name="resultadosAprendizaje"
              label="Resultados de aprendizaje"
              variant="outlined"
              value={formData.resultadosAprendizaje}
              onChange={handleChange}
            />
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Objetivos logrados:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              margin="normal"
              name="objetivosLogrados"
              label="Objetivos logrados"
              variant="outlined"
              value={formData.objetivosLogrados}
              onChange={handleChange}
            />

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Resultados alcanzados:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              margin="normal"
              name="resultadosAlcanzados"
              label="Resultados alcanzados"
              variant="outlined"
              value={formData.resultadosAlcanzados}
              onChange={handleChange}
            />

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Documentos adjuntos:
            </Typography>
            <Typography variant="body2">
              * Esta evidencia puede incluir listados de asistencia, resultados
              de encuestas de satisfacción, planillas de Excel, entre otras que
              usted considere relevantes. Tamaño máximo por archivo: 5MB. Tipos
              permitidos: JPEG, PNG, PDF, Excel.
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <input
                type="file"
                multiple
                accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                onChange={handleFileChange}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => fileInputRef.current.click()}
                disabled={archivosAdjuntos.length >= 10} // Limitar a 10 archivos
              >
                Adjuntar documentos
              </Button>
            </Box>
            <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
              {archivosAdjuntos.map((file, index) => (
                <Chip
                  key={index}
                  label={`${file.name} (${(file.size / 1024 / 1024).toFixed(
                    2
                  )} MB)`}
                  onDelete={() => handleRemoveFile(file.name)}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            {camposFaltantes.length > 0 && (
              <Typography variant="subtitle1" color="error">
                Por favor, complete los siguientes campos:
              </Typography>
            )}
            <List>
              {camposFaltantes.map((campo, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <ErrorIcon color="error" />
                  </ListItemIcon>
                  <ListItemText primary={campo} />
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant={theme.palette.mode === "dark" ? "outlined" : "contained"}
            onClick={handleSubmit}
          >
            Guardar Seguimiento
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        TransitionComponent={Grow}
        sx={{
          "& .MuiPaper-root": {
            background:
              theme.palette.mode === "dark" ? colors.grey[800] : "#ffffff",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" color={colors.grey[100]}>
            Confirmar guardado
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color={colors.grey[100]}>
            ¿Está seguro que desea guardar este seguimiento?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmDialog(false)}
            color="primary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmSubmit}
            color="secondary"
            variant="contained"
            autoFocus
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupSeguimiento;
