import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";

const DataContext = createContext();

export const useDataContextSession = () => {
  return useContext(DataContext);
};

export const DataProviderSession = ({ children }) => {

 const [username, setUsername] = useState([]);
   const [permisos, setPermisos] = useState([]);
   const [perfil, setPerfil] = useState([]);
   const [mail, setMail] = useState([]);
   const [idUsuario, setIdUsuario] = useState([]);
   const [isLoggedIn, setIsLoggedIn] = useState(false);
/*

  const permisosArray = [
    "VIME01-RWD",
    "VIME02-RWD",
    "VIME03-RWD",
    "VIME04-RWD",
    "PTO01-RWD",
    "VIME05-RWD",
    "VIME06-RWD",
    "VIME07-RWD",
    "VIME08-RWD",
  ];

  const username = "Carlos Garrido Celedón";
  const permisos = permisosArray;
  const perfil = "DVCM";
  const mail = "carlosgarrido@zonavirtual.uisek.cl";
  const idUsuario = "196716955";
  const isLoggedIn = true;
  useEffect(() => {
    const cookies = new Cookies();
    const cookieValue = {
      Username: username,
      mail: mail,
      Permisos: permisos,
      Perfil: perfil,
      us_consuser: idUsuario,
    };
    
    // Establece la cookie con una duración de 1 día
    cookies.set("MyAuthCookie", cookieValue, { path: "/", maxAge: 86400 });
  }, []);
*/
  
  
 useEffect(() => {
     const cookies = new Cookies();
     const myAuthCookieValue = cookies.get("MyAuthCookie");

     if (myAuthCookieValue) {
       setIsLoggedIn(true);
       setUsername(myAuthCookieValue.Username);
       setMail(myAuthCookieValue.mail);
       setPermisos(myAuthCookieValue.Permisos);
       setPerfil(myAuthCookieValue.Perfil);
       setIdUsuario(myAuthCookieValue.us_consuser);
     } else {
       setIsLoggedIn(false);
     }
   }, []);

  return (
    <DataContext.Provider
      value={{
        username,
        permisos,
        perfil,
        mail,
        idUsuario,
        isLoggedIn,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
