import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";

const Topbar = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const colorMode = useContext(ColorModeContext);

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows={isNonMobile ? "50px" : "40px"}
      justifyContent="space-between"
      alignItems="center"
      paddingY={3}
      paddingX={1}
    >
      {/* SEARCH BAR */}
      <Box display="flex">
        <img
          src="../../assets/logos/vimeLogos/output-onlinepngtools.png"
          alt="logo"
          style={{
            objectFit: "scale-down",
            height: isNonMobile ? "200px" : "150px",
            width: isNonMobile ? "200px" : "150px",
          }}
        />
        {theme.palette.mode === "dark" ? (
          <img
            src="../../assets/logos/vimeLogos/logoVCMDark.png"
            alt="logo"
            style={{
              objectFit: "scale-down",
              height: isNonMobile ? "200px" : "150px",
              width: isNonMobile ? "200px" : "150px",
            }}
          />
        ) : (
          <img
            src="../../assets/logos/vimeLogos/logoVCM.png"
            alt="logo"
            style={{
              objectFit: "scale-down",
              height: isNonMobile ? "200px" : "150px",
              width: isNonMobile ? "200px" : "150px",
            }}
          />
        )}
      </Box>
      {/* ICONS */}
      <Box display="flex" justifyContent="end">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
