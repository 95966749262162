import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import PopupAlert from "./PopupAlert";
import { useDataContext } from "../context/DataContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { v4 as uuidv4 } from "uuid";

const DynamicRowObjetivo = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopupAlert, setOpenPopupAlert] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const {
    rowPairsObjetivo,
    setRowPairsObjetivo,
    setIsRowObjetivoAdded,
    valuesObjetivoGeneral,
    setValuesObjetivoGeneral,
    valuesObjetivoEspecifico,
    setValuesObjetivoEspecifico,
    setIdObjetivoGeneral,
    idObjetivoGeneral,
  } = useDataContext();

  const handleOpenPopupAlert = () => {
    setOpenPopupAlert(true);
  };

  const handleSubmitPopupAlertYes = () => {
    setRowPairsObjetivo([]);
    setOpenPopupAlert(false);
    setIsRowObjetivoAdded(false);
  };

  const handleSubmitPopupAlertNo = () => {
    setOpenPopupAlert(false);
  };

  const generateUniqueId = () => {
    return uuidv4();
  };

  const addRow = () => {
    const newId = generateUniqueId();
    setRowPairsObjetivo([
      ...rowPairsObjetivo,
      { id: newId, general: "", specific: "" },
    ]);
    setIsRowObjetivoAdded(false);
  };

  const handleGeneralChange = (index, event) => {
    const updatedRowPairs = [...rowPairsObjetivo];
    updatedRowPairs[index].general = event.target.value;
    setRowPairsObjetivo(updatedRowPairs);

    const updatedIdObjetivoGeneral = [...idObjetivoGeneral];
    updatedIdObjetivoGeneral[index] = updatedRowPairs[index].id; // Assuming rowPairsObjetivo has an "id" property
    setIdObjetivoGeneral(updatedIdObjetivoGeneral);

    const updatedValuesObjetivoGeneral = [...valuesObjetivoGeneral];
    updatedValuesObjetivoGeneral[index] = event.target.value;
    setValuesObjetivoGeneral(updatedValuesObjetivoGeneral);

    if (
      event.target.value.trim() !== "" &&
      rowPairsObjetivo[index].specific.trim() !== ""
    ) {
      setIsRowObjetivoAdded(true);
    } else {
      setIsRowObjetivoAdded(false);
    }
  };

  const handleSpecificChange = (index, event) => {
    const updatedRowPairs = [...rowPairsObjetivo];
    updatedRowPairs[index].specific = event.target.value;
    setRowPairsObjetivo(updatedRowPairs);

    const updatedValuesObjetivoEspecifico = [...valuesObjetivoEspecifico];
    updatedValuesObjetivoEspecifico[index] = event.target.value;
    setValuesObjetivoEspecifico(updatedValuesObjetivoEspecifico);

    if (
      event.target.value.trim() !== "" &&
      rowPairsObjetivo[index].general.trim() !== ""
    ) {
      setIsRowObjetivoAdded(true);
    } else {
      setIsRowObjetivoAdded(false);
    }
  };

  const deleteRowPair = (pairIndex) => {
    const updatedRowPairs = [...rowPairsObjetivo];
    updatedRowPairs.splice(pairIndex, 1);
    setRowPairsObjetivo(updatedRowPairs);
    if (updatedRowPairs.length === 0) {
      setIsRowObjetivoAdded(false);
    } else {
      setIsRowObjetivoAdded(true);
    }
  };

  const renderRowPair = (pairIndex) => {
    const rowPair = rowPairsObjetivo[pairIndex];
    return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        key={pairIndex}
        sx={{
          "& label.Mui-focused": {
            color: colors.grey[100],
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.grey[100],
            },
            "&:hover fieldset": {
              borderColor: colors.orangeAccent[400],
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.orangeAccent[400],
            },
          },
        }}
      >
        <Box>
          <Divider>
            <Typography variant="h5" color={colors.grey[100]} fontWeight="bold">
              {` Objetivos ${pairIndex + 1} `}
            </Typography>
          </Divider>
          <Box
            marginTop="10px"
            display="grid"
            gridTemplateColumns="repeat(2, 1fr)"
            columnGap={3}
          >
            <Box>
              <Box>
                <Typography
                  variant="h5"
                  color={colors.grey[100]}
                  marginBottom="10px"
                >
                  {`${pairIndex + 1}.1. Objetivo General`}
                </Typography>
              </Box>
              <Box>
                <TextField
                  multiline
                  rows={3}
                  label="Objetivo General"
                  value={rowPair.general}
                  onChange={(event) => handleGeneralChange(pairIndex, event)}
                  fullWidth
                  inputProps={{ maxLength: 5000 }}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography
                  variant="h5"
                  color={colors.grey[100]}
                  marginBottom="10px"
                >
                  {`${pairIndex + 1}.2. Objetivo Específico`}
                </Typography>
              </Box>
              <Box marginBottom="10px">
                <TextField
                  multiline
                  rows={3}
                  label="Objetivo Específico"
                  value={rowPair.specific}
                  onChange={(event) => handleSpecificChange(pairIndex, event)}
                  fullWidth
                  inputProps={{ maxLength: 5000 }}
                />
              </Box>
              <Box display="flex" justifyContent="end" marginBottom="20px">
                {theme.palette.mode === "dark" ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<DeleteIcon />}
                    onClick={() => deleteRowPair(pairIndex)}
                  >
                    Eliminar Objetivos
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="secondary"
                    endIcon={<DeleteIcon />}
                    onClick={() => deleteRowPair(pairIndex)}
                  >
                    Eliminar Objetivos
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {rowPairsObjetivo.length < 1 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="69vh"
        >
          {theme.palette.mode === "dark" ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={addRow}
              startIcon={<AddCircleOutlinedIcon />}
              sx={{ width: "300px", height: "70px" }}
            >
              <Typography fontWeight="bold">Añadir Objetivos</Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={addRow}
              startIcon={<AddCircleOutlinedIcon />}
              sx={{ width: "300px", height: "70px" }}
            >
              <Typography fontWeight="bold">Añadir Objetivos</Typography>
            </Button>
          )}
        </Box>
      ) : (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              {theme.palette.mode === "dark" ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={addRow}
                  startIcon={<AddCircleOutlinedIcon />}
                >
                  Añadir Objetivos
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addRow}
                  startIcon={<AddCircleOutlinedIcon />}
                >
                  Añadir Objetivos
                </Button>
              )}
            </Box>
            <Box>
              {rowPairsObjetivo.length > 1 && theme.palette.mode === "dark" && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleOpenPopupAlert}
                  endIcon={<DeleteIcon />}
                >
                  Eliminar todos
                </Button>
              )}
              {rowPairsObjetivo.length > 1 &&
                theme.palette.mode === "light" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleOpenPopupAlert}
                    endIcon={<DeleteIcon />}
                  >
                    Eliminar todos
                  </Button>
                )}
            </Box>
          </Box>
          <Box
            marginTop="20px"
            marginBottom="20px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box overflow="auto" width={isNonMobile ? "90vw" : "90vw"} height={isNonMobile ? "60vh" : "60vh"} paddingX="30px">
              {rowPairsObjetivo.map((_, index) => renderRowPair(index))}
            </Box>
          </Box>
        </Box>
      )}
      <PopupAlert
        open={openPopupAlert}
        handleSubmitSi={handleSubmitPopupAlertYes}
        handleSubmitNo={handleSubmitPopupAlertNo}
        title="¿Esta seguro/a de borrar todos los objetivos?"
      >
        Se eliminaran todos los objetivos generales y específicos
      </PopupAlert>
    </Box>
  );
};

export default DynamicRowObjetivo;
