import axios from "axios";
import { toast } from "react-toastify";
import { render } from "@react-email/render";
import vimeMailRechazar from "../../emails/vimeMailRechazar";

const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

const currentDate = new Date();

// Adjust the date to match GMT-3 timezone
currentDate.setHours(currentDate.getHours() - 3); // Subtract 6 hours

function formatDateToSQLCompatible() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

const formattedDate = formatDateToSQLCompatible();

const rechazarProyecto = async (
  valuesNombrePrograma,
  valuesResponsablePrograma,
  valuesDiagnostico,
  valuesDireccion,
  valuesRecursosMateriales,
  valuesTransporteAlimentacion,
  valuesOtrosRecursos,
  valuesTotal,
  valuesResultadoEsperado,
  selectedFuenteFinanciamiento,
  selectedFocoPrioritario,
  selectedCicloFormativo,
  selectedLineaDeAccionRows,
  selectedPEI,
  selectedPais,
  selectedRegion,
  selectedCiudad,
  selectedAlcanceTerritorial,
  selectedGrupoInteresRelevante,
  selectedEtapaParticipa,
  selectedParticipantesEsperados,
  selectedTipoParticipantes,
  selectedContribucionInterna,
  selectedContribucionExterna,
  selectedFacultadRows,
  selectedCarreraRows,
  selectedAmbitoRows,
  selectedLineaSubRows,
  selectedAsignaturaRows,
  selectedActorParticipa,
  selectedODS,
  selectedInstrumentoEvaluacion,
  getProyecto,
  rowProgramaID,
  existingFuenteFinanciamiento,
  existingFacultad,
  existingCarrera,
  existingAmbito,
  existingLineaAccion,
  existingLineaSub,
  existingAsignatura,
  existingCicloFormativo,
  existingPEI,
  existingAlcanceTerritorial,
  existingGrupoInteresRelevante,
  existingEtapaParticipa,
  existingTipoParticipantes,
  existingContribucionInterna,
  existingContribucionExterna,
  existingRowPairs,
  rowPairsObjetivo,
  existingODS,
  existingInstrumentoEvaluacion,
  valuesFechaInicio,
  valuesFechaTermino,
  clearValues,
  setOpenBackdrop,
  theme,
  valuesRetroalimentacion,
  getRetroalimentacion,
  valuesIdUsuario,
  valuesMail,
  valuesUsername,
  usernameTemp,
  nombreProgramaTemp,
  idProgramaTemp,
  responsableProgramaTemp,
  mailTemp
) => {
  try {
    await axios.put(`https://vime.usek.cl/api/proyecto/${rowProgramaID}`, {
      id: rowProgramaID,
      id_tipoAccion: 2,
      nombreProyecto: valuesNombrePrograma,
      nombreResponsable: valuesResponsablePrograma,
      fechaInicio: valuesFechaInicio,
      fechaTermino: valuesFechaTermino,
      diagnostico: valuesDiagnostico,
      direccion: valuesDireccion,
      presupuestoRM:
        parseFloat(removeNonNumeric(valuesRecursosMateriales)) || 0,
      presupuestoTA:
        parseFloat(removeNonNumeric(valuesTransporteAlimentacion)) || 0,
      presupuestoOR: parseFloat(removeNonNumeric(valuesOtrosRecursos)) || 0,
      presupuestoTotal: parseFloat(removeNonNumeric(valuesTotal)) || 0,
      id_usuario: valuesIdUsuario,
      id_estado: 5,
      resultadoEsperado: valuesResultadoEsperado,
      mailUsuario: valuesMail,
      nombreAutor: valuesUsername,
      actividades: [],
    });

    const addedFuenteFinanciamientoIds = selectedFuenteFinanciamiento.filter(
      (item) => !existingFuenteFinanciamiento.includes(item.id_fuente)
    );

    const removedFuenteFinanciamientoIds = existingFuenteFinanciamiento.filter(
      (item) => !selectedFuenteFinanciamiento.includes(item.id_fuente)
    );

    await axios.delete(
      "https://vime.usek.cl/api/fuenteFinanciamientoSeleccion/proyecto",
      {
        data: {
          id_fuente: removedFuenteFinanciamientoIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/fuenteFinanciamientoSeleccion", {
      id_fuente: addedFuenteFinanciamientoIds,
      id_proyecto: rowProgramaID,
    });

    const addedFacultadIds = selectedFacultadRows.filter(
      (item) => !existingFacultad.includes(item.id_facultad)
    );

    const removedFacultadIds = existingFacultad.filter(
      (item) => !selectedFacultadRows.includes(item.id_facultad)
    );

    await axios.delete("https://vime.usek.cl/api/facultadSeleccion/proyecto", {
      data: {
        id_facultad: removedFacultadIds,
        id_proyecto: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/facultadSeleccion", {
      id_facultad: addedFacultadIds,
      id_proyecto: rowProgramaID,
    });

    const addedCarreraIds = selectedCarreraRows.filter(
      (item) => !existingCarrera.includes(item.id_carrera)
    );

    const removedCarreraIds = existingCarrera.filter(
      (item) => !selectedCarreraRows.includes(item.id_carrera)
    );

    await axios.delete("https://vime.usek.cl/api/carreraSeleccion/proyecto", {
      data: {
        id_carrera: removedCarreraIds,
        id_proyecto: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/carreraSeleccion", {
      id_carrera: addedCarreraIds,
      id_proyecto: rowProgramaID,
    });

    const addedCicloFormativoIds = selectedCicloFormativo.filter(
      (item) => !existingCicloFormativo.includes(item.id_ciclo)
    );

    const removedCicloFormativoIds = existingCicloFormativo.filter(
      (item) => !selectedCicloFormativo.includes(item.id_ciclo)
    );

    await axios.delete(
      "https://vime.usek.cl/api/cicloFormativoSeleccion/proyecto",
      {
        data: {
          id_ciclo: removedCicloFormativoIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/cicloFormativoSeleccion", {
      id_ciclo: addedCicloFormativoIds,
      id_proyecto: rowProgramaID,
    });

    await axios.put(
      `https://vime.usek.cl/api/focoPrioritarioSeleccion/proyecto/${rowProgramaID}`,
      {
        id_foco: selectedFocoPrioritario,
      }
    );

    const addedAmbitoIds = selectedAmbitoRows.filter(
      (item) => !existingAmbito.includes(item.id_ambito)
    );

    const removedAmbitoIds = existingAmbito.filter(
      (item) => !selectedAmbitoRows.includes(item.id_ambito)
    );

    await axios.delete("https://vime.usek.cl/api/ambitosSeleccion/proyecto", {
      data: {
        id_ambito: removedAmbitoIds,
        id_proyecto: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/ambitosSeleccion", {
      id_ambito: addedAmbitoIds,
      id_proyecto: rowProgramaID,
    });

    const addedLineaAccionIds = selectedLineaDeAccionRows.filter(
      (item) => !existingLineaAccion.includes(item.id_linea)
    );

    const removedLineaAccionIds = existingLineaAccion.filter(
      (item) => !selectedLineaDeAccionRows.includes(item.id_linea)
    );

    await axios.delete(
      "https://vime.usek.cl/api/lineasDeAccionSeleccion/proyecto",
      {
        data: {
          id_linea: removedLineaAccionIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/lineasDeAccionSeleccion", {
      id_linea: addedLineaAccionIds,
      id_proyecto: rowProgramaID,
    });

    const addedLineaSubIds = selectedLineaSubRows.filter(
      (item) => !existingLineaSub.includes(item.id_lineaSub)
    );

    const removedLineaSubIds = existingLineaSub.filter(
      (item) => !selectedLineaSubRows.includes(item.id_lineaSub)
    );

    await axios.delete(
      "https://vime.usek.cl/api/lineasSubCategoriaSeleccion/proyecto",
      {
        data: {
          id_lineaSub: removedLineaSubIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/lineasSubCategoriaSeleccion", {
      id_lineaSub: addedLineaSubIds,
      id_proyecto: rowProgramaID,
    });

    const addedAsignaturaIds = selectedAsignaturaRows.filter(
      (item) => !existingAsignatura.includes(item.id_asignatura)
    );

    const removedAsignaturaIds = existingAsignatura.filter(
      (item) => !selectedAsignaturaRows.includes(item.id_asignatura)
    );

    await axios.delete(
      "https://vime.usek.cl/api/asignaturasSeleccion/proyecto",
      {
        data: {
          id_asignatura: removedAsignaturaIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/asignaturasSeleccion", {
      id_asignatura: addedAsignaturaIds,
      id_proyecto: rowProgramaID,
    });

    const addedPEIIds = selectedPEI.filter(
      (item) => !existingPEI.includes(item.id_pei)
    );

    const removedPEIIds = existingPEI.filter(
      (item) => !selectedPEI.includes(item.id_pei)
    );

    await axios.delete("https://vime.usek.cl/api/peiSeleccion/proyecto", {
      data: {
        id_pei: removedPEIIds,
        id_proyecto: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/peiSeleccion", {
      id_pei: addedPEIIds,
      id_proyecto: rowProgramaID,
    });

    const addedAlcanceTerritorialIds = selectedAlcanceTerritorial.filter(
      (item) => !existingAlcanceTerritorial.includes(item.id_alcance)
    );

    const removedAlcanceTerritorialIds = existingAlcanceTerritorial.filter(
      (item) => !selectedAlcanceTerritorial.includes(item.id_alcance)
    );

    await axios.delete(
      "https://vime.usek.cl/api/alcanceTerritorialSeleccion/proyecto",
      {
        data: {
          id_alcance: removedAlcanceTerritorialIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/alcanceTerritorialSeleccion", {
      id_alcance: addedAlcanceTerritorialIds,
      id_proyecto: rowProgramaID,
    });

    await axios.put(
      `https://vime.usek.cl/api/paisSeleccion/proyecto/${rowProgramaID}`,
      {
        id_pais: selectedPais,
      }
    );

    await axios.put(
      `https://vime.usek.cl/api/regionSeleccion/proyecto/${rowProgramaID}`,
      {
        id_region: selectedRegion,
      }
    );

    await axios.put(
      `https://vime.usek.cl/api/ciudadSeleccion/proyecto/${rowProgramaID}`,
      {
        id_ciudad: selectedCiudad,
      }
    );

    const addedGrupoInteresRelevanteIds = selectedGrupoInteresRelevante.filter(
      (item) => !existingGrupoInteresRelevante.includes(item.id_grupo)
    );

    const removedGrupoInteresRelevanteIds =
      existingGrupoInteresRelevante.filter(
        (item) => !selectedGrupoInteresRelevante.includes(item.id_grupo)
      );

    await axios.delete(
      "https://vime.usek.cl/api/grupoInteresRelevanteSeleccion/proyecto",
      {
        data: {
          id_grupo: removedGrupoInteresRelevanteIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post(
      "https://vime.usek.cl/api/grupoInteresRelevanteSeleccion",
      {
        id_grupo: addedGrupoInteresRelevanteIds,
        id_proyecto: rowProgramaID,
      }
    );

    await axios.put(
      `https://vime.usek.cl/api/actorParticipaSeleccion/proyecto/${rowProgramaID}`,
      {
        id_actor: selectedActorParticipa,
      }
    );

    const addedEtapaParticipaIds = selectedEtapaParticipa.filter(
      (item) => !existingEtapaParticipa.includes(item.id_etapa)
    );

    const removedEtapaParticipaIds = existingEtapaParticipa.filter(
      (item) => !selectedEtapaParticipa.includes(item.id_etapa)
    );

    await axios.delete(
      "https://vime.usek.cl/api/etapaParticipaSeleccion/proyecto",
      {
        data: {
          id_etapa: removedEtapaParticipaIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/etapaParticipaSeleccion", {
      id_etapa: addedEtapaParticipaIds,
      id_proyecto: rowProgramaID,
    });

    const addedObjetivosIds = rowPairsObjetivo.filter(
      (item) => !existingRowPairs.includes(item.id)
    );

    const removedObjetivosIds = existingRowPairs.filter(
      (item) => !rowPairsObjetivo.includes(item.id)
    );

    await axios.delete("https://vime.usek.cl/api/objetivoEspecifico", {
      data: {
        id_objGeneral: removedObjetivosIds.map((item) => item.id),
        id_proyecto: rowProgramaID,
      },
    });

    await axios.delete("https://vime.usek.cl/api/objetivoGeneral/proyecto", {
      data: {
        id: removedObjetivosIds.map((item) => item.id),
        id_proyecto: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/objetivoGeneral", {
      id: addedObjetivosIds.map((item) => item.id),
      descripcion: addedObjetivosIds.map((item) => item.general),
      id_proyecto: rowProgramaID,
    });

    await axios.post("https://vime.usek.cl/api/objetivoEspecifico", {
      id_objGeneral: addedObjetivosIds.map((item) => item.id),
      descripcion: addedObjetivosIds.map((item) => item.specific),
      id_proyecto: rowProgramaID,
    });

    const addedTipoParticipantesIds = selectedTipoParticipantes.filter(
      (item) => !existingTipoParticipantes.includes(item.id_tipoParticipantes)
    );

    const removedTipoParticipantesIds = existingTipoParticipantes.filter(
      (item) => !selectedTipoParticipantes.includes(item.id_tipoParticipantes)
    );

    await axios.delete(
      "https://vime.usek.cl/api/tipoParticipantesSeleccion/proyecto",
      {
        data: {
          id_tipoParticipantes: removedTipoParticipantesIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/tipoParticipantesSeleccion", {
      id_tipoParticipantes: addedTipoParticipantesIds,
      id_proyecto: rowProgramaID,
    });

    await axios.put(
      `https://vime.usek.cl/api/participantesEsperadosSeleccion/proyecto/${rowProgramaID}`,
      {
        id_participantesEsperados: selectedParticipantesEsperados,
      }
    );

    const addedContribucionInternaIds = selectedContribucionInterna.filter(
      (item) =>
        !existingContribucionInterna.includes(item.id_contribucionInterna)
    );

    const removedContribucionInternaIds = existingContribucionInterna.filter(
      (item) =>
        !selectedContribucionInterna.includes(item.id_contribucionInterna)
    );

    await axios.delete(
      "https://vime.usek.cl/api/contribucionInternaSeleccion/proyecto",
      {
        data: {
          id_contribucionInterna: removedContribucionInternaIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/contribucionInternaSeleccion", {
      id_contribucionInterna: addedContribucionInternaIds,
      id_proyecto: rowProgramaID,
    });

    const addedContribucionExternaIds = selectedContribucionExterna.filter(
      (item) =>
        !existingContribucionExterna.includes(item.id_contribucionExterna)
    );

    const removedContribucionExternaIds = existingContribucionExterna.filter(
      (item) =>
        !selectedContribucionExterna.includes(item.id_contribucionExterna)
    );

    await axios.delete(
      "https://vime.usek.cl/api/contribucionExternaSeleccion/proyecto",
      {
        data: {
          id_contribucionExterna: removedContribucionExternaIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/contribucionExternaSeleccion", {
      id_contribucionExterna: addedContribucionExternaIds,
      id_proyecto: rowProgramaID,
    });

    const addedODSIds = selectedODS.filter(
      (item) => !existingODS.includes(item.id_ods)
    );

    const removedODSIds = existingODS.filter(
      (item) => !selectedODS.includes(item.id_ods)
    );

    await axios.delete("https://vime.usek.cl/api/odsSeleccion/proyecto", {
      data: {
        id_ods: removedODSIds,
        id_proyecto: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/odsSeleccion", {
      id_ods: addedODSIds,
      id_proyecto: rowProgramaID,
    });

    const addedInstrumentoEvaluacionIds = selectedInstrumentoEvaluacion.filter(
      (item) =>
        !existingInstrumentoEvaluacion.includes(item.id_instrumentoEvaluacion)
    );

    const removedInstrumentoEvaluacionIds =
      existingInstrumentoEvaluacion.filter(
        (item) =>
          !selectedInstrumentoEvaluacion.includes(item.id_instrumentoEvaluacion)
      );

    await axios.delete(
      "https://vime.usek.cl/api/instrumentoEvaluacionSeleccion/proyecto",
      {
        data: {
          id_instrumentoEvaluacion: removedInstrumentoEvaluacionIds,
          id_proyecto: rowProgramaID,
        },
      }
    );

    await axios.post(
      "https://vime.usek.cl/api/instrumentoEvaluacionSeleccion",
      {
        id_instrumentoEvaluacion: addedInstrumentoEvaluacionIds,
        id_proyecto: rowProgramaID,
      }
    );

    await axios.post("https://vime.usek.cl/api/retroalimentacion", {
      id_proyecto: rowProgramaID,
      descripcion: valuesRetroalimentacion,
      fechaIngreso: currentDate,
    });

    getProyecto();
    getRetroalimentacion();
    clearValues();
    setOpenBackdrop(false);
    toast.success("Proyecto Rechazado", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: theme.palette.mode === "dark" ? "dark" : "light",
    });
  } catch (error) {
    console.error("Error updating program:", error);
  }

  const emailHtml = render(
    vimeMailRechazar({
      userFirstname: usernameTemp,
      nombrePrograma: nombreProgramaTemp,
      tipoAccion: "El proyecto",
      tipoAccion2: "el proyecto",
      preview: "ha sido rechazado.",
      idPrograma: idProgramaTemp,
      responsablePrograma: responsableProgramaTemp,
      estado: "rechazado",
      observacion: valuesRetroalimentacion,
      linkVIME: "https://vime.usek.cl/proyectos",
    })
  );

  try {
    await axios.post("https://vime.usek.cl/api/send-email", {
      recipient: mailTemp,
      subject: "Notificación VIME-USEK",
      id_facultad: selectedFacultadRows,
      emailTemplate: emailHtml,
    });
  } catch (error) {
    console.error("Error enviando email: ", error);
  }
  try {
    await axios.post("https://vime.usek.cl/api/auditoria", {
      Username: valuesIdUsuario,
      Fecha: formattedDate,
      Accion: "Rechazar",
      Opcion: "Rechazo de proyecto",
      Transaccion: `El usuario rechazó el proyecto con el ID: ${idProgramaTemp}`,
      Sistema: "VIME",
    });
  } catch (error) {
    console.error("Error ingresando auditoria: ", error);
  }
};

export default rechazarProyecto;
