import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Typography,
  useTheme,
  Zoom,
  IconButton,
  TextField,
} from "@mui/material";
import { tokens } from "../theme";
import CloseIcon from "@mui/icons-material/Close";

const PopupConfirmarCancelacion = ({
  title,
  activityName,
  open,
  handleConfirmCancelation,
  onClose,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [motivo, setMotivo] = useState("");

  const handleConfirm = () => {
    handleConfirmCancelation(motivo);
    setMotivo("");
  };

  return (
    <Dialog
      TransitionComponent={Zoom}
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiPaper-root": {
                background: colors.grey[900],
              },
            }
          : {
              "& .MuiPaper-root": {
                background: "#fcfcfc",
              },
            }),
      }}
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="bold" color={colors.grey[100]}>
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: colors.grey[100],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" color={colors.grey[100]}>
          ¿Realmente desea cancelar la actividad?
          </Typography>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="motivo"
          label="Motivo de cancelación"
          type="text"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={motivo}
          onChange={(e) => setMotivo(e.target.value)}
          sx={{
            marginTop: 2,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: colors.grey[100],
              },
              "&:hover fieldset": {
                borderColor: colors.grey[100],
              },
              "&.Mui-focused fieldset": {
                borderColor: colors.grey[100],
              },
            },
            "& .MuiInputLabel-root": {
              color: colors.grey[100],
            },
            "& .MuiInputBase-input": {
              color: colors.grey[100],
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant={theme.palette.mode === "dark" ? "outlined" : "contained"}
          onClick={handleConfirm}
          disabled={!motivo}
          autoFocus
        >
          Confirmar Cancelación
        </Button>
        <Button
          color="secondary"
          variant={theme.palette.mode === "dark" ? "outlined" : "contained"}
          onClick={onClose}
        >
          Volver
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupConfirmarCancelacion;