import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import FAQ from "./scenes/faq";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import Programas from "./scenes/programas";
import Proyectos from "./scenes/proyectos";
import Actividades from "./scenes/actividades";
import Emergentes from "./scenes/emergentes";
import Agenda from "./scenes/agenda";
import Actualizacion from "./scenes/actualizacion";
import FormPrograma from "./scenes/programForm";
import { useDataContextSession } from "./context/DataContextSession";
import Revision from "./scenes/revision";

const hasPermission = (userPermissions, requiredPermission) => {
  const [section, actions] = requiredPermission.split('-');
  return userPermissions.some(permission => 
    permission.startsWith(section) && permission.includes('R')
  );
};

const PrivateRoute = ({ requiredPermissions }) => {
  const { isLoggedIn, permisos } = useDataContextSession();

  const isAuthorized = isLoggedIn && requiredPermissions.every(perm => hasPermission(permisos, perm));

  return isAuthorized ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

function App() {
  const [theme, colorMode] = useMode();
  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/reportes" element={<Contacts />} />
              <Route path="/form" element={<Form />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/faq" element={<FAQ />} />
              
              <Route element={<PrivateRoute requiredPermissions={["VIME01-R"]} />}>
                <Route path="/programas" element={<Programas />} />
              </Route>
              <Route element={<PrivateRoute requiredPermissions={["VIME02-R"]} />}>
                <Route path="/proyectos" element={<Proyectos />} />
              </Route>
              <Route element={<PrivateRoute requiredPermissions={["VIME03-R"]} />}>
                <Route path="/actividades" element={<Actividades />} />
              </Route>
              <Route element={<PrivateRoute requiredPermissions={["VIME04-R"]} />}>
                <Route path="/emergentes" element={<Emergentes />} />
              </Route>
              <Route element={<PrivateRoute requiredPermissions={["VIME05-R"]} />}>
                <Route path="/revision" element={<Revision />} />
              </Route>
              
              <Route path="/agenda" element={<Agenda />} />
              <Route path="/actualizacion" element={<Actualizacion />} />
              <Route path="/formPrograma" element={<FormPrograma />} />
              
              <Route path="/unauthorized" element={<UnauthorizedPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

const UnauthorizedPage = () => <div>No tienes permisos para acceder a esta página</div>;
const NotFoundPage = () => <div>Página no encontrada</div>;

export default App;