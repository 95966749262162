import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Typography,
  useTheme,
  Zoom,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import { tokens } from "../theme";
import CloseIcon from "@mui/icons-material/Close";

const PopupReagendar = ({
  title,
  currentDateStart,
  currentDateEnd,
  open,
  handleSubmitReagendar,
  onClose,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [newDateStart, setNewDateStart] = useState("");
  const [newDateEnd, setNewDateEnd] = useState("");
  const [motivo, setMotivo] = useState("");

  const handleReagendar = () => {
    handleSubmitReagendar(newDateStart, newDateEnd, motivo);
    setNewDateStart("");
    setNewDateEnd("");
    setMotivo("");
  };
 
  const textFieldSx = {
    marginTop: 2,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.grey[100],
      },
      "&:hover fieldset": {
        borderColor: colors.grey[100],
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.grey[100],
      },
    },
    "& .MuiInputLabel-root": {
      color: colors.grey[100],
    },
    "& .MuiInputBase-input": {
      color: colors.grey[100],
    },
  };

  return (
    <Dialog
      TransitionComponent={Zoom}
      open={open}
      fullWidth
      maxWidth="md"
      sx={{
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiPaper-root": {
                background: colors.grey[900],
              },
            }
          : {
              "& .MuiPaper-root": {
                background: "#fcfcfc",
              },
            }),
      }}
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="bold" color={colors.grey[100]}>
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: colors.grey[100],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" color={colors.grey[100]}>
            Fecha de inicio actual: {currentDateStart.substring(0, 10)}
          </Typography>
          <Typography variant="h5" color={colors.grey[100]}>
            Fecha de fin actual: {currentDateEnd.substring(0, 10)}
          </Typography>
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              margin="dense"
              id="newDateStart"
              label="Nueva fecha de inicio"
              type="date"
              fullWidth
              variant="outlined"
              value={newDateStart}
              onChange={(e) => setNewDateStart(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              sx={textFieldSx}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              id="newDateEnd"
              label="Nueva fecha de fin"
              type="date"
              fullWidth
              variant="outlined"
              value={newDateEnd}
              onChange={(e) => setNewDateEnd(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              sx={textFieldSx}
            />
          </Grid>
        </Grid>
        <TextField
          margin="dense"
          id="motivo"
          label="Motivos para reprogramar la actividad"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={motivo}
          onChange={(e) => setMotivo(e.target.value)}
          sx={textFieldSx}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant={theme.palette.mode === "dark" ? "outlined" : "contained"}
          onClick={handleReagendar}
          disabled={!newDateStart || !newDateEnd || !motivo}
        >
          Reprogramar
        </Button>
        <Button
          color="secondary"
          variant={theme.palette.mode === "dark" ? "outlined" : "contained"}
          onClick={onClose}
        >
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupReagendar;