import axios from "axios";

const retroalimentacionFetch = async (rowID, setValuesRetroalimentacion) => {
  try {
    const retroalimentacionResponse = await axios.get(
      "https://vime.usek.cl/api/retroalimentacion"
    );

    const retroalimentacionData = retroalimentacionResponse.data;

    const retroalimentacionDescripcion = retroalimentacionData
      .filter((item) => item.id === rowID)
      .map((item) => item.descripcion);

    setValuesRetroalimentacion(retroalimentacionDescripcion);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export default retroalimentacionFetch;
