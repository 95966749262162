import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { ResponsiveChoropleth } from "@nivo/geo";
import { scaleQuantize } from "d3-scale";
import { tokens } from "../theme";
// Transforma la data para que sea compatible con el gráfico
const transformData = (data) => {
  if (data) {
    return data.map((item) => ({
      id: item.descripcion, // Asegúrate de que 'descripcion' coincide con los nombres de los países en geoFeatures
      value:
        item.NumeroProyectos +
        item.NumeroProgramas +
        item.NumeroActividades +
        item.NumeroIniciativas,
      Proyectos: item.NumeroProyectos,
      Programas: item.NumeroProgramas,
      Actividades: item.NumeroActividades,
      Iniciativas: item.NumeroIniciativas,
    }));
  } else {
    return [];
  }
};

const GeographyChartMetro = ({ data, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [geoFeatures, setGeoFeatures] = useState(null);

  useEffect(() => {
    fetch("/assets/region.geojson")
      .then((response) => response.json())
      .then((data) => setGeoFeatures(data));
  }, []);

  if (!geoFeatures) {
    return <div>Loading...</div>;
  }

  const transformedData = transformData(data);
  const maxValue = Math.max(...transformedData.map((d) => d.value), 0); // Asegúrate de que haya un valor máximo

  const getColor = scaleQuantize()
    .domain([0, maxValue])
    .range(["#ededed", "orange"]);

  return (
    <ResponsiveChoropleth
      width={710}
      data={transformedData}
      features={geoFeatures.features}
      colors={getColor}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      domain={[0, maxValue]}
      unknownColor="#d3cece"
      label="properties.name"
      valueFormat=".2s"
      projectionType="mercator"
      projectionScale={12500}
      projectionTranslation={[22.3,-19]}
      projectionRotation={[0, 0, 0]}
      borderWidth={0.5}
      borderColor={{ theme: "background" }}
      tooltip={({ feature }) => {
        const countryData = transformedData.find((d) => d.id === feature.id);
        return countryData ? (
          <div
            style={{
              color: colors.grey[100],
              backgroundColor: colors.primary[400],
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <strong>{feature.properties.name}</strong>
            <br />
            <strong>Total: {countryData.value}</strong>
            <br />
            Proyectos: {countryData.Proyectos}
            <br />
            Programas: {countryData.Programas}
            <br />
            Actividades: {countryData.Actividades}
            <br />
            Iniciativa: {countryData.Iniciativas}
          </div>
        ) : (
          <div
            style={{
              color: colors.grey[100],
              backgroundColor: colors.primary[400],
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <strong>{feature.properties.name}</strong>
            <br />
            Cantidad: 0
          </div>
        );
      }}
    />
  );
};

export default GeographyChartMetro;
