import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React, { useState } from "react";
import Popup from "../../../components/Popup";
import TableAmbitos from "../../../tables/TableAmbitos";
import TableLineasDeAccion from "../../../tables/TableLineasDeAccion";
import TableLineaSub from "../../../tables/TableLineaSub";
import TableAsignaturas from "../../../tables/TableAsignaturas";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDataContext } from "../../../context/DataContext";
import TooltipInfo from "../../../components/TooltipInfo";

const CoherenciaInterna = ({
  touchedFocoPrioritario,
  errorsFocoPrioritario,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopupAmbito, setOpenPopupAmbito] = useState(false);
  const [openPopupLineaDeAccion, setOpenPopupLineaDeAccion] = useState(false);
  const [openPopupLineaSub, setOpenPopupLineaSub] = useState(false);
  const [openPopupAsignatura, setOpenPopupAsignatura] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const {
    selectedAmbitoRows,
    setSelectedAmbitoRows,
    selectedLineaDeAccionRows,
    setSelectedLineaDeAccionRows,
    selectedLineaSubRows,
    setSelectedLineaSubRows,
    selectedAsignaturaRows,
    setSelectedAsignaturaRows,
    isAmbitoSelected,
    setIsAmbitoSelected,
    isLineaDeAccionSelected,
    setIsLineaDeAccionSelected,
    isLineaSubSelected,
    setIsLineaSubSelected,
    isAsignaturaSelected,
    setIsAsignaturaSelected,
    selectedCarreraRows,
    focoPrioritario,
    selectedFocoPrioritario,
    setSelectedFocoPrioritario,
    tipoCicloFormativo,
    cicloFormativo,
    selectedCicloFormativo,
    setSelectedCicloFormativo,
    hasNoRows,
  } = useDataContext();

  const tipoCicloFormativoCicloBasico = tipoCicloFormativo.filter(
    (tipoCicloFormativo) => tipoCicloFormativo.id === 1
  );

  const tipoCicloFormativoCicloProfesional = tipoCicloFormativo.filter(
    (tipoCicloFormativo) => tipoCicloFormativo.id === 2
  );

  const tipoCicloFormativoCicloSintesis = tipoCicloFormativo.filter(
    (tipoCicloFormativo) => tipoCicloFormativo.id === 3
  );

  const cicloFormativoCicloBasico = cicloFormativo.filter(
    (cicloFormativo) => cicloFormativo.id_tipoCiclo === 1
  );

  const cicloFormativoCicloProfesional = cicloFormativo.filter(
    (cicloFormativo) => cicloFormativo.id_tipoCiclo === 2
  );

  const cicloFormativoCicloSintesis = cicloFormativo.filter(
    (cicloFormativo) => cicloFormativo.id_tipoCiclo === 3
  );

  const handlePopupOpenAmbito = () => {
    setOpenPopupAmbito(true);
  };

  const handlePopupOpenLineaDeAccion = () => {
    setOpenPopupLineaDeAccion(true);
  };

  const handlePopupOpenLineaSub = () => {
    setOpenPopupLineaSub(true);
  };

  const handlePopupCloseAmbito = (selectedAmbitoRows) => {
    setSelectedAmbitoRows(selectedAmbitoRows);
    setOpenPopupAmbito(false);
    if (selectedAmbitoRows.length > 0) {
      setIsAmbitoSelected(true);
    } else {
      setIsAmbitoSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupOpenAsignatura = () => {
    setOpenPopupAsignatura(true);
  };

  const handlePopupCloseLineaDeAccion = (selectedLineaDeAccionRows) => {
    setSelectedLineaDeAccionRows(selectedLineaDeAccionRows);
    setOpenPopupLineaDeAccion(false);
    if (selectedLineaDeAccionRows.length > 0) {
      setIsLineaDeAccionSelected(true);
    } else {
      setIsLineaDeAccionSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupCloseLineaSub = (selectedLineaSubRows) => {
    setSelectedLineaSubRows(selectedLineaSubRows);
    setOpenPopupLineaSub(false);
    if (selectedLineaSubRows.length > 0) {
      setIsLineaSubSelected(true);
    } else {
      setIsLineaSubSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupCloseAsignatura = (selectedAsignaturaRows) => {
    setSelectedAsignaturaRows(selectedAsignaturaRows);
    setOpenPopupAsignatura(false);
    if (selectedAsignaturaRows.length > 0) {
      setIsAsignaturaSelected(true);
    } else {
      setIsAsignaturaSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handleAmbitosSelectionChange = (newSelectedRows) => {
    setSelectedAmbitoRows(newSelectedRows);
  };

  const handleLineasDeAccionSelectionChange = (newSelectedRows) => {
    setSelectedLineaDeAccionRows(newSelectedRows);
    setSelectedLineaSubRows([]);
    setSelectedAsignaturaRows([]);
    setIsLineaSubSelected(false);
    setIsAsignaturaSelected(false);
  };

  const handleLineaSubSelectionChange = (newSelectedRows) => {
    setSelectedLineaSubRows(newSelectedRows);
    setSelectedAsignaturaRows([]);
    setIsAsignaturaSelected(false);
  };

  const handleAsignaturaSelectionChange = (newSelectedRows) => {
    setSelectedAsignaturaRows(newSelectedRows);
  };

  const handlePopupSubmitAmbito = (selectedAmbitoRows) => {
    setSelectedAmbitoRows(selectedAmbitoRows);
    setOpenPopupAmbito(false);
    if (selectedAmbitoRows.length > 0) {
      setIsAmbitoSelected(true);
    } else {
      setIsAmbitoSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupSubmitLineaDeAccion = (selectedLineaDeAccionRows) => {
    setSelectedLineaDeAccionRows(selectedLineaDeAccionRows);
    setOpenPopupLineaDeAccion(false);
    if (selectedLineaDeAccionRows.length > 0) {
      setIsLineaDeAccionSelected(true);
    } else {
      setIsLineaDeAccionSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupSubmitLineaSub = (selectedLineaSubRows) => {
    setSelectedLineaSubRows(selectedLineaSubRows);
    setOpenPopupLineaSub(false);
    if (selectedLineaSubRows.length > 0) {
      setIsLineaSubSelected(true);
    } else {
      setIsLineaSubSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupSubmitAsignatura = (selectedAsignaturaRows) => {
    setSelectedAsignaturaRows(selectedAsignaturaRows);
    setOpenPopupAsignatura(false);
    if (selectedAsignaturaRows.length > 0) {
      setIsAsignaturaSelected(true);
    } else {
      setIsAsignaturaSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handleFocoPrioritarioChange = (event) => {
    setSelectedFocoPrioritario(event.target.value);
  };

  const handleCicloFormativoChange = (event, cicloId) => {
    if (event.target.checked) {
      setSelectedCicloFormativo([...selectedCicloFormativo, cicloId]);
    } else {
      setSelectedCicloFormativo(
        selectedCicloFormativo.filter((id) => id !== cicloId)
      );
    }
  };

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      gridTemplateRows={isNonMobile ? undefined : isNonMobileMedium ? undefined : "70% 30%"}
      rowGap={isNonMobile ? 1 : 1}
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
            fontWeight="bold"
          >
            5. Ciclo Formativo{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Ciclo formativo:
                  </Typography>
                  <br></br>En consonancia con el Modelo Formativo de la
                  Universidad USEK, cada actividad de VcM debe estar vinculada a
                  uno o más los tres Ciclos Formativos que estructuran los
                  programas educativos. Estos ciclos poseen una caracterización
                  general y objetivos específicos diseñados para guiar la
                  formación de los estudiantes a lo largo de su trayectoria
                  universitaria.
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <Box>
            <FormControl component="fieldset" variant="standard">
              {tipoCicloFormativoCicloBasico.map((tipoCicloFormativo, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "3px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {tipoCicloFormativo.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                {cicloFormativoCicloBasico.map((cicloFormativo, id) => (
                  <FormControlLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    key={cicloFormativo.id}
                    control={
                      <Checkbox
                        color="default"
                        checked={selectedCicloFormativo.includes(
                          cicloFormativo.id
                        )}
                        onChange={(event) =>
                          handleCicloFormativoChange(event, cicloFormativo.id)
                        }
                        value={cicloFormativo.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                        {cicloFormativo.descripcion}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {tipoCicloFormativoCicloProfesional.map(
                (tipoCicloFormativo, id) => (
                  <FormLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "3px" }}
                  >
                    <Typography
                      variant={isNonMobile ? "h5" : "h6"}
                      fontWeight="bold"
                      color={colors.grey[100]}
                    >
                      {tipoCicloFormativo.descripcion}
                    </Typography>
                  </FormLabel>
                )
              )}
              <FormGroup>
                {cicloFormativoCicloProfesional.map((cicloFormativo, id) => (
                  <FormControlLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    key={cicloFormativo.id}
                    control={
                      <Checkbox
                        color="default"
                        checked={selectedCicloFormativo.includes(
                          cicloFormativo.id
                        )}
                        onChange={(event) =>
                          handleCicloFormativoChange(event, cicloFormativo.id)
                        }
                        value={cicloFormativo.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                        {cicloFormativo.descripcion}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {tipoCicloFormativoCicloSintesis.map((tipoCicloFormativo, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "3px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {tipoCicloFormativo.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                {cicloFormativoCicloSintesis.map((cicloFormativo, id) => (
                  <FormControlLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    key={cicloFormativo.id}
                    control={
                      <Checkbox
                        color="default"
                        checked={selectedCicloFormativo.includes(
                          cicloFormativo.id
                        )}
                        onChange={(event) =>
                          handleCicloFormativoChange(event, cicloFormativo.id)
                        }
                        value={cicloFormativo.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                        {cicloFormativo.descripcion}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            fontWeight="bold"
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
          >
            6. Política VCM USEK{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Focos prioritarios:
                  </Typography>
                  <br></br>Dentro del Modelo de Gestión de la Universidad, se
                  identifican tres pilares prioritarios: Inclusión, Calidad de
                  Vida, e Innovación y Emprendimiento, los cuales están
                  alineados con los propósitos institucionales. En el contexto
                  de su actividad de Vinculación con el Medio, es necesario elegir
                  uno, el más coherente con los objetivos planteados.
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            columnGap: 3,
            alignItems: "center",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom={isNonMobile ? "10px" : "5px"}
              >
                6.1. Foco Prioritario
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                select
                label="Foco Prioritario"
                value={selectedFocoPrioritario}
                onChange={handleFocoPrioritarioChange}
                name="focoPrioritario"
                error={!!touchedFocoPrioritario && !!errorsFocoPrioritario}
                helperText={touchedFocoPrioritario && errorsFocoPrioritario}
              >
                {focoPrioritario.map((focoPrioritario, id) => (
                  <MenuItem key={focoPrioritario.id} value={focoPrioritario.id}>
                    {focoPrioritario.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom={isNonMobile ? "10px" : "5px"}
              >
                6.2. Ámbitos
              </Typography>
            </Box>
            {!isAmbitoSelected ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenAmbito}
                    color="secondary"
                    variant="outlined"
                  >
                    Seleccionar Ámbitos
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenAmbito}
                    color="secondary"
                    variant="contained"
                  >
                    Seleccionar Ámbitos
                  </Button>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                columnGap="20px"
              >
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenAmbito}
                    color="secondary"
                    variant="outlined"
                  >
                    Seleccionar Ámbitos
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenAmbito}
                    color="secondary"
                    variant="contained"
                  >
                    Seleccionar Ámbitos
                  </Button>
                )}
                <CheckCircleIcon color="success" />
              </Box>
            )}
          </Box>
          <Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom={isNonMobile ? "10px" : "5px"}
              >
                6.3. Lineas de Acción
              </Typography>
            </Box>
            {!isLineaDeAccionSelected ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenLineaDeAccion}
                    color="secondary"
                    variant="outlined"
                  >
                    Seleccionar Lineas de Acción
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenLineaDeAccion}
                    color="secondary"
                    variant="contained"
                  >
                    Seleccionar Lineas de Acción
                  </Button>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                columnGap="20px"
              >
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenLineaDeAccion}
                    color="secondary"
                    variant="outlined"
                  >
                    Seleccionar Lineas de Acción
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenLineaDeAccion}
                    color="secondary"
                    variant="contained"
                  >
                    Seleccionar Lineas de Acción
                  </Button>
                )}
                <CheckCircleIcon color="success" />
              </Box>
            )}
          </Box>
          <Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom={isNonMobile ? "10px" : "5px"}
              >
                6.4. Subcategoría
              </Typography>
            </Box>
            {!isLineaSubSelected ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenLineaSub}
                    color="secondary"
                    variant="outlined"
                    disabled={
                      !selectedLineaDeAccionRows ||
                      selectedLineaDeAccionRows.length === 0 ||
                      selectedLineaDeAccionRows.every(
                        (value) => ![1, 4].includes(value)
                      )
                    }
                  >
                    Seleccionar Subcategoría
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenLineaSub}
                    color="secondary"
                    variant="contained"
                    disabled={
                      !selectedLineaDeAccionRows ||
                      selectedLineaDeAccionRows.length === 0 ||
                      selectedLineaDeAccionRows.every(
                        (value) => ![1, 4].includes(value)
                      )
                    }
                  >
                    Seleccionar Subcategoría
                  </Button>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                columnGap="20px"
              >
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenLineaSub}
                    color="secondary"
                    variant="outlined"
                    disabled={
                      !selectedLineaDeAccionRows ||
                      selectedLineaDeAccionRows.length === 0 ||
                      selectedLineaDeAccionRows.every(
                        (value) => ![1, 4].includes(value)
                      )
                    }
                  >
                    Seleccionar Subcategoría
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenLineaSub}
                    color="secondary"
                    variant="contained"
                    disabled={
                      !selectedLineaDeAccionRows ||
                      selectedLineaDeAccionRows.length === 0 ||
                      selectedLineaDeAccionRows.every(
                        (value) => ![1, 4].includes(value)
                      )
                    }
                  >
                    Seleccionar Subcategoría
                  </Button>
                )}
                <CheckCircleIcon color="success" />
              </Box>
            )}
          </Box>
          <Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom={isNonMobile ? "10px" : "5px"}
              >
                6.5. Asignaturas
              </Typography>
            </Box>
            {!isAsignaturaSelected ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenAsignatura}
                    color="secondary"
                    variant="outlined"
                    disabled={
                      !selectedLineaSubRows ||
                      selectedLineaSubRows.length === 0 ||
                      hasNoRows ||
                      selectedCarreraRows.length === 0 ||
                      selectedLineaSubRows.every(
                        (value) => ![2].includes(value)
                      )
                    }
                  >
                    Seleccionar Asignatura
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenAsignatura}
                    color="secondary"
                    variant="contained"
                    disabled={
                      !selectedLineaSubRows ||
                      selectedLineaSubRows.length === 0 ||
                      hasNoRows ||
                      selectedCarreraRows.length === 0 ||
                      selectedLineaSubRows.every(
                        (value) => ![2].includes(value)
                      )
                    }
                  >
                    Seleccionar Asignatura
                  </Button>
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                columnGap="20px"
              >
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenAsignatura}
                    color="secondary"
                    variant="outlined"
                    disabled={
                      !selectedLineaSubRows ||
                      selectedLineaSubRows.length === 0 ||
                      selectedCarreraRows.length === 0 ||
                      hasNoRows ||
                      selectedLineaSubRows.every(
                        (value) => ![2].includes(value)
                      )
                    }
                  >
                    Seleccionar Asignatura
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "60%",
                      height: isNonMobile ? undefined : "40px",
                    }}
                    onClick={handlePopupOpenAsignatura}
                    color="secondary"
                    variant="contained"
                    disabled={
                      !selectedLineaSubRows ||
                      selectedLineaSubRows.length === 0 ||
                      selectedCarreraRows.length === 0 ||
                      hasNoRows ||
                      selectedLineaSubRows.every(
                        (value) => ![2].includes(value)
                      )
                    }
                  >
                    Seleccionar Asignatura
                  </Button>
                )}
                <CheckCircleIcon color="success" />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Popup
        open={openPopupAmbito}
        onClose={() => handlePopupCloseAmbito(selectedAmbitoRows)}
        title={"Ámbitos"}
        buttonName={"Seleccionar"}
        handleSubmit={() => handlePopupSubmitAmbito(selectedAmbitoRows)}
      >
        <TableAmbitos
          selectedRows={selectedAmbitoRows}
          onSelectionchange={handleAmbitosSelectionChange}
        />
      </Popup>
      <Popup
        open={openPopupLineaDeAccion}
        onClose={() => handlePopupCloseLineaDeAccion(selectedLineaDeAccionRows)}
        title={"Lineas de Acción"}
        buttonName={"Seleccionar"}
        handleSubmit={() =>
          handlePopupSubmitLineaDeAccion(selectedLineaDeAccionRows)
        }
      >
        <TableLineasDeAccion
          selectedRows={selectedLineaDeAccionRows}
          onSelectionchange={handleLineasDeAccionSelectionChange}
        />
      </Popup>
      <Popup
        open={openPopupLineaSub}
        onClose={() => handlePopupCloseLineaSub(selectedLineaSubRows)}
        title={"Subcategoría"}
        buttonName={"Seleccionar"}
        handleSubmit={() => handlePopupSubmitLineaSub(selectedLineaSubRows)}
      >
        <TableLineaSub
          selectedRows={selectedLineaSubRows}
          onSelectionchange={handleLineaSubSelectionChange}
        />
      </Popup>
      <Popup
        open={openPopupAsignatura}
        onClose={() => handlePopupCloseAsignatura(selectedAsignaturaRows)}
        title={"Asignatura"}
        buttonName={"Seleccionar"}
        handleSubmit={() => handlePopupSubmitAsignatura(selectedAsignaturaRows)}
      >
        <TableAsignaturas
          selectedRows={selectedAsignaturaRows}
          onSelectionchange={handleAsignaturaSelectionChange}
        />
      </Popup>
    </Box>
  );
};

export default CoherenciaInterna;
