import * as yup from "yup";

export const userSchema = yup.object().shape({
  nombrePrograma: yup.string().required("Campo obligatorio"),
  responsablePrograma: yup.string().required("Campo obligatorio"),
  fuenteFinanciamiento: yup
    .array()
    .of(yup.boolean())
    .test("at-least-one-selected", "Seleccione al menos una opción", (values) =>
      values.includes(true)
    )
    .required("You must select at least one option"),
  facultadDireccion: yup.string().required("Campo obligatorio"),
  cicloFormativo: yup.string().required("Campo obligatorio"),
  focoPrioritario: yup.string().required("Campo obligatorio"),
  ambito: yup.string().required("Campo obligatorio"),
  dimension: yup.string().required("Campo obligatorio"),
  alcanceTerritorial: yup.string().required("Campo obligatorio"),
  localizacion: yup.string().required("Campo obligatorio"),
  grupoInteres: yup.string().required("Campo obligatorio"),
  duracionPrograma: yup.string().required("Campo obligatorio"),
  articulacionPei: yup.string().required("Campo obligatorio"),
  ods: yup.string().required("Campo obligatorio"),
  actorClave: yup.string().required("Campo obligatorio"),
  participacionMedio: yup.string().required("Campo obligatorio"),
  grupoRelevante: yup.string().required("Campo obligatorio"),
  participacionEsperada: yup.string().required("Campo obligatorio"),
  contribuyeInterno: yup.string().required("Campo obligatorio"),
  contribuyeExterno: yup.string().required("Campo obligatorio"),
  diagnostico: yup.string().required("Campo obligatorio"),
  objetivoGeneral: yup.string().required("Campo obligatorio"),
  objetivoEspecifico: yup.string().required("Campo obligatorio"),
  resultadoEsperado: yup.string().required("Campo obligatorio"),
  indicadorLogro: yup.string().required("Campo obligatorio"),
  desActividad: yup.string().required("Campo obligatorio"),
  instrumentoEvaluacion: yup.string().required("Campo obligatorio"),
  presupuestoTotal: yup.string().required("Campo obligatorio"),
  // lastName: yup.string().required("Required"),
  // email: yup
  //     .string()
  //     .email("Invalid Email")
  //     .required("Required"),
  // contact: yup
  //     .string()
  //     .matches(phoneRegEx, "Phone number is not valid")
  //     .required("Required"),
  // address1: yup.string().required("Required"),
  // address2: yup.string().required("Required")
});

export const userSchemaActividadP = yup.object().shape({
  nombrePrograma: yup.string().required("Campo obligatorio"),
  responsablePrograma: yup.string().required("Campo obligatorio"),
  facultadDireccion: yup.string().required("Campo obligatorio"),
  cicloFormativo: yup.string().required("Campo obligatorio"),
  focoPrioritario: yup.string().required("Campo obligatorio"),
  ambito: yup.string().required("Campo obligatorio"),
  dimension: yup.string().required("Campo obligatorio"),
  alcanceTerritorial: yup.string().required("Campo obligatorio"),
  localizacion: yup.string().required("Campo obligatorio"),
  grupoInteres: yup.string().required("Campo obligatorio"),
  duracionPrograma: yup.string().required("Campo obligatorio"),
  articulacionPei: yup.string().required("Campo obligatorio"),
  ods: yup.string().required("Campo obligatorio"),
  actorClave: yup.string().required("Campo obligatorio"),
  participacionMedio: yup.string().required("Campo obligatorio"),
  grupoRelevante: yup.string().required("Campo obligatorio"),
  participacionEsperada: yup.string().required("Campo obligatorio"),
  contribuyeInterno: yup.string().required("Campo obligatorio"),
  contribuyeExterno: yup.string().required("Campo obligatorio"),
  diagnostico: yup.string().required("Campo obligatorio"),
  objetivoGeneral: yup.string().required("Campo obligatorio"),
  objetivoEspecifico: yup.string().required("Campo obligatorio"),
  resultadoEsperado: yup.string().required("Campo obligatorio"),
  indicadorLogro: yup.string().required("Campo obligatorio"),
  desActividad: yup.string().required("Campo obligatorio"),
  instrumentoEvaluacion: yup.string().required("Campo obligatorio"),
  presupuestoTotal: yup.string().required("Campo obligatorio"),
  // lastName: yup.string().required("Required"),
  // email: yup
  //     .string()
  //     .email("Invalid Email")
  //     .required("Required"),
  // contact: yup
  //     .string()
  //     .matches(phoneRegEx, "Phone number is not valid")
  //     .required("Required"),
  // address1: yup.string().required("Required"),
  // address2: yup.string().required("Required")
});

export const initialValues = {
  nombrePrograma: "",
  responsablePrograma: "",
  facultadDireccion: "",
  fuenteFinanciamiento: [false, false],
  cicloFormativo: "",
  focoPrioritario: "",
  ambito: "",
  dimension: "",
  alcanceTerritorial: "",
  localizacion: "",
  grupoInteres: "",
  duracionPrograma: "",
  articulacionPei: "",
  ods: "",
  actorClave: "",
  participacionMedio: "",
  grupoRelevante: "",
  participacionEsperada: "",
  contribuyeInterno: "",
  contribuyeExterno: "",
  diagnostico: "",
  objetivoGeneral: "",
  objetivoEspecifico: "",
  resultadoEsperado: "",
  indicadorLogro: "",
  desActividad: "",
  instrumentoEvaluacion: "",
  presupuestoTotal: "",
};

export const initialValuesActividadP = {
  nombrePrograma: "",
  responsablePrograma: "",
  facultadDireccion: "",
  cicloFormativo: "",
  focoPrioritario: "",
  ambito: "",
  dimension: "",
  alcanceTerritorial: "",
  localizacion: "",
  grupoInteres: "",
  duracionPrograma: "",
  articulacionPei: "",
  ods: "",
  actorClave: "",
  participacionMedio: "",
  grupoRelevante: "",
  participacionEsperada: "",
  contribuyeInterno: "",
  contribuyeExterno: "",
  diagnostico: "",
  objetivoGeneral: "",
  objetivoEspecifico: "",
  resultadoEsperado: "",
  indicadorLogro: "",
  desActividad: "",
  instrumentoEvaluacion: "",
  presupuestoTotal: "",
};
