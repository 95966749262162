import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import DynamicRowActividad from "../../../components/DynamicRowActividad";

const Actividades = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      rowGap={0}
      gridTemplateColumns="repeat(1, 1fr)"
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Typography
          variant={isNonMobile ? "h4" : "h5"}
          color={colors.grey[100]}
          fontWeight="bold"
          marginBottom="10px"
        >
          14. Actividades
        </Typography>
      </Box>
      <Box>
      <DynamicRowActividad />
      </Box>
    </Box>
  );
};

export default Actividades;
