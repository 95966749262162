import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { tokens } from '../theme';
import { useTheme } from '@mui/material';

const extractOdsNumber = (label) => {
  const match = label.match(/\d+/);
  return match ? parseInt(match[0], 10) : null;
};

const sortDataByOdsNumber = (data) => {
  return data.sort((a, b) => {
    const numA = extractOdsNumber(a.label);
    const numB = extractOdsNumber(b.label);
    return numA - numB;
  });
};

const PieChart = ({ data ,useColorMapping }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const sortedData = sortDataByOdsNumber(data);
  console.log("Datos de entrada:", data);

  return (
    <ResponsivePie
      data={sortedData}
      theme={{
        labels: {
          text: {
            fontSize: 12, // Ajusta el tamaño del texto de las etiquetas
          },
        },
        legends: {
          text: {
            fontSize: useColorMapping?7:12, // Ajusta el tamaño del texto de la leyenda
          },
        },
      }}
      margin={{ top: 50, right: 300, bottom: 50, left: 80 }} // Aumentar el margen derecho para la leyenda
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsThickness={2}
      enableArcLabels={true}
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={7}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      arcLabel={(item) => `${item.value}%`} // Mostrar el porcentaje en las etiquetas
      colors={useColorMapping?({ id, data }) => data.color:{ scheme: 'nivo' }} // Usar el color proporcionado en los datos
      legends={[
        {
          anchor: 'right', // Posicionar la leyenda a la derecha
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 60,
          itemHeight: 15,
          itemTextColor: colors.grey[100],
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 10,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: colors.grey[100],
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieChart;
