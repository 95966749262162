import { Box, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
import { useDataContext } from "../../../context/DataContext";

const Presupuesto = ({
  errorsRecursosMateriales,
  touchedRecursosMateriales,
  errorsTransporteAlimentacion,
  touchedTransporteAlimentacion,
  errorsOtrosRecursos,
  touchedOtrosRecursos,
  errorsTotal,
  touchedTotal,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");

  const {
    valuesRecursosMateriales,
    setValuesRecursosMateriales,
    valuesTransporteAlimentacion,
    setValuesTransporteAlimentacion,
    valuesOtrosRecursos,
    setValuesOtrosRecursos,
    valuesTotal,
    setValuesTotal,
  } = useDataContext();

  // ...

  // Define event handlers for input fields
  const onChangeRecursosMateriales = (e) => {
    const newValue = e.target.value;
    setValuesRecursosMateriales(addCommas(removeNonNumeric(newValue)));
    calculateTotal(newValue, valuesTransporteAlimentacion, valuesOtrosRecursos);
  };

  const onChangeTransporteAlimentacion = (e) => {
    const newValue = e.target.value;
    setValuesTransporteAlimentacion(addCommas(removeNonNumeric(newValue)));
    calculateTotal(valuesRecursosMateriales, newValue, valuesOtrosRecursos);
  };

  const onChangeOtrosRecursos = (e) => {
    const newValue = e.target.value;
    setValuesOtrosRecursos(addCommas(removeNonNumeric(newValue)));
    calculateTotal(
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      newValue
    );
  };

  // Function to calculate the total
  const calculateTotal = (
    recursosMateriales,
    transporteAlimentacion,
    otrosRecursos
  ) => {
    // Replace periods with an empty string to convert back to a standard number format
    const recursosMaterialesValue =
      Number(recursosMateriales.replace(/\./g, "").replace(/,/g, ".")) || 0;
    const transporteAlimentacionValue =
      Number(transporteAlimentacion.replace(/\./g, "").replace(/,/g, ".")) || 0;
    const otrosRecursosValue =
      Number(otrosRecursos.replace(/\./g, "").replace(/,/g, ".")) || 0;

    const total =
      recursosMaterialesValue +
      transporteAlimentacionValue +
      otrosRecursosValue;

    // Format the total with periods as thousand separators
    setValuesTotal(addCommas(total.toString()));
  };

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      rowGap={1}
      gridTemplateColumns="repeat(2, 1fr)"
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={
              isNonMobile ? "10px" : isNonMobileMedium ? "5px" : "0px"
            }
            fontWeight="bold"
          >
            20. Presupuesto
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 2,
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant={isNonMobile ? "h5" : "h6"}
              fontWeight="bold"
              color={colors.grey[100]}
            >
              Recursos Necesarios
            </Typography>
          </Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h5" : "h6"}
              color={colors.grey[100]}
              fontWeight="bold"
            >
              Costo en CLP
            </Typography>
          </Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h5" : "h6"}
              color={colors.grey[100]}
            >
              Recursos Materiales
            </Typography>
          </Box>
          <Box>
            <TextField
              InputProps={{
                sx: { fontSize: isNonMobile ? undefined : "12px" },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              variant="outlined"
              type="text"
              onChange={onChangeRecursosMateriales}
              value={valuesRecursosMateriales}
              name="nombrePrograma"
              error={!!touchedRecursosMateriales && !!errorsRecursosMateriales}
              helperText={touchedRecursosMateriales && errorsRecursosMateriales}
            />
          </Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h5" : "h6"}
              color={colors.grey[100]}
            >
              Transporte/Alimentación
            </Typography>
          </Box>
          <Box>
            <TextField
              InputProps={{
                sx: { fontSize: isNonMobile ? undefined : "12px" },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              variant="outlined"
              type="text"
              onChange={onChangeTransporteAlimentacion}
              value={valuesTransporteAlimentacion}
              name="nombrePrograma"
              error={
                !!touchedTransporteAlimentacion &&
                !!errorsTransporteAlimentacion
              }
              helperText={
                touchedTransporteAlimentacion && errorsTransporteAlimentacion
              }
            />
          </Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h5" : "h6"}
              color={colors.grey[100]}
            >
              Otros Recursos
            </Typography>
          </Box>
          <Box>
            <TextField
              InputProps={{
                sx: { fontSize: isNonMobile ? undefined : "12px" },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              size="small"
              fullWidth
              variant="outlined"
              type="text"
              onChange={onChangeOtrosRecursos}
              value={valuesOtrosRecursos}
              name="nombrePrograma"
              error={!!touchedOtrosRecursos && !!errorsOtrosRecursos}
              helperText={touchedOtrosRecursos && errorsOtrosRecursos}
            />
          </Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h5" : "h6"}
              color={colors.grey[100]}
              fontWeight="bold"
            >
              Total
            </Typography>
          </Box>
          <Box>
            <TextField
              InputProps={{
                sx: { fontSize: isNonMobile ? undefined : "12px" },
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                readOnly: true,
              }}
              size="small"
              fullWidth
              variant="outlined"
              type="text"
              value={valuesTotal}
              name="nombrePrograma"
              error={!!touchedTotal && !!errorsTotal}
              helperText={touchedTotal && errorsTotal}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "inherit",
                  },
                  "&:hover fieldset": {
                    borderColor: "inherit",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "inherit",
                    borderWidth: 1, // Set border width to prevent enlargement
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Presupuesto;
