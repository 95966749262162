export const ODSDescripcion = {
  ODS01: `<p>
      A nivel mundial, el número de personas que viven en situación de extrema
      pobreza disminuyó desde un 36 % en 1990 hasta un 10 % en 2015. No
      obstante, el ritmo al que se produce este cambio está disminuyendo, y la
      crisis del COVID -19 pone en riesgo décadas de progreso en la lucha contra
      la pobreza. Una nueva investigación publicada por el Instituto Mundial de
      Investigaciones de Economía del Desarrollo de la Universidad de las
      Naciones Unidas advierte de que las consecuencias económicas de la
      pandemia mundial podrían incrementar la pobreza en todo el mundo hasta
      llegar a afectar a 500 millones de personas más. o lo que es lo mismo, a
      un 8% más de la población total mundial. Esta sería la primera vez que la
      pobreza aumentará en todo el mundo en 30 años, desde 1990.
    </p>`,
  ODS02: `
   
     <p>
       El número de personas experimentando hambre en el mundo,
       medido por la prevalencia de desnutrición, ha aumentado
       después de décadas de declive constante. Actualmente,
       alrededor de 690 millones de personas, aproximadamente el
       8,9% de la población global, padecen hambre, lo que
       representa un incremento de unos 10 millones en un año y
       60 millones en cinco años. Si las tendencias actuales
       persisten, se espera que más de 840 millones de personas
       sufran hambre para 2030, alejándose del objetivo de hambre
       cero para ese año.
     </p>
     <p>
       En paralelo, se requiere un cambio profundo en el sistema
       global de producción y distribución de alimentos para
       alimentar tanto a los 820 millones de personas que
       actualmente padecen hambre como a los 2000 millones
       adicionales que habitarán el mundo en 2050. El incremento
       en la productividad agrícola y la promoción de prácticas
       sostenibles son esenciales para mitigar los riesgos del
       hambre en el futuro.
     </p>
   
 `,
  ODS03: `
   
 <p>
 Garantizar la salud y el bienestar en todas las edades es
 crucial para el desarrollo sostenible. El COVID-19 ha
 generado una crisis sanitaria global sin precedentes,
 afectando la salud, la economía y la vida de innumerables
 personas. A pesar de avances previos en la prolongación de
 la esperanza de vida y la reducción de causas de muerte,
 subsisten desafíos en la eliminación de enfermedades y la
 afronta de problemas de salud actuales y emergentes. Mayor
 financiamiento de sistemas sanitarios, mejoras en
 saneamiento, acceso a personal médico y enfoque en la
 preparación para emergencias son esenciales para salvar
 vidas. La disparidad entre las capacidades de los países
 para enfrentar la pandemia destaca la importancia de la
 preparación y la inversión en servicios públicos
 esenciales en el siglo XXI, según el Programa de las
 Naciones Unidas para el Desarrollo.
</p>
   
 `,
  ODS04: `
   
 <p>
 La educación es esencial para la movilidad socioeconómica
 y superar la pobreza. Aunque hubo avances en la última
 década en acceso y matrícula escolar, alrededor de 260
 millones de niños seguían sin educación en 2018, afectando
 al 20% de ese grupo etario global. Además, más del 50% de
 los niños no cumplen con los estándares mínimos de lectura
 y matemáticas.
</p>
<p>
 En 2020, el COVID-19 forzó cierres escolares en la mayoría
 de los países, afectando al 91% de los estudiantes y
 dejando a unos 1600 millones sin escuela. Esto impactó
 también en 369 millones de niños que dependen de comidas
 escolares. La pandemia interrumpió la educación de manera
 sin precedentes, particularmente para los niños más
 vulnerables. Las consecuencias amenazan los logros
 educativos globales.
</p>
   
 `,
  ODS05: `
   
 <p>
 La igualdad de género es crucial para un mundo pacífico y
 sostenible. Aunque ha habido avances en educación y
 liderazgo femenino, desafíos persisten debido a leyes
 discriminatorias y violencia. El COVID-19 amenaza logros
 alcanzados al aumentar desigualdades, especialmente para
 mujeres. Ellas tienen roles destacados en la respuesta al
 virus, pero también enfrentan aumento de trabajo no
 remunerado y desempleo. La pandemia ha intensificado la
 violencia contra mujeres y niñas, ya que el confinamiento
 las expone a abusos en casa.
</p>
   
 `,
  ODS06: `
   
 <p>
 A pesar del progreso en el acceso al agua potable y
 saneamiento, muchas personas, principalmente en áreas
 rurales, carecen de estos servicios esenciales. A nivel
 mundial, una de cada tres personas no tiene agua potable
 segura, dos de cada cinco carecen de instalaciones básicas
 para lavado de manos y más de 673 millones aún defecan al
 aire libre. El COVID-19 ha resaltado la necesidad de
 higiene y acceso al agua limpia para prevenir
 enfermedades. Aunque el lavado de manos es eficaz,
 millones aún carecen de acceso a agua y saneamiento debido
 a la falta de financiamiento.
</p>
   
 `,
  ODS07: `
   
 <p>
 El progreso hacia el Objetivo 7, relacionado con la
 energía sostenible, es alentador. La disponibilidad y
 sostenibilidad de la energía están mejorando, con aumento
 del acceso a electricidad en países pobres, mayor
 eficiencia energética y éxito de energías renovables en
 electricidad. Sin embargo, se requiere más enfoque en
 acceso a combustibles de cocina limpios y expansión de
 energía renovable fuera de la electricidad, especialmente
 en África subsahariana. El informe de progreso energético
 evalúa avances en acceso, eficiencia y renovables, guiando
 hacia los Objetivos de Desarrollo Sostenible 2030.
</p>
   
 `,
  ODS08: `
   
 <p>
 Un crecimiento económico sostenible e inclusivo mejora
 estándares de vida y crea empleos. El COVID-19 ha dañado
 la economía global, con recesión proyectada por el FMI. La
 pérdida de empleos amenaza a la mitad de los trabajadores
 a nivel mundial según la OIT. Antes de la pandemia, uno de
 cada cinco países ya se encaminaba a reducir sus ingresos
 per cápita en 2020, afectando a miles de millones en
 situación de pobreza. Las perturbaciones económicas del
 COVID-19, como la caída de precios y volatilidad
 financiera, están agravando riesgos existentes.
</p>
   
 `,
  ODS09: `
   
 <p>
 La industrialización inclusiva y sostenible, junto con
 innovación e infraestructura, impulsa empleo e ingresos al
 liberar fuerzas económicas dinámicas. Facilitan nuevas
 tecnologías, comercio internacional y uso eficiente de
 recursos. Países menos desarrollados deben acelerar
 desarrollo manufacturero y aumentar inversión en
 investigación. El crecimiento manufacturero global
 disminuía antes de la pandemia, afectándolo más.
 Innovación y tecnología son cruciales para desafíos
 económicos y ambientales. Inversión en I+D creció
 globalmente pero fue menor en regiones en desarrollo. Más
 del 50% de la población está conectada en comunicaciones e
 infraestructura, y el 96.5% tiene cobertura de red en
 2019.
</p>
   
 `,
  ODS10: `
   
 <p>
 Reducir desigualdades y asegurar inclusión es esencial
 para Objetivos de Desarrollo Sostenible. Aunque hay
 señales positivas en ciertas áreas, como la disminución de
 desigualdad de ingresos, la brecha persiste. El COVID-19
 ha agravado desigualdades, afectando a los más pobres y
 vulnerables, exponiendo redes de seguridad frágiles y
 acentuando impactos. El desempleo global y reducción de
 ingresos son consecuencias económicas de la pandemia.
</p>
<p>
 El COVID-19 amenaza avances en igualdad de género y
 derechos de mujeres, exacerbando desafíos en salud,
 economía y protección social para ellas. Grupos
 vulnerables en sistemas de salud deficientes o crisis
 humanitarias, como refugiados, migrantes, pueblos
 indígenas, personas discapacitadas y niños, enfrentan
 riesgos de exclusión. Además, el odio hacia grupos
 vulnerables está en aumento.
</p>
   
 `,
  ODS11: `
   
 <p>
 La urbanización crece, con más del 50% de la población
 mundial en ciudades desde 2007, proyectándose un 60% para
 2030. Ciudades impulsan economía con 60% del PIB global,
 pero generan 70% emisiones de carbono y 60% uso de
 recursos. Urbanización rápida resulta en barrios pobres,
 infraestructuras inadecuadas y contaminación. COVID-19
 afecta duramente a áreas urbanas densas y pobres,
 especialmente a mil millones en asentamientos informales
 donde medidas como distanciamiento son difíciles. FAO
 advierte de aumento de hambre y muertes en áreas urbanas
 sin acceso a alimentos para vulnerables.
</p>
   
 `,
  ODS12: `
   
 <p>
 El consumo y producción globales dañan el medio ambiente y
 recursos. Avances económicos y sociales trajeron
 degradación ambiental amenazando desarrollo futuro y
 supervivencia. Datos relevantes son: un tercio de
 alimentos producidos se desperdician, cambio a bombillas
 eficientes ahorraría 120 mil millones de dólares,
 población creciente requeriría tres planetas para mantener
 estilo de vida actual. COVID-19 ofrece oportunidad de
 recuperación sostenible. Consumo y producción sostenibles
 buscan hacer más con menos, desligar crecimiento económico
 de daño ambiental, mejorar eficiencia de recursos y
 promover estilos de vida sostenibles. Esto puede combatir
 pobreza y transicionar a economías verdes y bajas en
 carbono.
</p>
   
 `,
  ODS13: `
   
 <p>
 El 2019 fue el segundo año más cálido registrado, marcando
 la década más calurosa. Los niveles de gases de efecto
 invernadero alcanzaron máximos históricos. El cambio
 climático afecta a todos los países, transformando
 economías y vidas con eventos extremos y aumento del nivel
 del mar. Aunque las emisiones caerán un 6% en 2020 debido
 a la pandemia, se espera que aumenten tras la recuperación
 económica. Es crucial actuar urgentemente para abordar la
 emergencia climática y la pandemia para salvar vidas y
 medios de subsistencia. El Acuerdo de París, aprobado en
 2015, busca limitar el aumento de temperatura y fortalecer
 la capacidad de los países ante el cambio climático
 mediante financiamiento, tecnología y desarrollo.
</p>
   
 `,
  ODS14: `
   
 <p>
 El océano es esencial para la vida en la Tierra,
 proporcionando agua, clima, comida y oxígeno. Su gestión
 cuidadosa es vital para un futuro sostenible. Sin embargo,
 la contaminación y la acidificación costera están dañando
 ecosistemas y biodiversidad, afectando la pesca y las
 áreas costeras. Proteger los océanos es primordial. La
 biodiversidad marina es crucial para la salud humana y del
 planeta. Se requiere manejo eficaz de áreas protegidas y
 recursos, así como regulaciones para reducir sobrepesca,
 contaminación y acidificación.
</p>
   
 `,
  ODS15: `
   
 <p>
 El COVID-19 destaca la importancia de abordar amenazas a
 especies y ecosistemas. El PNUMA alertó en 2016 sobre
 epidemias zoonóticas relacionadas con ecosistemas. La
 respuesta del PNUMA incluye manejo de desechos médicos,
 cambio positivo para naturaleza y personas, resiliencia en
 recuperación económica y modernización de gobernanza
 ambiental global. La Década para la Restauración de los
 Ecosistemas (2021-2030) busca revertir degradación global
 de hábitats. Un nuevo Marco mundial de diversidad
 biológica está en desarrollo. En respuesta a la pandemia,
 se necesita un plan sólido para proteger la naturaleza y,
 a su vez, proteger a la humanidad.
</p>
   
 `,
  ODS16: `
   
 <p>
 Conflictos, inseguridad, instituciones débiles y falta de
 acceso a la justicia amenazan el desarrollo sostenible. En
 2018, más de 70 millones de personas huyeron de guerras y
 conflictos, marcando la cifra más alta en décadas. En
 2019, 357 asesinatos y 30 desapariciones forzadas de
 defensores de derechos humanos, periodistas y
 sindicalistas se registraron en 47 países. Además, cerca
 de 1 de cada 4 niños menores de 5 años no tienen registros
 de nacimiento, privándolos de identidad legal necesaria
 para protección de derechos y acceso a justicia y
 servicios sociales.
</p>
   
 `,
  ODS17: `
   
 <p>
 Los Objetivos de Desarrollo Sostenible (ODS) requieren
 asociaciones globales y cooperación. Para lograr un
 desarrollo efectivo, se necesitan alianzas inclusivas
 basadas en valores y metas compartidas, priorizando
 personas y planeta. Muchos países necesitan asistencia
 para el desarrollo, pero la ayuda está disminuyendo y los
 donantes incumplen compromisos financieros. La pandemia de
 COVID-19 provocará una recesión global del 3 % en 2020. Se
 necesita cooperación internacional sólida para que los
 países se recuperen de la pandemia y alcancen los ODS.
</p>
   
 `,
};
