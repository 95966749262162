import { Box, Button, Stack } from "@mui/material";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { useDataContext } from "../context/DataContext";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GradingIcon from "@mui/icons-material/Grading";

const TableAcciones = ({
  onClickVerPrograma,
  onClickVerProyecto,
  onClickVerActividad,
  onClickVerIniciativa,
  onClickEvaluarPrograma,
  onClickEvaluarProyecto,
  onClickEvaluarActividad,
  onClickEvaluarIniciativa,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { programa, proyecto, iniciativa, actividad, isLoading, tipoAccion } =
    useDataContext();

  const combinedData = [
    ...programa.map((item) => ({ ...item, source: "programa" })),
    ...proyecto.map((item) => ({ ...item, source: "proyecto" })),
    ...actividad.map((item) => ({ ...item, source: "actividad" })),
    ...iniciativa.map((item) => ({ ...item, source: "iniciativa" })),
    // Add more sources if needed following the same pattern
  ];

  const combinedDataRevision = combinedData.filter(
    (combinedData) => combinedData.id_estado === 3
  );

  const getNombreAccion = (row) => {
    switch (row.source) {
      case "programa":
        return row.nombrePrograma;
      case "proyecto":
        return row.nombreProyecto; // Replace with your actual field name
      case "actividad":
        return row.nombreActividad; // Replace with your actual field name
      case "iniciativa":
        return row.nombreIniciativa; // Replace with your actual field name
      // Add more cases for other sources if needed
      default:
        return ""; // Handle default case
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 1 : 1,
    },
    {
      field: "nombreAccion",
      headerName: "Nombre de la Acción",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        const nombreAccion = getNombreAccion(params.row);
        return <span>{nombreAccion}</span>;
      },
    },
    {
      field: "nombreResponsable",
      headerName: "Nombre del Responsable",
      flex: 1,
    },
    {
      field: "id_tipoAccion",
      headerName: "Tipo Accion",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 1 : 1,
      cellClassName: "email-column--cell",
      renderCell: (params) => {
        const accionId = params.value;
        const accionObject = tipoAccion.find((e) => e.id === accionId);

        // Determine the icon based on the estadoId value
        let icon = null;
        if (accionId === 1) {
          icon = <AssignmentOutlinedIcon />;
        } else if (accionId === 2) {
          icon = <FolderCopyOutlinedIcon />;
        } else if (accionId === 3) {
          icon = <LocalActivityOutlinedIcon />;
        } else if (accionId === 4) {
          icon = <VolunteerActivismOutlinedIcon />;
        }
        // Display the icon and estado description
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            columnGap={1}
          >
            {icon}
            <span>{accionObject ? accionObject.descripcion : ""}</span>
          </Box>
        );
      },
    },
    {
      field: "nombreAutor",
      headerName: "Nombre del Autor",
      flex: 1,
    },
    {
      field: "mailUsuario",
      headerName: "Email del Autor",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 1.2 : 1.2,
    },
    {
      headerName: "",
      flex: isNonMobile ? 0.8 : isNonMobileMedium ? 1 : 1,
      align: "right",
      renderCell: (params) => {
        const rowId = params.row.id;
        const accionRow = params.row.id_tipoAccion;
        return (
          <Box>
            {theme.palette.mode === "dark" ? (
              <Box display="flex">
                {accionRow === 1 && (
                  <Stack direction="row" spacing={1} display="flex">
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() => onClickVerPrograma(rowId)}
                    >
                      Ver
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<GradingIcon />}
                      onClick={() => onClickEvaluarPrograma(rowId)}
                    >
                      Evaluar
                    </Button>
                  </Stack>
                )}
                {accionRow === 2 && (
                  <Stack direction="row" spacing={1} display="flex">
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() => onClickVerProyecto(rowId)}
                    >
                      Ver
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<GradingIcon />}
                      onClick={() => onClickEvaluarProyecto(rowId)}
                    >
                      Evaluar
                    </Button>
                  </Stack>
                )}
                {accionRow === 3 && (
                  <Stack direction="row" spacing={1} display="flex">
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() => onClickVerActividad(rowId)}
                    >
                      Ver
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<GradingIcon />}
                      onClick={() => onClickEvaluarActividad(rowId)}
                    >
                      Evaluar
                    </Button>
                  </Stack>
                )}
                {accionRow === 4 && (
                  <Stack direction="row" spacing={1} display="flex">
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() => onClickVerIniciativa(rowId)}
                    >
                      Ver
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<GradingIcon />}
                      onClick={() => onClickEvaluarIniciativa(rowId)}
                    >
                      Evaluar
                    </Button>
                  </Stack>
                )}
              </Box>
            ) : (
              <Box display="flex">
                {accionRow === 1 && (
                  <Stack direction="row" spacing={1} display="flex">
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<VisibilityIcon />}
                      onClick={() => onClickVerPrograma(rowId)}
                    >
                      Ver
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<GradingIcon />}
                      onClick={() => onClickEvaluarPrograma(rowId)}
                    >
                      Evaluar
                    </Button>
                  </Stack>
                )}
                {accionRow === 2 && (
                  <Stack direction="row" spacing={1} display="flex">
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<VisibilityIcon />}
                      onClick={() => onClickVerProyecto(rowId)}
                    >
                      Ver
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<GradingIcon />}
                      onClick={() => onClickEvaluarProyecto(rowId)}
                    >
                      Evaluar
                    </Button>
                  </Stack>
                )}
                {accionRow === 3 && (
                  <Stack direction="row" spacing={1} display="flex">
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<VisibilityIcon />}
                      onClick={() => onClickVerActividad(rowId)}
                    >
                      Ver
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<GradingIcon />}
                      onClick={() => onClickEvaluarActividad(rowId)}
                    >
                      Evaluar
                    </Button>
                  </Stack>
                )}
                {accionRow === 4 && (
                  <Stack direction="row" spacing={1} display="flex">
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<VisibilityIcon />}
                      onClick={() => onClickVerIniciativa(rowId)}
                    >
                      Ver
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<GradingIcon />}
                      onClick={() => onClickEvaluarIniciativa(rowId)}
                    >
                      Evaluar
                    </Button>
                  </Stack>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="3px"
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
        {theme.palette.mode === "dark" ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          ></Box>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          ></Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        marginTop: "1vh",
        height: isNonMobile ? "80vh" : isNonMobileMedium ? "80vh" : "75vh",
        overflow: "auto",
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[400],
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                // backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: "white",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "white",
              },
              "& .MuiTouchRipple-root": {
                color: "white",
              },
              "& .name-column--cell": {
                color: colors.orangeAccent[400],
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }
          : {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[400],
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                // backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: "white",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "white",
              },
              "& .MuiTouchRipple-root": {
                color: "white",
              },
              "& .name-column--cell": {
                color: colors.orangeAccent[500],
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }),
      }}
    >
      {isLoading ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <DataGrid
          disableRowSelectionOnClick
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={combinedDataRevision}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          autoPageSize
          rowHeight={isNonMobile ? 52 : isNonMobileMedium ? 45 : 40}
          columnHeaderHeight={isNonMobile ? 56 : 44}
        />
      )}
    </Box>
  );
};

export default TableAcciones;
