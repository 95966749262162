import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDataContext } from "../../../context/DataContext";

const Contribucion = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const {
    contribucionInterna,
    contribucionExterna,
    selectedContribucionInterna,
    setSelectedContribucionInterna,
    selectedContribucionExterna,
    setSelectedContribucionExterna,
  } = useDataContext();

  const handleContribucionInternaChange = (event, contribucionInternaId) => {
    if (event.target.checked) {
      setSelectedContribucionInterna([
        ...selectedContribucionInterna,
        contribucionInternaId,
      ]);
    } else {
      setSelectedContribucionInterna(
        selectedContribucionInterna.filter((id) => id !== contribucionInternaId)
      );
    }
  };

  const handleContribucionExternaChange = (event, contribucionExternaId) => {
    if (event.target.checked) {
      setSelectedContribucionExterna([
        ...selectedContribucionExterna,
        contribucionExternaId,
      ]);
    } else {
      setSelectedContribucionExterna(
        selectedContribucionExterna.filter((id) => id !== contribucionExternaId)
      );
    }
  };

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px": "0px"}
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      rowGap={1}
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "15px"}
            fontWeight="bold"
          >
            16. Contribución Interna
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(1, 1fr)",
          }}
        >
          <Box>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {contribucionInterna.map((contribucionInterna, id) => (
                  <Box
                    display="grid"
                    gridTemplateRows={
                      isNonMobile ? undefined : "repeat(4, 7px)"
                    }
                    alignItems="center"
                  >
                    <FormControlLabel
                      key={contribucionInterna.id}
                      control={
                        <Checkbox
                          color="default"
                          checked={selectedContribucionInterna.includes(
                            contribucionInterna.id
                          )}
                          onChange={(event) =>
                            handleContribucionInternaChange(
                              event,
                              contribucionInterna.id
                            )
                          }
                          value={contribucionInterna.id}
                          style={{
                            color: colors.grey[100],
                            marginRight: "5px",
                          }}
                        />
                      }
                      label={contribucionInterna.descripcion}
                    />
                  </Box>
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            fontWeight="bold"
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "15px"}
          >
            17. Contribución Externa
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(1, 1fr)",
          }}
        >
          <Box>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {contribucionExterna.map((contribucionExterna, id) => (
                  <Box
                    display="grid"
                    gridTemplateRows={
                      isNonMobile ? undefined : "repeat(4, 7px)"
                    }
                    alignItems="center"
                  >
                    <FormControlLabel
                      key={contribucionExterna.id}
                      control={
                        <Checkbox
                          color="default"
                          checked={selectedContribucionExterna.includes(
                            contribucionExterna.id
                          )}
                          onChange={(event) =>
                            handleContribucionExternaChange(
                              event,
                              contribucionExterna.id
                            )
                          }
                          value={contribucionExterna.id}
                          style={{
                            color: colors.grey[100],
                            marginRight: "5px",
                          }}
                        />
                      }
                      label={contribucionExterna.descripcion}
                    />
                  </Box>
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contribucion;
