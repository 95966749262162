import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const DataContext = createContext();

export const useDataContextActividadP = () => {
  return useContext(DataContext);
};

export const DataProviderActividadP = ({ children }) => {
  const [facultad, setFacultad] = useState([]);
  const [carrera, setCarrera] = useState([]);
  const [fuenteFinanciamiento, setFuenteFinanciamiento] = useState([]);
  const [ambitos, setAmbitos] = useState([]);
  const [lineasDeAccion, setLineasDeAccion] = useState([]);
  const [lineaSub, setLineaSub] = useState([]);
  const [asignatura, setAsignatura] = useState([]);
  const [focoPrioritario, setFocoPrioritario] = useState([]);
  const [pais, setPais] = useState([]);
  const [region, setRegion] = useState([]);
  const [ciudad, setCiudad] = useState([]);
  const [tipoCicloFormativo, setTipoCicloFormativo] = useState([]);
  const [cicloFormativo, setCicloFormativo] = useState([]);
  const [ejePEI, setEjePEI] = useState([]);
  const [pei, setPEI] = useState([]);
  const [alcanceTerritorial, setAlcanceTerritorial] = useState([]);
  const [tipoGrupoInteresRelevante, setTipoGrupoInteresRelevante] = useState(
    []
  );
  const [grupoInteresRelevante, setGrupoInteresRelevante] = useState([]);
  const [actorParticipa, setActorParticipa] = useState([]);
  const [etapaParticipa, setEtapaParticipa] = useState([]);
  const [tipoParticipantes, setTipoParticipantes] = useState([]);
  const [participantesEsperados, setParticipantesEsperados] = useState([]);
  const [contribucionInterna, setContribucionInterna] = useState([]);
  const [contribucionExterna, setContribucionExterna] = useState([]);
  const [ods, setODS] = useState([]);
  const [instrumentoEvaluacion, setInstrumentoEvaluacion] = useState([]);
  //const [rowPairs, setRowPairs] = useState([]);
  const [rowPairsObjetivo, setRowPairsObjetivo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedFacultadRows, setSelectedFacultadRows] = useState([]);
  const [selectedCarreraRows, setSelectedCarreraRows] = useState([]);
  const [selectedAmbitoRows, setSelectedAmbitoRows] = useState([]);
  const [selectedLineaDeAccionRows, setSelectedLineaDeAccionRows] = useState(
    []
  );
  const [selectedLineaSubRows, setSelectedLineaSubRows] = useState([]);
  const [selectedAsignaturaRows, setSelectedAsignaturaRows] = useState([]);
  const [selectedFocoPrioritario, setSelectedFocoPrioritario] = useState("");
  const [selectedPais, setSelectedPais] = useState("44");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCiudad, setSelectedCiudad] = useState("");
  const [selectedFuenteFinanciamiento, setSelectedFuenteFinanciamiento] =
    useState([]);
  const [selectedCicloFormativo, setSelectedCicloFormativo] = useState([]);
  const [selectedPEI, setSelectedPEI] = useState([]);
  const [selectedAlcanceTerritorial, setSelectedAlcanceTerritorial] = useState(
    []
  );
  const [selectedGrupoInteresRelevante, setSelectedGrupoInteresRelevante] =
    useState([]);
  const [selectedActorParticipa, setSelectedActorParticipa] = useState([1]);
  const [selectedEtapaParticipa, setSelectedEtapaParticipa] = useState([]);
  const [selectedTipoParticipantes, setSelectedTipoParticipantes] = useState(
    []
  );
  const [selectedParticipantesEsperados, setSelectedParticipantesEsperados] =
    useState("");
  const [selectedContribucionInterna, setSelectedContribucionInterna] =
    useState([]);
  const [selectedContribucionExterna, setSelectedContribucionExterna] =
    useState([]);
  const [selectedODS, setSelectedODS] = useState([]);
  const [selectedInstrumentoEvaluacion, setSelectedInstrumentoEvaluacion] =
    useState([]);

  const [isFacultadSelected, setIsFacultadSelected] = useState(false);
  const [isCarreraSelected, setIsCarreraSelected] = useState(false);
  const [isAmbitoSelected, setIsAmbitoSelected] = useState(false);
  const [isLineaDeAccionSelected, setIsLineaDeAccionSelected] = useState(false);
  const [isLineaSubSelected, setIsLineaSubSelected] = useState(false);
  const [isAsignaturaSelected, setIsAsignaturaSelected] = useState(false);
  const [isODSchecked, setIsODSchecked] = useState(false);
  const [isRowObjetivoAdded, setIsRowObjetivoAdded] = useState(false);
  const [isRowActividadAdded, setIsRowActividadAdded] = useState(false);
  const [isInstrumentoEvaluacionChecked, setIsInstrumentoEvaluacionChecked] =
    useState(false);
  const [isInstrumentoEvaluacionSelected, setIsInstrumentoEvaluacionSelected] =
    useState(false);

  const [valuesRecursosMateriales, setValuesRecursosMateriales] = useState("");
  const [valuesTransporteAlimentacion, setValuesTransporteAlimentacion] =
    useState("");
  const [valuesOtrosRecursos, setValuesOtrosRecursos] = useState("");
  const [valuesTotal, setValuesTotal] = useState("");
  const [valuesNombreActividadP, setValuesNombreActividadP] = useState("");
  const [valuesResponsableActividadP, setValuesResponsableActividadP] =
    useState("");
  const [valuesDiagnostico, setValuesDiagnostico] = useState("");
  const [valuesResultadoEsperado, setValuesResultadoEsperado] = useState("");
  const [valuesBreveDescripcion, setValuesBreveDescripcion] = useState("");
  const [valuesObjetivoGeneral, setValuesObjetivoGeneral] = useState("");
  const [valuesDireccion, setValuesDireccion] = useState("");
  const [valuesFechaInicio, setValuesFechaInicio] = useState(null);
  const [valuesFechaTermino, setValuesFechaTermino] = useState(null);
  const [isFechaInicioSelected, setIsFechaInicioSelected] = useState(false);
  const [isFechaTerminoSelected, setIsFechaTerminoSelected] = useState(false);

  useEffect(() => {
    const getFacultad = async () => {
      try {
        const response = await axios.get("https://vime.usek.cl/api/facultad");
        setFacultad(response.data);
        setIsLoading(false);
      } catch (err) {
        // Handle errors
        setIsLoading(false);
      }
    };

    getFacultad();
  }, []);

  useEffect(() => {
    const getFuenteFinanciamiento = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/fuenteFinanciamiento"
        );
        setFuenteFinanciamiento(response.data);
      } catch (err) {}
    };

    getFuenteFinanciamiento();
  }, []);

  useEffect(() => {
    const getTipoCicloFormativo = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/tipoCicloFormativo"
        );
        setTipoCicloFormativo(response.data);
      } catch (err) {}
    };

    getTipoCicloFormativo();
  }, []);

  useEffect(() => {
    const getEjePEI = async () => {
      try {
        const response = await axios.get("https://vime.usek.cl/api/ejePEI");
        setEjePEI(response.data);
      } catch (err) {}
    };

    getEjePEI();
  }, []);

  useEffect(() => {
    const getInstrumentoEvaluacion = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/instrumentoEvaluacion"
        );
        setInstrumentoEvaluacion(response.data);
      } catch (err) {}
    };

    getInstrumentoEvaluacion();
  }, []);

  useEffect(() => {
    const getODS = async () => {
      try {
        const response = await axios.get("https://vime.usek.cl/api/ods");
        setODS(response.data);
      } catch (err) {}
    };

    getODS();
  }, []);

  useEffect(() => {
    const getContribucionInterna = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/contribucionInterna"
        );
        setContribucionInterna(response.data);
      } catch (err) {}
    };

    getContribucionInterna();
  }, []);

  useEffect(() => {
    const getContribucionExterna = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/contribucionExterna"
        );
        setContribucionExterna(response.data);
      } catch (err) {}
    };

    getContribucionExterna();
  }, []);

  useEffect(() => {
    const getTipoGrupoInteresRelevante = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/tipoGrupoInteresRelevante"
        );
        setTipoGrupoInteresRelevante(response.data);
      } catch (err) {}
    };

    getTipoGrupoInteresRelevante();
  }, []);

  useEffect(() => {
    const getGrupoInteresRelevante = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/grupoInteresRelevante"
        );
        setGrupoInteresRelevante(response.data);
      } catch (err) {}
    };

    getGrupoInteresRelevante();
  }, []);

  useEffect(() => {
    const getTipoParticipantes = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/tipoParticipantes"
        );
        setTipoParticipantes(response.data);
      } catch (err) {}
    };

    getTipoParticipantes();
  }, []);

  useEffect(() => {
    const getParticipantesEsperados = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/participantesEsperados"
        );
        setParticipantesEsperados(response.data);
      } catch (err) {}
    };

    getParticipantesEsperados();
  }, []);

  useEffect(() => {
    const getActorParticipa = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/actorParticipa"
        );
        setActorParticipa(response.data);
      } catch (err) {}
    };

    getActorParticipa();
  }, []);

  useEffect(() => {
    const getEtapaParticipa = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/etapaParticipa"
        );
        setEtapaParticipa(response.data);
      } catch (err) {}
    };

    getEtapaParticipa();
  }, []);

  useEffect(() => {
    const getPEI = async () => {
      try {
        const response = await axios.get("https://vime.usek.cl/api/pei");
        setPEI(response.data);
      } catch (err) {}
    };

    getPEI();
  }, []);

  useEffect(() => {
    const getCicloFormativo = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/cicloFormativo"
        );
        setCicloFormativo(response.data);
      } catch (err) {}
    };

    getCicloFormativo();
  }, []);

  useEffect(() => {
    const getAlcanceTerritorial = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/alcanceTerritorial"
        );
        setAlcanceTerritorial(response.data);
      } catch (err) {}
    };

    getAlcanceTerritorial();
  }, []);

  useEffect(() => {
    // Fetch carrera basada en la facultad
    if (selectedFacultadRows) {
      axios
        .get(
          `https://vime.usek.cl/api/carrerasProgramas/facultad/${selectedFacultadRows}`
        )
        .then((response) => {
          setCarrera(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error buscando regiones:", error);
          setIsLoading(false);
        });
    } else {
      setCarrera([]); // Resetea la lista de carreras cuando no hay facultad seleccionada
    }
  }, [selectedFacultadRows]);

  useEffect(() => {
    const getAmbitos = async () => {
      try {
        const response = await axios.get("https://vime.usek.cl/api/ambitos");
        setAmbitos(response.data);
        setIsLoading(false);
      } catch (err) {
        // Handle errors
        setIsLoading(false);
      }
    };

    getAmbitos();
  }, []);

  useEffect(() => {
    const getLineasDeAccion = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/lineasDeAccion"
        );
        setLineasDeAccion(response.data);
        setIsLoading(false);
      } catch (err) {
        // Handle errors
        setIsLoading(false);
      }
    };

    getLineasDeAccion();
  }, []);

  useEffect(() => {
    // Fetch carrera basada en la linea de accion
    if (selectedLineaDeAccionRows.length > 0) {
      axios
        .get(
          `https://vime.usek.cl/api/lineasSubCategoria/linea/${selectedLineaDeAccionRows}`
        )
        .then((response) => {
          setLineaSub(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error buscando regiones:", error);
          setIsLoading(false);
        });
    } else {
      setLineaSub([]); // Resetea la lista de carreras cuando no hay linea de accion seleccionada
    }
  }, [selectedLineaDeAccionRows]);

  useEffect(() => {
    if (
      selectedLineaSubRows &&
      selectedLineaSubRows.length > 0 &&
      selectedCarreraRows &&
      selectedCarreraRows.length > 0
    ) {
      axios
        .get(
          `https://vime.usek.cl/api/asignaturas/lineaSub/${selectedLineaSubRows}/carrera/${selectedCarreraRows}`
        )
        .then((response) => {
          setAsignatura(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error buscando regiones:", error);
          setIsLoading(false);
        });
    } else {
      setAsignatura([]); // Resetea la lista de carreras cuando no hay facultad seleccionada
    }
  }, [selectedLineaSubRows, selectedCarreraRows]);

  useEffect(() => {
    const getFocoPrioritario = async () => {
      try {
        const response = await axios.get(
          "https://vime.usek.cl/api/focoPrioritario"
        );
        setFocoPrioritario(response.data);
      } catch (err) {}
    };

    getFocoPrioritario();
  }, []);

  useEffect(() => {
    const getPais = async () => {
      try {
        const response = await axios.get("https://vime.usek.cl/api/pais");
        setPais(response.data);
      } catch (err) {}
    };

    getPais();
  }, []);

  useEffect(() => {
    // Fetch region basada en el pais
    if (selectedPais) {
      axios
        .get(`https://vime.usek.cl/api/region/pais/${selectedPais}`)
        .then((response) => {
          setRegion(response.data);
        })
        .catch((error) => {
          console.error("Error buscando regiones:", error);
        });
    } else {
      setRegion([]); // Resetea la lista de ciudad cuando no hay pais seleccionado
    }
  }, [selectedPais]);

  useEffect(() => {
    // Fetch region basada en el pais
    if (selectedRegion) {
      axios
        .get(`https://vime.usek.cl/api/ciudad/region/${selectedRegion}`)
        .then((response) => {
          setCiudad(response.data);
        })
        .catch((error) => {
          console.error("Error buscando ciudades:", error);
        });
    } else {
      setCiudad([]); // Resetea la lista de ciudad cuando no hay pais seleccionado
    }
  }, [selectedRegion]);

  return (
    <DataContext.Provider
      value={{
        facultad,
        setFacultad,
        carrera,
        setCarrera,
        fuenteFinanciamiento,
        setFuenteFinanciamiento,
        tipoCicloFormativo,
        setTipoCicloFormativo,
        cicloFormativo,
        setCicloFormativo,
        ambitos,
        setAmbitos,
        lineasDeAccion,
        setLineasDeAccion,
        lineaSub,
        setLineaSub,
        asignatura,
        setAsignatura,
        focoPrioritario,
        setFocoPrioritario,
        pais,
        setPais,
        region,
        setRegion,
        ciudad,
        setCiudad,
        ejePEI,
        setEjePEI,
        pei,
        setPEI,
        alcanceTerritorial,
        setAlcanceTerritorial,
        selectedAlcanceTerritorial,
        setSelectedAlcanceTerritorial,
        selectedPais,
        setSelectedPais,
        selectedRegion,
        setSelectedRegion,
        selectedCiudad,
        setSelectedCiudad,
        selectedFuenteFinanciamiento,
        setSelectedFuenteFinanciamiento,
        selectedCicloFormativo,
        setSelectedCicloFormativo,
        selectedPEI,
        setSelectedPEI,
        tipoGrupoInteresRelevante,
        setTipoGrupoInteresRelevante,
        grupoInteresRelevante,
        setGrupoInteresRelevante,
        selectedGrupoInteresRelevante,
        setSelectedGrupoInteresRelevante,
        actorParticipa,
        setActorParticipa,
        selectedActorParticipa,
        setSelectedActorParticipa,
        etapaParticipa,
        setEtapaParticipa,
        selectedEtapaParticipa,
        setSelectedEtapaParticipa,
        tipoParticipantes,
        setTipoParticipantes,
        selectedTipoParticipantes,
        setSelectedTipoParticipantes,
        participantesEsperados,
        setParticipantesEsperados,
        selectedParticipantesEsperados,
        setSelectedParticipantesEsperados,
        contribucionInterna,
        setContribucionInterna,
        selectedContribucionInterna,
        setSelectedContribucionInterna,
        contribucionExterna,
        setContribucionExterna,
        selectedContribucionExterna,
        setSelectedContribucionExterna,
        ods,
        setODS,
        selectedODS,
        setSelectedODS,
        instrumentoEvaluacion,
        setInstrumentoEvaluacion,
        selectedInstrumentoEvaluacion,
        setSelectedInstrumentoEvaluacion,
        isLoading,
        setIsLoading,
        selectedFacultadRows,
        setSelectedFacultadRows,
        selectedCarreraRows,
        setSelectedCarreraRows,
        selectedAmbitoRows,
        setSelectedAmbitoRows,
        selectedLineaDeAccionRows,
        setSelectedLineaDeAccionRows,
        selectedLineaSubRows,
        setSelectedLineaSubRows,
        selectedAsignaturaRows,
        setSelectedAsignaturaRows,
        selectedFocoPrioritario,
        setSelectedFocoPrioritario,
        isFacultadSelected,
        setIsFacultadSelected,
        isCarreraSelected,
        setIsCarreraSelected,
        isAmbitoSelected,
        setIsAmbitoSelected,
        isLineaDeAccionSelected,
        setIsLineaDeAccionSelected,
        isLineaSubSelected,
        setIsLineaSubSelected,
        isAsignaturaSelected,
        setIsAsignaturaSelected,
        isODSchecked,
        setIsODSchecked,
        isInstrumentoEvaluacionChecked,
        setIsInstrumentoEvaluacionChecked,
        valuesRecursosMateriales,
        setValuesRecursosMateriales,
        valuesTransporteAlimentacion,
        setValuesTransporteAlimentacion,
        valuesOtrosRecursos,
        setValuesOtrosRecursos,
        valuesTotal,
        setValuesTotal,
        valuesResponsableActividadP,
        setValuesResponsableActividadP,
        valuesDiagnostico,
        setValuesDiagnostico,
        valuesResultadoEsperado,
        setValuesResultadoEsperado,
        //rowPairs,
        //setRowPairs,
        rowPairsObjetivo,
        setRowPairsObjetivo,
        isRowObjetivoAdded,
        setIsRowObjetivoAdded,
        isRowActividadAdded,
        setIsRowActividadAdded,
        valuesNombreActividadP,
        setValuesNombreActividadP,
        valuesBreveDescripcion,
        setValuesBreveDescripcion,
        valuesObjetivoGeneral,
        setValuesObjetivoGeneral,
        valuesDireccion,
        setValuesDireccion,
        isInstrumentoEvaluacionSelected,
        setIsInstrumentoEvaluacionSelected,
        valuesFechaInicio,
        setValuesFechaInicio,
        valuesFechaTermino,
        setValuesFechaTermino,
        isFechaInicioSelected,
        setIsFechaInicioSelected,
        isFechaTerminoSelected,
        setIsFechaTerminoSelected,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
