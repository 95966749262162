import { Box } from "@mui/material";
import Header from "../../components/Header";
import Carousel from "../../components/Carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDataContextSession } from "../../context/DataContextSession";

const Dashboard = () => {
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const { username } = useDataContextSession();
  return (
    <Box display="grid" gridTemplateRows="15% auto">
      <Box
        display="flex"
        margin={isNonMobile ? "0px 20px 0px 20px" : "0px 20px 0px 20px"}
      >
        <Header title="Inicio" subtitle={username.length > 0 ? `Bienvenido/a a VIME, ${username}` : "Bienvenido/a a VIME"} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Carousel />
      </Box>
    </Box>
  );
};

export default Dashboard;
