import React, { useState } from "react";
import { useTheme, Box, Button, Typography, Chip,Tooltip } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import PopupSeguimiento from "../components/PopupSeguimiento";
import PopupReagendar from "../components/PopupReagendar";
import PopupConfirmarCancelacion from "../components/PopupConfirmarCancelacion";
import { useDataContextSession } from "../context/DataContextSession";
import axios from "axios";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridToolbarExport,
} from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";

const TableSeguimiento = ({ seguimiento, isLoading, onCrearEvaluacion }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { idUsuario, perfil, permisos } = useDataContextSession();
  const [openSeguimiento, setOpenSeguimiento] = useState(false);
  const [openReagendar, setOpenReagendar] = useState(false);
  const [openCancelar, setOpenCancelar] = useState(false);
  const [actividadSeleccionada, setActividadSeleccionada] = useState(null);
  const [actividadCode, setActividadCode] = useState(null);

  const handleOpenModal = (id, code) => {
    setActividadSeleccionada(id);
    setActividadCode(code);
    setOpenSeguimiento(true);
  };
  const handleReprogramar = (id) => {
    setActividadSeleccionada(id);
    setOpenReagendar(true);
  };

  const handleCancelar = (id) => {
    setActividadSeleccionada(id);
    setOpenCancelar(true);
  };
  const handleCloseModal = () => {
    setOpenSeguimiento(false);
    setOpenReagendar(false);
    setOpenCancelar(false);
    setActividadSeleccionada(null);
  };
  const handleReporteEvaluacion = (id) => {
    // Mostrar un indicador de carga
    const loadingIndicator = document.createElement("div");
    loadingIndicator.id = "loading-indicator";
    loadingIndicator.innerHTML = "Cargando reporte...";
    loadingIndicator.style.position = "fixed";
    loadingIndicator.style.top = "50%";
    loadingIndicator.style.left = "50%";
    loadingIndicator.style.transform = "translate(-50%, -50%)";
    loadingIndicator.style.padding = "20px";
    loadingIndicator.style.background = "rgba(0, 0, 0, 0.7)";
    loadingIndicator.style.color = "white";
    loadingIndicator.style.borderRadius = "5px";
    loadingIndicator.style.zIndex = "1000";
    document.body.appendChild(loadingIndicator);

    console.log(`Mostrar reporte de evaluación para la actividad ${id}`);

    fetch(`https://vime.usek.cl/api/generarReportePDF/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/pdf",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("La respuesta de la red no fue satisfactoria");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.error("Hubo un problema con la petición fetch:", error);
        alert("Error al cargar el reporte. Por favor, intente nuevamente.");
      })
      .finally(() => {
        // Eliminar el indicador de carga
        document.body.removeChild(loadingIndicator);
      });
  };
  const tienePermisoEditar = (permisos) => {
    return permisos.some(
      (permiso) => permiso.startsWith("VIME08") && permiso.includes("W")
    );
  };
  const tienePermisoEliminar = (permisos) => {
    return permisos.some(
      (permiso) => permiso.startsWith("VIME08") && permiso.includes("D")
    );
  };
  const handleSubmitReagendar = async (newDateStart, newDateEnd, motivo) => {
    if (!actividadSeleccionada) return;

    const actividad = seguimiento.find(
      (act) => act.id === actividadSeleccionada
    );
    if (!actividad) return;

    try {
      const response = await axios.post("https://vime.usek.cl/api/reagendar", {
        id: actividad.id,
        code: actividad.code,
        inicio: newDateStart,
        fin: newDateEnd,
        observacion: motivo,
      });

      if (response.status === 200) {
        window.location.reload();
      } else {
        console.error("Error al reagendar la actividad");
      }
    } catch (error) {
      console.error("Error al hacer la solicitud:", error);
    }

    handleCloseModal();
  };

  const handleConfirmCancelation = async (motivo) => {
    if (!actividadSeleccionada) return;

    const actividad = seguimiento.find(
      (act) => act.id === actividadSeleccionada
    );
    if (!actividad) return;

    try {
      const response = await axios.post("https://vime.usek.cl/api/cancelar", {
        id: actividad.id,
        code: actividad.code,
        observacion: motivo,
      });

      if (response.status === 200) {
        window.location.reload();
      } else {
        console.error("Error al cancelar la actividad");
        // Manejo de errores
      }
    } catch (error) {
      console.error("Error al hacer la solicitud:", error);
      // Manejo de errores de red o del servidor
    }

    handleCloseModal();
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      filterable: false,
      minWidth: 70,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 1.5,
      filterable: false,
      minWidth: 100,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {params.row.code === 1 ? (
            <LocalActivityIcon color="success" fontSize="small" />
          ) : params.row.code === 2 ? (
            <ArticleIcon color="error" fontSize="small" />
          ) : null}
          <Typography>{params.value}</Typography>
        </Box>
      ),
    },
    {
      field: "nombre",
      headerName: "Nombre",
      filterable: false,
      flex: 1.5,
      minWidth: 150,
    },
    {
      field: "responsable",
      headerName: "Responsable",
      filterable: true,
      flex: 1.5,
    },
    {
      field: "inicio",
      headerName: "Fecha de Inicio",
      filterable: false,
      flex: 0.8,
      valueGetter: (params) => {
        if (!params.value) return '';
        
        const formatDate = (dateStr) => {
          const [year, month, day] = dateStr.substring(0, 10).split('-');
          return `${day}/${month}/${year}`;
        };
    
        let displayValue = formatDate(params.value);
    
        if (params.row.obreprogramacion && params.row.inicioOriginal) {
          const originalDate = formatDate(params.row.inicioOriginal);
          displayValue += ` (Original: ${originalDate})`;
        }
    
        return displayValue;
      },
    },
    {
      field: "fin",
      filterable: false,
      headerName: "Fecha Término",
      flex: 0.8,
      valueGetter: (params) => {
        if (!params.value) return '';
        
        const formatDate = (dateStr) => {
          const [year, month, day] = dateStr.substring(0, 10).split('-');
          return `${day}/${month}/${year}`;
        };
    
        let displayValue = formatDate(params.value);
    
        if (params.row.obreprogramacion && params.row.finOriginal) {
          const originalDate = formatDate(params.row.finOriginal);
          displayValue += ` (Original: ${originalDate})`;
        }
    
        return displayValue;
      },
    },
    {
      field: "estadoseg",
      filterable: true,
      headerName: "Estado",
      flex: 1,
      renderCell: (params) => {
        let color, backgroundColor;
        switch (params.value) {
          case "evaluado":
            backgroundColor = colors.greenAccent[400];
            color = colors.grey[100];
            break;
          case "cancelado":
            backgroundColor = colors.redAccent[600];
            color = colors.grey[100];
            break;
          case "cerrado":
            backgroundColor = colors.redAccent[600];
            color = colors.grey[100];
            break;
          case "abierto":
            backgroundColor = colors.grey[600];
            color = colors.grey[900];
            break;
          default:
            backgroundColor = colors.grey[400];
            color = colors.grey[900];
        }
        return (
          <Box
            sx={{
              backgroundColor,
              color,
              borderRadius: "16px",
              padding: "3px 10px",
              fontSize: "0.75rem",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 2,
      minWidth: 200,
      filterable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          {params.row.estadoseg === "abierto" ? (
            <>
              {tienePermisoEditar(permisos) && (
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{ fontSize: "0.6rem", padding: "2px 8px" }}
                  onClick={() => handleReprogramar(params.row.id)}
                >
                  Reprogramar
                </Button>
              )}
              {tienePermisoEliminar(permisos) && (
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{ fontSize: "0.6rem", padding: "2px 8px" }}
                  onClick={() => handleCancelar(params.row.id)}
                >
                  Cancelar
                </Button>
              )}
              {tienePermisoEditar(permisos) && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{ fontSize: "0.6rem", padding: "2px 8px" }}
                  onClick={() =>
                    handleOpenModal(params.row.id, params.row.code)
                  }
                >
                  Finalizar
                </Button>
              )}
               <Tooltip title={params.row.obreprogramacion} arrow placement="top">
             <Typography variant="body2" color="textSecondary">
               {params.row.obreprogramacion.length > 50 ? `${params.row.obreprogramacion.substring(0, 50)}...` : params.row.obreprogramacion}
             </Typography>
           </Tooltip>
          
            </>
          ) : params.row.estadoseg === "cancelado" ||
            params.row.estadoseg === "cerrado" ? (
            <Tooltip title={params.row.obcancelacion} arrow placement="top">
             <Typography variant="body2" color="textSecondary">
               {params.row.obcancelacion.length > 50 ? `${params.row.obcancelacion.substring(0, 50)}...` : params.row.obcancelacion}
             </Typography>
           </Tooltip>
          ) : (
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ fontSize: "0.7rem", padding: "2px 8px" }}
              onClick={() => handleReporteEvaluacion(params.row.id)}
            >
              Reporte Evaluación
            </Button>
          )}
        </Box>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="3px"
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <GridToolbarQuickFilter />
        </GridToolbarContainer>
      </Box>
    );
  }

  return (
    <Box
      height="calc(100vh - 100px)"
      sx={{
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[400],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }
          : {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[600],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }),
        "& .MuiDataGrid-root": {
          overflowX: "auto",
        },
      }}
    >
      {isLoading ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          <DataGrid
            disableRowSelectionOnClick
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={seguimiento}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            autoPageSize
            rowHeight={isNonMobile ? 52 : isNonMobileMedium ? 45 : 40}
            columnHeaderHeight={isNonMobile ? 56 : 44}
          />
          <PopupSeguimiento
            open={openSeguimiento}
            onClose={handleCloseModal}
            actividadId={actividadSeleccionada}
            actividadCode={actividadCode}
          />
          <PopupReagendar
            title="Reprogramar la actividad"
            currentDateStart={
              actividadSeleccionada
                ? seguimiento.find((act) => act.id === actividadSeleccionada)
                    ?.inicio
                : ""
            }
            currentDateEnd={
              actividadSeleccionada
                ? seguimiento.find((act) => act.id === actividadSeleccionada)
                    ?.fin
                : ""
            }
            open={openReagendar}
            handleSubmitReagendar={handleSubmitReagendar}
            onClose={handleCloseModal}
          />
          <PopupConfirmarCancelacion
            title="Confirmar Cancelación"
            activityName={
              actividadSeleccionada
                ? seguimiento.find((act) => act.id === actividadSeleccionada)
                    ?.nombre
                : ""
            }
            open={openCancelar}
            handleConfirmCancelation={handleConfirmCancelation}
            onClose={handleCloseModal}
          />
        </>
      )}
    </Box>
  );
};

export default TableSeguimiento;
