import React from "react";

import { IconButton, Tooltip, Typography, Zoom } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const TooltipInfo = ({ title }) => {
  return (
    <Tooltip
      title={
        <Typography variant="h7" color="white">
          {title}
        </Typography>
      }
      placement="right"
      TransitionComponent={Zoom}
      arrow
    >
      <IconButton size="small">
        <InfoIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default TooltipInfo;
