import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React, { useState } from "react";
import Popup from "../../../components/Popup";
import TableFacultad from "../../../tables/TableFacultad";
import TableCarreras from "../../../tables/TableCarreras";
import Checkbox from "@mui/material/Checkbox";
import TooltipInfo from "../../../components/TooltipInfo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDataContext } from "../../../context/DataContext";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";

const InformacionGeneral = ({
  touchedNombrePrograma,
  errorsNombrePrograma,
  touchedResponsablePrograma,
  errorsResponsablePrograma,
  onBlurNombrePrograma,
  onBlurResponsablePrograma,
  touchedFuenteFinanciamiento,
  errorsFuenteFinanciamiento,
  onBlurFuenteFinanciamiento,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopupFacultad, setOpenPopupFacultad] = useState(false);
  const [openPopupCarreras, setOpenPopupCarreras] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    selectedFacultadRows,
    setSelectedFacultadRows,
    selectedCarreraRows,
    setSelectedCarreraRows,
    isFacultadSelected,
    setIsFacultadSelected,
    isCarreraSelected,
    setIsCarreraSelected,
    setIsAsignaturaSelected,
    setSelectedAsignaturaRows,
    fuenteFinanciamiento,
    selectedFuenteFinanciamiento,
    setSelectedFuenteFinanciamiento,
    valuesNombrePrograma,
    setValuesNombrePrograma,
    valuesResponsablePrograma,
    setValuesResponsablePrograma,
    valuesFechaInicio,
    setValuesFechaInicio,
    valuesFechaTermino,
    setValuesFechaTermino,
    isLoading,
  } = useDataContext();
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");

  const handlePopupOpenFacultad = () => {
    setOpenPopupFacultad(true);
  };

  const handlePopupSubmitFacultad = (selectedFacultadRows) => {
    setSelectedFacultadRows(selectedFacultadRows);
    setOpenPopupFacultad(false);
    if (selectedFacultadRows.length > 0) {
      setIsFacultadSelected(true);
    } else {
      setIsFacultadSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupSubmitCarrera = (selectedCarreraRows) => {
    setSelectedCarreraRows(selectedCarreraRows);
    setOpenPopupCarreras(false);
    if (selectedCarreraRows.length > 0) {
      setIsCarreraSelected(true);
    } else {
      setIsCarreraSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupCloseFacultad = (selectedFacultadRows) => {
    setSelectedFacultadRows(selectedFacultadRows);
    setOpenPopupFacultad(false);
    if (selectedFacultadRows.length > 0) {
      setIsFacultadSelected(true);
    } else {
      setIsFacultadSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupOpenCarreras = () => {
    setOpenPopupCarreras(true);
  };

  const handlePopupCloseCarreras = (selectedCarreraRows) => {
    setSelectedCarreraRows(selectedCarreraRows);
    setOpenPopupCarreras(false);
    if (selectedCarreraRows.length > 0) {
      setIsCarreraSelected(true);
    } else {
      setIsCarreraSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handleFacultadSelectionChange = (newSelectedRows) => {
    setSelectedFacultadRows(newSelectedRows);
    setSelectedCarreraRows([]);
    setIsCarreraSelected(false);
  };

  const handleCarreraSelectionChange = (newSelectedRows) => {
    setSelectedCarreraRows(newSelectedRows);
    setSelectedAsignaturaRows([]);
    setIsAsignaturaSelected(false);
  };

  const handleFuenteFinanciamientoChange = (event, fuenteId) => {
    if (event.target.checked) {
      setSelectedFuenteFinanciamiento([
        ...selectedFuenteFinanciamiento,
        fuenteId,
      ]);
    } else {
      setSelectedFuenteFinanciamiento(
        selectedFuenteFinanciamiento.filter((id) => id !== fuenteId)
      );
    }
  };

  const onChangeNombrePrograma = (e) => {
    const newValue = e.target.value;
    setValuesNombrePrograma(newValue);
  };

  const onChangeResponsablePrograma = (e) => {
    const newValue = e.target.value;
    setValuesResponsablePrograma(newValue);
  };

  const handleChangeFechaTermino = (newDate) => {
    setValuesFechaTermino(newDate);
  };

  const handleChangeFechaInicio = (newDate) => {
    setValuesFechaInicio(newDate);

    if (valuesFechaTermino !== null && newDate.isAfter(valuesFechaTermino)) {
      setValuesFechaTermino(null);
    }
  };

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      rowGap={isNonMobile ? 1 : 4}
      gridTemplateRows={isNonMobile ? undefined : "1fr 1fr 1fr"}
      sx={{
        "& > div": {
          gridColumn: isNonMobile ? undefined : "span 4",
        },
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
            fontWeight="bold"
          >
            1. Datos generales del proyecto{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Nombre del proyecto:
                  </Typography>
                  <br></br>Denominación que identifica y resume la esencia del
                  proyecto de manera concisa y clara, reflejando su propósito
                  principal. (máximo 250 caracteres)<br></br>
                  <br />
                  <Typography fontWeight="bold" variant="h7">
                    Nombre del responsable:
                  </Typography>
                  <br></br>Denominación del responsable a cargo del proyecto.
                  (máximo 250 caracteres)
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                1.1. Nombre del Proyecto
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                InputProps={{
                  sx: { fontSize: isNonMobile ? undefined : "12px" },
                }}
                label="Nombre del Proyecto"
                onChange={onChangeNombrePrograma}
                onBlur={onBlurNombrePrograma}
                value={valuesNombrePrograma}
                name="nombrePrograma"
                error={!!touchedNombrePrograma && !!errorsNombrePrograma}
                helperText={touchedNombrePrograma && errorsNombrePrograma}
                inputProps={{ maxLength: 250 }}
              />
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                1.2. Responsable del Proyecto
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                InputProps={{
                  sx: { fontSize: isNonMobile ? undefined : "12px" },
                }}
                label="Responsable del Proyecto"
                onChange={onChangeResponsablePrograma}
                onBlur={onBlurResponsablePrograma}
                value={valuesResponsablePrograma}
                name="responsablePrograma"
                error={
                  !!touchedResponsablePrograma && !!errorsResponsablePrograma
                }
                helperText={
                  touchedResponsablePrograma && errorsResponsablePrograma
                }
                inputProps={{ maxLength: 250 }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            fontWeight="bold"
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
          >
            2. Datos de Facultad/Dirección
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                2.1. Facultad/Dirección
              </Typography>
            </Box>
            {!isFacultadSelected ? (
              <Box>
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "40%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenFacultad}
                    color="secondary"
                    variant="outlined"
                  >
                    Seleccionar Facultad
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "40%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenFacultad}
                    color="secondary"
                    variant="contained"
                  >
                    Seleccionar Facultad
                  </Button>
                )}
              </Box>
            ) : (
              <Box display="flex" alignItems="center" columnGap="25px">
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "40%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenFacultad}
                    color="secondary"
                    variant="outlined"
                  >
                    Seleccionar Facultad
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "40%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenFacultad}
                    color="secondary"
                    variant="contained"
                  >
                    Seleccionar Facultad
                  </Button>
                )}
                <CheckCircleIcon color="success" />
              </Box>
            )}
          </Box>
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                2.2. Carreras/Programa
              </Typography>
            </Box>
            {!isCarreraSelected ? (
              <Box>
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "40%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenCarreras}
                    color="secondary"
                    variant="outlined"
                    disabled={
                      !selectedFacultadRows ||
                      selectedFacultadRows.length === 0 ||
                      selectedFacultadRows.every((value) => value === "CAA")
                    }
                  >
                    Seleccionar Carreras
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "40%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenCarreras}
                    color="secondary"
                    variant="contained"
                    disabled={
                      !selectedFacultadRows ||
                      selectedFacultadRows.length === 0 ||
                      selectedFacultadRows.every((value) => value === "CAA")
                    }
                  >
                    Seleccionar Carreras
                  </Button>
                )}
              </Box>
            ) : (
              <Box display="flex" alignItems="center" columnGap="25px">
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "40%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenCarreras}
                    color="secondary"
                    variant="outlined"
                    disabled={
                      !selectedFacultadRows ||
                      selectedFacultadRows.length === 0 ||
                      selectedFacultadRows.every((value) => value === "CAA")
                    }
                  >
                    Seleccionar Carreras
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "40%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenCarreras}
                    color="secondary"
                    variant="contained"
                    disabled={
                      !selectedFacultadRows ||
                      selectedFacultadRows.length === 0 ||
                      selectedFacultadRows.every((value) => value === "CAA")
                    }
                  >
                    Seleccionar Carreras
                  </Button>
                )}
                <CheckCircleIcon color="success" />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
        <Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h4" : "h5"}
              fontWeight="bold"
              color={colors.grey[100]}
              marginBottom={isNonMobile ? "10px" : "3px"}
            >
              3. Fuente de Financiamiento
            </Typography>
          </Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h5" : "h6"}
              color={colors.grey[100]}
            >
              3.1. Nivel
            </Typography>
          </Box>
          {isLoading ? (
            <Box width="300px" height="50px">
              <CircularProgress color="secondary" sx={{ marginTop: "20px" }} />
            </Box>
          ) : (
            <Box width="300px" height="50px">
              <FormControl
                component="fieldset"
                variant="standard"
                error={
                  !!touchedFuenteFinanciamiento && !!errorsFuenteFinanciamiento
                }
              >
                <FormGroup>
                  {fuenteFinanciamiento.map((fuenteFinanciamiento, id) => (
                    <FormControlLabel
                      key={fuenteFinanciamiento.id}
                      control={
                        <Checkbox
                          name="fuenteFinanciamiento"
                          color="default"
                          checked={selectedFuenteFinanciamiento.includes(
                            fuenteFinanciamiento.id
                          )}
                          onChange={(event) =>
                            handleFuenteFinanciamientoChange(
                              event,
                              fuenteFinanciamiento.id
                            )
                          }
                          value={fuenteFinanciamiento.id}
                          onBlur={onBlurFuenteFinanciamiento}
                          style={{
                            color: colors.grey[100],
                            marginRight: "5px",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={isNonMobile ? "h6" : "h7"}
                          color={colors.grey[100]}
                        >
                          {fuenteFinanciamiento.descripcion}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
                <FormHelperText>
                  {touchedFuenteFinanciamiento && errorsFuenteFinanciamiento
                    ? touchedFuenteFinanciamiento && errorsFuenteFinanciamiento
                    : " "}
                </FormHelperText>
              </FormControl>
            </Box>
          )}
        </Box>
        <Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h4" : "h5"}
              fontWeight="bold"
              color={colors.grey[100]}
              marginBottom={isNonMobile ? "10px" : "3px"}
            >
              4. Duración
            </Typography>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, 200px)"
            columnGap={10}
          >
            <Box>
              <Box marginBottom="10px">
                <Typography
                  variant={isNonMobile ? "h5" : "h6"}
                  color={colors.grey[100]}
                >
                  4.1 Fecha de Inicio
                </Typography>
              </Box>
              <Box>
                <LocalizationProvider
                  adapterLocale="es"
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    //disablePast
                    value={dayjs(valuesFechaInicio ?? dayjs())}
                    onChange={handleChangeFechaInicio}
                    label="yyyy/mm/dd"
                    format="YYYY-MM-DD"
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box>
              <Box marginBottom="10px">
                <Typography
                  variant={isNonMobile ? "h5" : "h6"}
                  color={colors.grey[100]}
                >
                  4.2 Fecha de Termino
                </Typography>
              </Box>

              <Box>
                <LocalizationProvider
                  adapterLocale="es"
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    disabled={valuesFechaInicio === null}
                    //disablePast
                    minDate={valuesFechaInicio}
                    value={dayjs(valuesFechaTermino ?? dayjs())}
                    onChange={handleChangeFechaTermino}
                    label="yyyy/mm/dd"
                    format="YYYY-MM-DD"
                    onError={(newError) => setErrorMessage(newError)}
                    slotProps={{
                      textField: {
                        helperText:
                          errorMessage &&
                          "La fecha de termino no puede ser menor a la fecha de inicio.",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Popup
        open={openPopupFacultad}
        onClose={() => handlePopupCloseFacultad(selectedFacultadRows)}
        title={"Facultades"}
        buttonName={"Seleccionar"}
        handleSubmit={() => handlePopupSubmitFacultad(selectedFacultadRows)}
      >
        <TableFacultad
          selectedRows={selectedFacultadRows}
          onSelectionchange={handleFacultadSelectionChange}
        />
      </Popup>
      <Popup
        open={openPopupCarreras}
        onClose={() => handlePopupCloseCarreras(selectedCarreraRows)}
        title={"Carreras/Programas"}
        buttonName={"Seleccionar"}
        handleSubmit={() => handlePopupSubmitCarrera(selectedCarreraRows)}
      >
        <TableCarreras
          selectedRows={selectedCarreraRows}
          onSelectionchange={handleCarreraSelectionChange}
        />
      </Popup>
    </Box>
  );
};

export default InformacionGeneral;
