import { Box, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

const Retroalimentacion = ({
  valuesRetroalimentacion,
  onChangeRetroalimentacion,
  nombreAccion,
  readonly,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");

  return (
    <Box
      height={isNonMobile ? "50vh" : "50vh"}
      display="grid"
      gridTemplateRows="1fr"
      alignItems="center"
      sx={{
        "& > div": {
          gridColumn: isNonMobile ? undefined : "span 4",
        },
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "20px" : "10px"}
            fontWeight="bold"
          >
            {nombreAccion}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(1, 1fr)",
          }}
        >
          <Box>
            <Box>
              <TextField
                fullWidth
                multiline
                rows={isNonMobile ? 20 : 15}
                variant="outlined"
                type="text"
                label="Retroalimentación"
                onChange={onChangeRetroalimentacion}
                value={valuesRetroalimentacion}
                name="retroalimentacion"
                InputProps={{
                  readOnly: readonly,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Retroalimentacion;
