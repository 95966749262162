import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Typography,
  useTheme,
  Zoom,
  IconButton,
} from "@mui/material";
import { tokens } from "../theme";
import CloseIcon from "@mui/icons-material/Close";

const PopupRevision = ({
  title,
  children,
  open,
  handleSubmitSi,
  handleSubmitNo,
  onClose,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Dialog
      TransitionComponent={Zoom}
      open={open}
      sx={{
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiPaper-root": {
                background: colors.grey[900],
              },
            }
          : {
              "& .MuiPaper-root": {
                background: "#fcfcfc",
              },
            }),
      }}
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="bold" color={colors.grey[100]}>
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: colors.grey[100],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" color={colors.grey[100]}>
            {children}
          </Typography>
        </DialogContentText>
      </DialogContent>
      {theme.palette.mode === "dark" ? (
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleSubmitSi}
            autoFocus
          >
            Aprobar
          </Button>
          <Button color="secondary" variant="outlined" onClick={handleSubmitNo}>
            Rechazar
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmitSi}
            autoFocus
          >
            Aprobar
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmitNo}
          >
            Rechazar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PopupRevision;
