import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import GradingIcon from "@mui/icons-material/Grading";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackdropSimple from "../../components/BackdropSimple";
import { useLocation } from "react-router-dom";
import { useDataContextSession } from "../../context/DataContextSession";

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const location = useLocation();
  const { permisos } = useDataContextSession();

  // Extract the pathname from the location object
  const currentPath = location.pathname;

  // Define a function to check if the current route matches a specific route
  const isActiveRoute = (route) => {
    return currentPath === route;
  };

  // Use isActiveRoute function to determine if the route is active
  // You can replace 'Inicio' with your actual route
  const isInicioActive = isActiveRoute("/");
  const isProgramaActive = isActiveRoute("/programas");
  const isProyectoActive = isActiveRoute("/proyectos");
  const isActividadesActive = isActiveRoute("/actividades");
  const isIniciativasActive = isActiveRoute("/emergentes");
  const isRevisionActive = isActiveRoute("/revision");
  const isActualizacionActive = isActiveRoute("/actualizacion");
  const isVisualizacionActive = isActiveRoute("/calendar");
  const isEvaluacionActive = isActiveRoute("/formPrograma");
  const isRepertorioActive = isActiveRoute("/team");
  const isIndicadoresActive = isActiveRoute("/reportes");

  const handleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
    setOpenBackdrop(!openBackdrop);
  };

  const shouldRenderDivider = () => {
    const allowedValues = ["VIME01", "VIME02", "VIME03", "VIME04", "VIME05","VIME06","VIME07","VIME08"];
    return allowedValues.some(value => permisos.some(permiso => permiso.startsWith(value)));
  };
  const tienePermiso = (codigoPermiso) => {
    return permisos.some(permiso => permiso.startsWith(codigoPermiso));
  };
  return (
    <Box
      sx={{
        ...(theme.palette.mode === "dark"
          ? {
              "& .pro-sidebar-inner": {
                background: `${colors.grey[800]} !important`,
              },
              "& .pro-icon-wrapper": {
                backgroundColor: "transparent !important",
              },
              "& .pro-inner-item": {
                padding: isNonMobile
                  ? "5px 35px 5px 20px !important"
                  : "0px 0px 0px 16px !important",
              },
              "& .pro-inner-item:hover": {
                color: `${colors.orangeAccent[400]} !important`,
              },
              "& .pro-menu-item.active": {
                color: `${colors.orangeAccent[400]} !important`,
              },
            }
          : {
              "& .pro-sidebar-inner": {
                background: `${colors.grey[800]} !important`,
              },
              "& .pro-icon-wrapper": {
                backgroundColor: "transparent !important",
              },
              "& .pro-inner-item": {
                padding: isNonMobile
                  ? "5px 35px 5px 20px !important"
                  : "0px 0px 0px 16px !important",
              },
              "& .pro-inner-item:hover": {
                color: `${colors.orangeAccent[400]} !important`,
              },
              "& .pro-menu-item.active": {
                color: `${colors.orangeAccent[500]} !important`,
              },
            }),
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        collapsedWidth={
          isNonMobile ? "4.7vw" : isNonMobileMedium ? "4.9vw" : "5.5vw"
        }
        width={isNonMobile ? "12vw" : isNonMobileMedium ? "16vw" : "18vw"}
      >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={handleCollapsed}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: isNonMobile ? "10px 0px 10px 5px" : "0px 0px 0px 0px",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
                <img
                  src="../../assets/logos/vimeLogos/output-onlinepngtools.png"
                  alt="logo"
                  style={{
                    position: "absolute",
                    left: isNonMobile ? "105px" : "130px",
                    objectFit: "scale-down",
                    height: isNonMobile ? "100px" : "100px",
                    width: isNonMobile ? "110px" : "100px",
                  }}
                />
              </Box>
            )}
          </MenuItem>

          {/* MENU ITEMS */}
          <Box
            marginLeft={isCollapsed ? (isNonMobile ? "5px" : "0px") : undefined}
          >
            {/* INICIO */}
            {isCollapsed ? (
              <Box
                padding={
                  isNonMobile
                    ? "0px 0px 25px 0px"
                    : isNonMobileMedium
                    ? "0px 0px 20px 0px"
                    : "0px 0px 20px 0px"
                }
              >
                <MenuItem
                  active={isInicioActive}
                  style={{
                    color: colors.grey[100],
                  }}
                  icon={
                    <HomeOutlinedIcon
                      sx={{ fontSize: isNonMobile ? "24px" : "24px" }}
                    />
                  }
                >
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography
                      variant={isNonMobile ? "h6" : "h7"}
                      display="flex"
                      position="absolute"
                      left={isNonMobile ? "23px" : "20px"}
                      top={isNonMobile ? "35px" : "28px"}
                    >
                      Inicio
                    </Typography>
                  </Link>
                </MenuItem>
              </Box>
            ) : (
              <MenuItem
                active={isInicioActive}
                onClick={handleCollapsed}
                style={{
                  color: colors.grey[100],
                }}
                icon={<HomeOutlinedIcon />}
              >
                <Typography>Inicio</Typography>
                <Link to="/" />
              </MenuItem>
            )}

            {isCollapsed ? (
              <Box
                padding={
                  isNonMobile
                    ? "0px 5px 8px 5px"
                    : isNonMobileMedium
                    ? "0px 5px 8px 5px"
                    : "0px 5px 8px 5px"
                }
              >
                {shouldRenderDivider() && <Divider />}
              </Box>
            ) : (
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{
                  ...(isCollapsed && { m: "15px 0 5px 5px" }),
                  ...(!isCollapsed && {
                    m: isNonMobile ? "15px 0 5px 20px" : "5px 0 5px 20px",
                  }),
                }}
              >
                {shouldRenderDivider() && "Planificación"}
              </Typography>
            )}

            {/* PROGRAMAS */}
            {tienePermiso("VIME01") &&
              (isCollapsed ? (
                <Box
                  padding={
                    isNonMobile
                      ? "0px 0px 10px 0px"
                      : isNonMobileMedium
                      ? "0px 0px 15px 0px"
                      : "0px 0px 15px 0px"
                  }
                >
                  <MenuItem
                    active={isProgramaActive}
                    style={{
                      color: colors.grey[100],
                    }}
                    icon={<AssignmentOutlinedIcon sx={{ fontSize: "24px" }} />}
                  >
                    <Link
                      to="/programas"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        display="flex"
                        position="absolute"
                        left={isNonMobile ? "6px" : "7px"}
                        top={isNonMobile ? "35px" : "30px"}
                      >
                        Programas
                      </Typography>
                    </Link>
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem
                  active={isProgramaActive}
                  onClick={handleCollapsed}
                  style={{
                    color: colors.grey[100],
                  }}
                  icon={<AssignmentOutlinedIcon />}
                >
                  <Typography>Programas</Typography>
                  <Link to="/programas" />
                </MenuItem>
              ))}

            {/* PROYECYOS */}
            {tienePermiso("VIME02") &&
              (isCollapsed ? (
                <Box
                  padding={
                    isNonMobile
                      ? "0px 0px 10px 0px"
                      : isNonMobileMedium
                      ? "0px 0px 15px 0px"
                      : "0px 0px 15px 0px"
                  }
                >
                  <MenuItem
                    active={isProyectoActive}
                    style={{
                      color: colors.grey[100],
                    }}
                    icon={<FolderCopyOutlinedIcon sx={{ fontSize: "24px" }} />}
                  >
                    <Link
                      to="/proyectos"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        display="flex"
                        position="absolute"
                        left={isNonMobile ? "7px" : "8px"}
                        top={isNonMobile ? "35px" : "30px"}
                      >
                        Proyectos
                      </Typography>
                    </Link>
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem
                  active={isProyectoActive}
                  onClick={handleCollapsed}
                  style={{
                    color: colors.grey[100],
                  }}
                  icon={<FolderCopyOutlinedIcon />}
                >
                  <Typography>Proyectos</Typography>
                  <Link to="/proyectos" />
                </MenuItem>
              ))}

            {/* ACTIVIDADES */}
            {tienePermiso("VIME03") &&
              (isCollapsed ? (
                <Box
                  padding={
                    isNonMobile
                      ? "0px 0px 8px 0px"
                      : isNonMobileMedium
                      ? "0px 0px 13px 0px"
                      : "0px 0px 13px 0px"
                  }
                >
                  <MenuItem
                    active={isActividadesActive}
                    style={{
                      color: colors.grey[100],
                    }}
                    icon={
                      <LocalActivityOutlinedIcon sx={{ fontSize: "24px" }} />
                    }
                  >
                    <Link
                      to="/actividades"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        display="flex"
                        position="absolute"
                        left={isNonMobile ? "4px" : "5px"}
                        top={isNonMobile ? "35px" : "28px"}
                      >
                        Actividades
                      </Typography>
                    </Link>
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem
                  active={isActividadesActive}
                  onClick={handleCollapsed}
                  style={{
                    color: colors.grey[100],
                  }}
                  icon={<LocalActivityOutlinedIcon />}
                >
                  <Typography>Actividades</Typography>
                  <Link to="/actividades" />
                </MenuItem>
              ))}

            {/* INICIATIVAS EMERGENTES */}
            {tienePermiso("VIME04") &&
              (isCollapsed ? (
                <Box
                  padding={
                    isNonMobile
                      ? "0px 0px 37px 0px"
                      : isNonMobileMedium
                      ? "0px 0px 37px 0px"
                      : "0px 0px 37px 0px"
                  }
                >
                  <MenuItem
                    active={isIniciativasActive}
                    style={{
                      color: colors.grey[100],
                    }}
                    icon={
                      <VolunteerActivismOutlinedIcon
                        sx={{ fontSize: "24px" }}
                      />
                    }
                  >
                    <Link
                      to="/emergentes"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        display="flex"
                        position="absolute"
                        left={isNonMobile ? "3px" : "5px"}
                        top={isNonMobile ? "40px" : "33px"}
                        align="center"
                      >
                        Iniciativas
                        <br />
                        Emergentes
                      </Typography>
                    </Link>
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem
                  active={isIniciativasActive}
                  onClick={handleCollapsed}
                  style={{
                    color: colors.grey[100],
                  }}
                  icon={<VolunteerActivismOutlinedIcon />}
                >
                  <Typography>Iniciativas Emergentes</Typography>
                  <Link to="/emergentes" />
                </MenuItem>
              ))}

            {/* REVISIÓN */}
            {tienePermiso("VIME05") &&
              (isCollapsed ? (
                <Box
                  padding={
                    isNonMobile
                      ? "0px 0px 25px 0px"
                      : isNonMobileMedium
                      ? "0px 0px 20px 0px"
                      : "0px 0px 20px 0px"
                  }
                >
                  <MenuItem
                    active={isRevisionActive}
                    style={{
                      color: colors.grey[100],
                    }}
                    icon={<GradingIcon sx={{ fontSize: "24px" }} />}
                  >
                    <Link
                      to="/revision"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        display="flex"
                        position="absolute"
                        left={isNonMobile ? "12px" : "12px"}
                        top={isNonMobile ? "35px" : "30px"}
                      >
                        Revisión
                      </Typography>
                    </Link>
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem
                  active={isRevisionActive}
                  onClick={handleCollapsed}
                  style={{
                    color: colors.grey[100],
                  }}
                  icon={<GradingIcon />}
                >
                  <Typography>Revisión</Typography>
                  <Link to="/revision" />
                </MenuItem>
              ))}

            {tienePermiso("VIME08") && (isCollapsed ? (
              <Box
                padding={
                  isNonMobile
                    ? "0px 5px 5px 5px"
                    : isNonMobileMedium
                    ? "0px 5px 5px 5px"
                    : "0px 5px 5px 5px"
                }
              >
                <Divider />
              </Box>
            ) : (
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{
                  ...(isCollapsed && { m: "15px 0 5px 5px" }),
                  ...(!isCollapsed && {
                    m: isNonMobile ? "15px 0 5px 20px" : "10px 0 5px 20px",
                  }),
                }}
              >
                Seguimiento y Monitoreo
              </Typography>
            ))}

            {/* ACTUALIZACIÓN */}
            {tienePermiso("VIME08") && (isCollapsed ? (
              <Box
                padding={
                  isNonMobile
                    ? "0px 0px 10px 0px"
                    : isNonMobileMedium
                    ? "0px 0px 15px 0px"
                    : "0px 0px 15px 0px"
                }
              >
                <MenuItem
                  active={isActualizacionActive}
                  style={{
                    color: colors.grey[100],
                  }}
                  icon={
                    <PendingActionsOutlinedIcon sx={{ fontSize: "24px" }} />
                  }
                >
                  <Link
                    to="/formPrograma"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography
                      variant={isNonMobile ? "h6" : "h7"}
                      display="flex"
                      position="absolute"
                      left={isNonMobile ? "1px" : "3px"}
                      top={isNonMobile ? "35px" : "30px"}
                    >
                      Actualización
                    </Typography>
                  </Link>
                </MenuItem>
              </Box>
            ) : (
              <MenuItem
                active={isActualizacionActive}
                onClick={handleCollapsed}
                style={{
                  color: colors.grey[100],
                }}
                icon={<PendingActionsOutlinedIcon />}
              >
                <Typography>Actualización</Typography>
                <Link to="/formPrograma" />
              </MenuItem>
            ))}

          
            {tienePermiso("VIME07") && (isCollapsed ? (
              <Box
                padding={
                  isNonMobile
                    ? "0px 5px 5px 5px"
                    : isNonMobileMedium
                    ? "0px 5px 5px 5px"
                    : "0px 5px 5px 5px"
                }
              >
                <Divider />
              </Box>
            ) : (
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{
                  ...(isCollapsed && { m: "15px 0 5px 5px" }),
                  ...(!isCollapsed && { m: "15px 0 5px 20px" }),
                }}
              >
                Indicadores
              </Typography>
            ))}

            {/* INDICADORES */}
            {tienePermiso("VIME07") && (isCollapsed ? (
              <Box>
                <MenuItem
                  active={isIndicadoresActive}
                  style={{
                    color: colors.grey[100],
                  }}
                  icon={<DonutSmallOutlinedIcon sx={{ fontSize: "24px" }} />}
                >
                  <Link
                    to="/reportes"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography
                      variant={isNonMobile ? "h6" : "h7"}
                      display="flex"
                      position="absolute"
                      left={isNonMobile ? "4px" : "6px"}
                      top={isNonMobile ? "35px" : "30px"}
                    >
                      Indicadores
                    </Typography>
                  </Link>
                </MenuItem>
              </Box>
            ) : (
              <MenuItem
                active={isIndicadoresActive}
                onClick={handleCollapsed}
                style={{
                  color: colors.grey[100],
                }}
                icon={<DonutSmallOutlinedIcon />}
              >
                <Typography>Indicadores</Typography>
                <Link to="/reportes" />
              </MenuItem>
            ))}

          </Box>
        </Menu>
      </ProSidebar>
      <BackdropSimple open={openBackdrop} />
    </Box>
  );
};
export default Sidebar;
