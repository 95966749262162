import { Box, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDataContext} from "../../../context/DataContext";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TooltipInfo from "../../../components/TooltipInfo";
import dayjs from "dayjs";

const InformacionGeneral = ({
  actividadID,
  touchedNombrePrograma,
  errorsNombrePrograma,
  touchedResponsablePrograma,
  errorsResponsablePrograma,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { rowPairs, setRowPairs } =
    useDataContext();
  const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);
  console.log(actividadData)
  const [errorFechaTermino, setErrorFechaTermino] = useState("");


  const onChangeNombreActividad = (e) => {
    const newValue = e.target.value;
    setRowPairs(prevRowPairs => prevRowPairs.map(actividad => 
      actividad.id === actividadID ? { ...actividad, valuesNombreActividadP: newValue } : actividad
    ));
  };
  
  const onChangeNombreResponsable = (e) => {
    const newValue = e.target.value;
    setRowPairs(prevRowPairs => prevRowPairs.map(actividad => 
      actividad.id === actividadID ? { ...actividad, valuesResponsableActividadP: newValue } : actividad
    ));
  };

  const handleChangeFechaTermino = (newDate) => {
  setRowPairs(prevRowPairs => prevRowPairs.map(actividad => 
    actividad.id === actividadID ? { ...actividad, valuesFechaTermino: newDate } : actividad
  ));
};

const handleChangeFechaInicio = (newDate) => {
  setRowPairs(prevRowPairs => prevRowPairs.map(actividad => 
    actividad.id === actividadID ? { ...actividad, valuesFechaInicio: newDate } : actividad
  ));
};

 /* const shouldDisableDate = (date) => {
    console.log(date)
    const currentDate = dayjs();
    const oneDayAhead = currentDate;

    return date.isBefore(oneDayAhead);
  };*/

  const shouldDisableTime = (date) => {
    return actividadData.valuesFechaInicio && date.minute() === dayjs(actividadData.valuesFechaInicio).minute();
  };

  return (
    <Box
      height={isNonMobile ? "55vh" : isNonMobileMedium ? "55vh" : "50vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "0px" : "10px"}
      display="grid"
      rowGap={1}
      gridTemplateColumns="repeat(1, 1fr)"
      sx={{
        "& > div": {
          gridColumn: isNonMobile ? undefined : "span 4",
        },
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiPickersDay-root": {
          "&.Mui-selected": {
            backgroundColor: colors.Cerulean,
          },
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
            fontWeight="bold"
          >
            1. Datos generales de la actividad{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Nombre de la actividad:
                  </Typography>
                  <br></br>Denominación que identifica y resume la esencia de la
                  actividad de manera concisa y clara, reflejando su propósito
                  principal. (máximo 250 caracteres)<br></br>
                  <br />
                  <Typography fontWeight="bold" variant="h7">
                    Nombre del responsable:
                  </Typography>
                  <br></br>Denominación del responsable a cargo de la actividad.
                  (máximo 250 caracteres)
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                1.1. Nombre de la Actividad
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Nombre de la Actividad"
                onChange={onChangeNombreActividad}
                value={actividadData.valuesNombreActividadP}
                name="nombrePrograma"
                error={!!touchedNombrePrograma && !!errorsNombrePrograma}
                helperText={touchedNombrePrograma && errorsNombrePrograma}
              />
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                1.2. Responsable de la Actividad
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Responsable del Programa"
                onChange={onChangeNombreResponsable}
                value={actividadData?.valuesResponsableActividadP}
                name="responsablePrograma"
                error={
                  !!touchedResponsablePrograma && !!errorsResponsablePrograma
                }
                helperText={
                  touchedResponsablePrograma && errorsResponsablePrograma
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            fontWeight="bold"
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
          >
            2. Duración
          </Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 300px)"
          columnGap={10}
        >
          <Box>
            <Box marginBottom="10px">
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
              >
                2.1 Fecha de Inicio
              </Typography>
            </Box>

            <Box>
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DateTimePicker
                  //disablePast
                  //shouldDisableDate={shouldDisableDate}
                  value={dayjs(actividadData.valuesFechaInicio)}
                  onChange={handleChangeFechaInicio}
                  ampm={false}
                  label="dd/mm/yyyy hh:mm"
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box>
            <Box marginBottom="10px">
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
              >
                2.2 Fecha de Termino
              </Typography>
            </Box>

            <Box>
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DateTimePicker
                  disabled={actividadData.valuesFechaInicio === null}
                  // disablePast
                  minDateTime={dayjs(actividadData.valuesFechaInicio)}
                  value={dayjs(actividadData.valuesFechaTermino)}
                  onChange={handleChangeFechaTermino}
                  shouldDisableTime={shouldDisableTime}
                  ampm={false}
                  label="dd/mm/yyyy hh:mm"
                  onError={(newError) => setErrorFechaTermino(newError)}
                  slotProps={{
                    textField: {
                      helperText:
                        errorFechaTermino &&
                        "La fecha de termino no puede ser menor a la fecha de inicio.",
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InformacionGeneral;
