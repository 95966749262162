import axios from "axios";

const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
function formatDateToSQLCompatible() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

const formattedDate = formatDateToSQLCompatible();

const updateIncompleto = async (
  valuesNombrePrograma,
  valuesResponsablePrograma,
  valuesDiagnostico,
  valuesDireccion,
  valuesRecursosMateriales,
  valuesTransporteAlimentacion,
  valuesOtrosRecursos,
  valuesTotal,
  valuesResultadoEsperado,
  selectedFuenteFinanciamiento,
  selectedFocoPrioritario,
  selectedCicloFormativo,
  selectedLineaDeAccionRows,
  selectedPEI,
  selectedPais,
  selectedRegion,
  selectedCiudad,
  selectedAlcanceTerritorial,
  selectedGrupoInteresRelevante,
  selectedEtapaParticipa,
  selectedParticipantesEsperados,
  selectedTipoParticipantes,
  selectedContribucionInterna,
  selectedContribucionExterna,
  selectedFacultadRows,
  selectedCarreraRows,
  selectedAmbitoRows,
  selectedLineaSubRows,
  selectedAsignaturaRows,
  selectedActorParticipa,
  selectedODS,
  selectedInstrumentoEvaluacion,
  getIniciativa,
  rowProgramaID,
  existingFuenteFinanciamiento,
  existingFacultad,
  existingCarrera,
  existingAmbito,
  existingLineaAccion,
  existingLineaSub,
  existingAsignatura,
  existingCicloFormativo,
  existingPEI,
  existingAlcanceTerritorial,
  existingGrupoInteresRelevante,
  existingEtapaParticipa,
  existingTipoParticipantes,
  existingContribucionInterna,
  existingContribucionExterna,
  existingODS,
  existingInstrumentoEvaluacion,
  valuesFechaInicio,
  valuesFechaTermino,
  valuesObjetivoGeneralActividad,
  valuesBreveDescripcionActividad,
  clearValues,
  setOpenBackdrop,
  valuesIdUsuario,
  valuesMail,
  valuesUsername
) => {
  try {
    await axios.put(`https://vime.usek.cl/api/iniciativa/${rowProgramaID}`, {
      id: rowProgramaID,
      id_tipoAccion: 4,
      nombreIniciativa: valuesNombrePrograma,
      nombreResponsable: valuesResponsablePrograma,
      fechaInicio: valuesFechaInicio,
      fechaTermino: valuesFechaTermino,
      diagnostico: valuesDiagnostico,
      descripcion: valuesBreveDescripcionActividad,
      objetivoGeneral: valuesObjetivoGeneralActividad,
      direccion: valuesDireccion,
      presupuestoRM:
        parseFloat(removeNonNumeric(valuesRecursosMateriales)) || 0,
      presupuestoTA:
        parseFloat(removeNonNumeric(valuesTransporteAlimentacion)) || 0,
      presupuestoOR: parseFloat(removeNonNumeric(valuesOtrosRecursos)) || 0,
      presupuestoTotal: parseFloat(removeNonNumeric(valuesTotal)) || 0,
      id_usuario: valuesIdUsuario,
      id_estado: 2,
      resultadoEsperado: valuesResultadoEsperado,
      mailUsuario: valuesMail,
      nombreAutor: valuesUsername,
    });

    const addedFuenteFinanciamientoIds = selectedFuenteFinanciamiento.filter(
      (item) => !existingFuenteFinanciamiento.includes(item.id_fuente)
    );

    const removedFuenteFinanciamientoIds = existingFuenteFinanciamiento.filter(
      (item) => !selectedFuenteFinanciamiento.includes(item.id_fuente)
    );

    await axios.delete(
      "https://vime.usek.cl/api/fuenteFinanciamientoSeleccion/iniciativa",
      {
        data: {
          id_fuente: removedFuenteFinanciamientoIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/fuenteFinanciamientoSeleccion", {
      id_fuente: addedFuenteFinanciamientoIds,
      id_iniciativa: rowProgramaID,
    });

    const addedFacultadIds = selectedFacultadRows.filter(
      (item) => !existingFacultad.includes(item.id_facultad)
    );

    const removedFacultadIds = existingFacultad.filter(
      (item) => !selectedFacultadRows.includes(item.id_facultad)
    );

    await axios.delete(
      "https://vime.usek.cl/api/facultadSeleccion/iniciativa",
      {
        data: {
          id_facultad: removedFacultadIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/facultadSeleccion", {
      id_facultad: addedFacultadIds,
      id_iniciativa: rowProgramaID,
    });

    const addedCarreraIds = selectedCarreraRows.filter(
      (item) => !existingCarrera.includes(item.id_carrera)
    );

    const removedCarreraIds = existingCarrera.filter(
      (item) => !selectedCarreraRows.includes(item.id_carrera)
    );

    await axios.delete("https://vime.usek.cl/api/carreraSeleccion/iniciativa", {
      data: {
        id_carrera: removedCarreraIds,
        id_iniciativa: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/carreraSeleccion", {
      id_carrera: addedCarreraIds,
      id_iniciativa: rowProgramaID,
    });

    const addedCicloFormativoIds = selectedCicloFormativo.filter(
      (item) => !existingCicloFormativo.includes(item.id_ciclo)
    );

    const removedCicloFormativoIds = existingCicloFormativo.filter(
      (item) => !selectedCicloFormativo.includes(item.id_ciclo)
    );

    await axios.delete(
      "https://vime.usek.cl/api/cicloFormativoSeleccion/iniciativa",
      {
        data: {
          id_ciclo: removedCicloFormativoIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/cicloFormativoSeleccion", {
      id_ciclo: addedCicloFormativoIds,
      id_iniciativa: rowProgramaID,
    });

    await axios.put(
      `https://vime.usek.cl/api/focoPrioritarioSeleccion/iniciativa/${rowProgramaID}`,
      {
        id_foco: selectedFocoPrioritario,
      }
    );

    const addedAmbitoIds = selectedAmbitoRows.filter(
      (item) => !existingAmbito.includes(item.id_ambito)
    );

    const removedAmbitoIds = existingAmbito.filter(
      (item) => !selectedAmbitoRows.includes(item.id_ambito)
    );

    await axios.delete("https://vime.usek.cl/api/ambitosSeleccion/iniciativa", {
      data: {
        id_ambito: removedAmbitoIds,
        id_iniciativa: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/ambitosSeleccion", {
      id_ambito: addedAmbitoIds,
      id_iniciativa: rowProgramaID,
    });

    const addedLineaAccionIds = selectedLineaDeAccionRows.filter(
      (item) => !existingLineaAccion.includes(item.id_linea)
    );

    const removedLineaAccionIds = existingLineaAccion.filter(
      (item) => !selectedLineaDeAccionRows.includes(item.id_linea)
    );

    await axios.delete(
      "https://vime.usek.cl/api/lineasDeAccionSeleccion/iniciativa",
      {
        data: {
          id_linea: removedLineaAccionIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/lineasDeAccionSeleccion", {
      id_linea: addedLineaAccionIds,
      id_iniciativa: rowProgramaID,
    });

    const addedLineaSubIds = selectedLineaSubRows.filter(
      (item) => !existingLineaSub.includes(item.id_lineaSub)
    );

    const removedLineaSubIds = existingLineaSub.filter(
      (item) => !selectedLineaSubRows.includes(item.id_lineaSub)
    );

    await axios.delete(
      "https://vime.usek.cl/api/lineasSubCategoriaSeleccion/iniciativa",
      {
        data: {
          id_lineaSub: removedLineaSubIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/lineasSubCategoriaSeleccion", {
      id_lineaSub: addedLineaSubIds,
      id_iniciativa: rowProgramaID,
    });

    const addedAsignaturaIds = selectedAsignaturaRows.filter(
      (item) => !existingAsignatura.includes(item.id_asignatura)
    );

    const removedAsignaturaIds = existingAsignatura.filter(
      (item) => !selectedAsignaturaRows.includes(item.id_asignatura)
    );

    await axios.delete(
      "https://vime.usek.cl/api/asignaturasSeleccion/iniciativa",
      {
        data: {
          id_asignatura: removedAsignaturaIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/asignaturasSeleccion", {
      id_asignatura: addedAsignaturaIds,
      id_iniciativa: rowProgramaID,
    });

    const addedPEIIds = selectedPEI.filter(
      (item) => !existingPEI.includes(item.id_pei)
    );

    const removedPEIIds = existingPEI.filter(
      (item) => !selectedPEI.includes(item.id_pei)
    );

    await axios.delete("https://vime.usek.cl/api/peiSeleccion/iniciativa", {
      data: {
        id_pei: removedPEIIds,
        id_iniciativa: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/peiSeleccion", {
      id_pei: addedPEIIds,
      id_iniciativa: rowProgramaID,
    });

    const addedAlcanceTerritorialIds = selectedAlcanceTerritorial.filter(
      (item) => !existingAlcanceTerritorial.includes(item.id_alcance)
    );

    const removedAlcanceTerritorialIds = existingAlcanceTerritorial.filter(
      (item) => !selectedAlcanceTerritorial.includes(item.id_alcance)
    );

    await axios.delete(
      "https://vime.usek.cl/api/alcanceTerritorialSeleccion/iniciativa",
      {
        data: {
          id_alcance: removedAlcanceTerritorialIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/alcanceTerritorialSeleccion", {
      id_alcance: addedAlcanceTerritorialIds,
      id_iniciativa: rowProgramaID,
    });

    await axios.put(
      `https://vime.usek.cl/api/paisSeleccion/iniciativa/${rowProgramaID}`,
      {
        id_pais: selectedPais,
      }
    );

    await axios.put(
      `https://vime.usek.cl/api/regionSeleccion/iniciativa/${rowProgramaID}`,
      {
        id_region: selectedRegion,
      }
    );

    await axios.put(
      `https://vime.usek.cl/api/ciudadSeleccion/iniciativa/${rowProgramaID}`,
      {
        id_ciudad: selectedCiudad,
      }
    );

    const addedGrupoInteresRelevanteIds = selectedGrupoInteresRelevante.filter(
      (item) => !existingGrupoInteresRelevante.includes(item.id_grupo)
    );

    const removedGrupoInteresRelevanteIds =
      existingGrupoInteresRelevante.filter(
        (item) => !selectedGrupoInteresRelevante.includes(item.id_grupo)
      );

    await axios.delete(
      "https://vime.usek.cl/api/grupoInteresRelevanteSeleccion/iniciativa",
      {
        data: {
          id_grupo: removedGrupoInteresRelevanteIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post(
      "https://vime.usek.cl/api/grupoInteresRelevanteSeleccion",
      {
        id_grupo: addedGrupoInteresRelevanteIds,
        id_iniciativa: rowProgramaID,
      }
    );

    await axios.put(
      `https://vime.usek.cl/api/actorParticipaSeleccion/iniciativa/${rowProgramaID}`,
      {
        id_actor: selectedActorParticipa,
      }
    );

    const addedEtapaParticipaIds = selectedEtapaParticipa.filter(
      (item) => !existingEtapaParticipa.includes(item.id_etapa)
    );

    const removedEtapaParticipaIds = existingEtapaParticipa.filter(
      (item) => !selectedEtapaParticipa.includes(item.id_etapa)
    );

    await axios.delete(
      "https://vime.usek.cl/api/etapaParticipaSeleccion/iniciativa",
      {
        data: {
          id_etapa: removedEtapaParticipaIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/etapaParticipaSeleccion", {
      id_etapa: addedEtapaParticipaIds,
      id_iniciativa: rowProgramaID,
    });

    const addedTipoParticipantesIds = selectedTipoParticipantes.filter(
      (item) => !existingTipoParticipantes.includes(item.id_tipoParticipantes)
    );

    const removedTipoParticipantesIds = existingTipoParticipantes.filter(
      (item) => !selectedTipoParticipantes.includes(item.id_tipoParticipantes)
    );

    await axios.delete(
      "https://vime.usek.cl/api/tipoParticipantesSeleccion/iniciativa",
      {
        data: {
          id_tipoParticipantes: removedTipoParticipantesIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/tipoParticipantesSeleccion", {
      id_tipoParticipantes: addedTipoParticipantesIds,
      id_iniciativa: rowProgramaID,
    });

    await axios.put(
      `https://vime.usek.cl/api/participantesEsperadosSeleccion/iniciativa/${rowProgramaID}`,
      {
        id_participantesEsperados: selectedParticipantesEsperados,
      }
    );

    const addedContribucionInternaIds = selectedContribucionInterna.filter(
      (item) =>
        !existingContribucionInterna.includes(item.id_contribucionInterna)
    );

    const removedContribucionInternaIds = existingContribucionInterna.filter(
      (item) =>
        !selectedContribucionInterna.includes(item.id_contribucionInterna)
    );

    await axios.delete(
      "https://vime.usek.cl/api/contribucionInternaSeleccion/iniciativa",
      {
        data: {
          id_contribucionInterna: removedContribucionInternaIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/contribucionInternaSeleccion", {
      id_contribucionInterna: addedContribucionInternaIds,
      id_iniciativa: rowProgramaID,
    });

    const addedContribucionExternaIds = selectedContribucionExterna.filter(
      (item) =>
        !existingContribucionExterna.includes(item.id_contribucionExterna)
    );

    const removedContribucionExternaIds = existingContribucionExterna.filter(
      (item) =>
        !selectedContribucionExterna.includes(item.id_contribucionExterna)
    );

    await axios.delete(
      "https://vime.usek.cl/api/contribucionExternaSeleccion/iniciativa",
      {
        data: {
          id_contribucionExterna: removedContribucionExternaIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post("https://vime.usek.cl/api/contribucionExternaSeleccion", {
      id_contribucionExterna: addedContribucionExternaIds,
      id_iniciativa: rowProgramaID,
    });

    const addedODSIds = selectedODS.filter(
      (item) => !existingODS.includes(item.id_ods)
    );

    const removedODSIds = existingODS.filter(
      (item) => !selectedODS.includes(item.id_ods)
    );

    await axios.delete("https://vime.usek.cl/api/odsSeleccion/iniciativa", {
      data: {
        id_ods: removedODSIds,
        id_iniciativa: rowProgramaID,
      },
    });

    await axios.post("https://vime.usek.cl/api/odsSeleccion", {
      id_ods: addedODSIds,
      id_iniciativa: rowProgramaID,
    });

    const addedInstrumentoEvaluacionIds = selectedInstrumentoEvaluacion.filter(
      (item) =>
        !existingInstrumentoEvaluacion.includes(item.id_instrumentoEvaluacion)
    );

    const removedInstrumentoEvaluacionIds =
      existingInstrumentoEvaluacion.filter(
        (item) =>
          !selectedInstrumentoEvaluacion.includes(item.id_instrumentoEvaluacion)
      );

    await axios.delete(
      "https://vime.usek.cl/api/instrumentoEvaluacionSeleccion/iniciativa",
      {
        data: {
          id_instrumentoEvaluacion: removedInstrumentoEvaluacionIds,
          id_iniciativa: rowProgramaID,
        },
      }
    );

    await axios.post(
      "https://vime.usek.cl/api/instrumentoEvaluacionSeleccion",
      {
        id_instrumentoEvaluacion: addedInstrumentoEvaluacionIds,
        id_iniciativa: rowProgramaID,
      }
    );

    getIniciativa();
    clearValues();
    setOpenBackdrop(false);
  } catch (error) {
    console.error("Error updating program:", error);
  }
  try {
    await axios.post("https://vime.usek.cl/api/auditoria", {
      Username: valuesIdUsuario,
      Fecha: formattedDate,
      Accion: "Edición",
      Opcion: "Edición de iniciativa incompleta",
      Transaccion: `El usuario editó la iniciativa incompleta con el ID: ${rowProgramaID}`,
      Sistema: "VIME",
    });
  } catch (error) {
    console.error("Error ingresando auditoria: ", error);
  }
};

export default updateIncompleto;
