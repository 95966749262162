import React, { useState } from "react";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import PopupAlert from "./PopupAlert";
import FormularioActividadP from "../forms/FormularioActividadP";
import PopupFormActividad from "./PopupFormActividad";
import { useDataContext } from "../context/DataContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import "dayjs/locale/es";

const DynamicRowActividad = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopupAlert, setOpenPopupAlert] = useState(false);
  const [openPopupFormulario, setOpenPopupFormulario] = useState(false);
  const [popupAlertTitle, setPopupAlertTitle] = useState("");
  const [popupAlertMessage, setPopupAlertMessage] = useState("");
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const { rowPairs, setRowPairs, idActividadP, setIDActividadP } =
    useDataContext();
  const [activityToDelete, setActivityToDelete] = useState(null);
  const generateUniqueId = () => {
    const staticString = "ACTPR-";
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hour = String(currentDate.getHours()).padStart(2, "0");
    const minute = String(currentDate.getMinutes()).padStart(2, "0");
    const second = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    const datetimeString = `${year}${month}${day}${hour}${minute}${second}${milliseconds}`;
    const uniqueId = staticString + datetimeString;

    return uniqueId;
  };

  const handleOpenPopupAlert = () => {
    setOpenPopupAlert(true);
  };
  const updateActivityApi = async (activity) => {
    try {
      const response = await fetch(
        `https://vime.usek.cl/api/actividadpnew/${activity.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(activity),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
  const deleteActivityApi = async (id) => {
    try {
      const response = await fetch(
        `https://vime.usek.cl/api/actividadpnew/${id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };
  const handleDeleteConfirmation = (activityId) => {
    // Aquí llamarías a la API para eliminar y luego actualizarías el estado
    deleteActivityApi(activityId)
      .then(() => {
        // Actualizar el estado para quitar la actividad del listado
        setRowPairs(rowPairs.filter((activity) => activity.id !== activityId));
        setActivityToDelete(null); // Restablecer el estado
      })
      .catch((error) => {
        // Manejar error aquí
        console.error("Error al eliminar la actividad:", error);
        setActivityToDelete(null); // Restablecer el estado
      });
  };
  const handleOpenPopupFormulario = (id) => {
    setOpenPopupFormulario(true);
    setIDActividadP(id);
  };

  const handleClosePopupFormulario = () => {
    console.log(idActividadP);
    const activity = rowPairs.find((activity) => activity.id === idActividadP);

    if (activity && !activity.isnew) {
      // Si la actividad no es nueva, llama a la API de actualización
      updateActivityApi(activity)
        .then(() => {
          console.log("Actividad actualizada con éxito");
          // Aquí puedes realizar más acciones, como cerrar el formulario o mostrar un mensaje de éxito.
        })
        .catch((error) => {
          console.error("Error al actualizar la actividad:", error);
        });
    } else {
      console.log("Actividad nueva o ID no encontrado.");
    }

    // Cerrar el formulario
    setOpenPopupFormulario(false);
  };

  const handleSubmitPopupAlertYes = () => {
    if (activityToDelete) {
      handleDeleteConfirmation(activityToDelete.id); // Confirmar la eliminación
    }
    handleClosePopupAlert(); // Cerrar el popup de alerta
  };

  const handleSubmitPopupAlertNo = () => {
    handleClosePopupAlert();
  };
  const handleClosePopupAlert = () => {
    setOpenPopupAlert(false);
    setPopupAlertTitle("");
    setPopupAlertMessage("");
    setActivityToDelete(null);
  };
  const addRow = () => {
    setRowPairs((prevRowPairs) => [
      ...prevRowPairs,
      {
        id: generateUniqueId(),
        id_tipoAccion: 5,
        isnew: true,
        isAmbitoSelected: false,
        selectedCicloFormativo: [],
        selectedPais: 44,
        selectedRegion: "",
        selectedCiudad: "",
        selectedAlcanceTerritorial: [],
        selectedGrupoInteresRelevante: [],
        valuesDireccion: "",
        selectedActorParticipa: "1",
        selectedEtapaParticipa: [],
        selectedAmbito: [],
        valuesDiagnostico: "",
        valuesResultadoEsperado: "",
        selectedParticipantesEsperados: 1,
        selectedTipoParticipantes: [],
        selectedContribucionInterna: [],
        selectedContribucionExterna: [],
        isInstrumentoEvaluacionChecked: {
          1: false,
          2: false,
          4: false,
        },
        isInstrumentoEvaluacionSelected: false,
        valuesRecursosMateriales: "",
        valuesTransporteAlimentacion: "",
        valuesOtrosRecursos: "",
        valuesTotal: "",
        valuesNombreActividadP: "",
        valuesResponsableActividadP: "",
        valuesFechaTermino: dayjs(),
        valuesFechaInicio: dayjs(),
        valuesBreveDescripcion: "",
        valuesObjetivoGeneral: "",
      },
    ]);
  };

  const deleteRowPair = (pairIndex) => {
    const activity = rowPairs[pairIndex];
    if (!activity.isnew) {
      setPopupAlertTitle("Confirmación de eliminación");
      setPopupAlertMessage(
        "¿Está seguro/a de que desea eliminar esta actividad?"
      );

      setActivityToDelete(activity);
      setOpenPopupAlert(true);
    } else {
      setPopupAlertTitle("¿Está seguro/a de borrar todas las actividades?");
      setPopupAlertMessage(
        "Se eliminarán todas las actividades y sus formularios."
      );
      const updatedRowPairs = [...rowPairs];
      updatedRowPairs.splice(pairIndex, 1);
      setRowPairs(updatedRowPairs);
    }
  };

  const renderRowPair = (pairIndex) => {
    const rowPair = rowPairs[pairIndex];
    return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(1, 1fr)"
        key={rowPair.id}
        sx={{
          "& label.Mui-focused": {
            color: colors.grey[100],
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.grey[100],
            },
            "&:hover fieldset": {
              borderColor: colors.orangeAccent[400],
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.orangeAccent[400],
            },
          },
        }}
      >
        <Box>
          <Divider>
            <Typography
              variant={isNonMobile ? "h5" : "h6"}
              color={colors.grey[100]}
              fontWeight="bold"
            >
              {` Actividad ${pairIndex + 1} `}
            </Typography>
          </Divider>
          <Box marginTop="10px" display="grid" alignItems="center">
            <Box>
              <Box
                display="flow-root"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant={isNonMobile ? "h5" : "h6"}
                  color={colors.grey[100]}
                  marginBottom="10px"
                >
                  {rowPair.valuesNombreActividadP ? (
                    <>
                      <Typography
                        variant={isNonMobile ? "h5" : "h6"}
                        color={colors.grey[100]}
                        marginBottom="2px"
                      >
                        <span style={{ margin: "0 0px" }}>
                          {`ACTIVIDAD: `}
                          <strong
                            style={{
                              fontSize: isNonMobile ? "1.2rem" : "1.1rem",
                            }}
                          >
                            {rowPair.valuesNombreActividadP}
                          </strong>
                        </span>
                        <span style={{ margin: "0 15px" }}>
                          {` RESPONSABLE: `}
                          <strong
                            style={{
                              fontSize: isNonMobile ? "1.2rem" : "1.1rem",
                            }}
                          >
                            {rowPair.valuesResponsableActividadP}
                          </strong>
                        </span>
                      </Typography>
                      <Typography
                        variant={isNonMobile ? "h5" : "h6"}
                        color={colors.grey[100]}
                        style={{ marginBottom: "2px" }}
                      >
                        INICIO:
                        <span style={{ margin: "0 15px" }}>
                          {dayjs(rowPair.valuesFechaInicio).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </span>
                        FIN:
                        <span style={{ margin: "0 15px" }}>
                          {dayjs(rowPair.valuesFechaTermino).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </span>
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant={isNonMobile ? "h5" : "h6"}
                      color={colors.grey[100]}
                      marginBottom="2px"
                    ></Typography>
                  )}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center">
                {theme.palette.mode === "dark" ? (
                  <Button
                    onClick={() =>
                      handleOpenPopupFormulario(rowPairs[pairIndex].id)
                    }
                    sx={{ width: "20%" }}
                    color={rowPair.isnew ? "secondary" : "success"}
                    variant="outlined"
                  >
                    {rowPair.valuesNombreActividadP
                      ? `Ver Actividadsss`
                      : `Ingresar Actividad`}
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      handleOpenPopupFormulario(rowPairs[pairIndex].id)
                    }
                    sx={{ width: "20%" }}
                    color={rowPair.isnew ? "secondary" : "info"}
                    variant="contained"
                  >
                    {rowPair.valuesNombreActividadP
                      ? `Ver Actividad`
                      : `Ingresar Actividad`}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end">
          {theme.palette.mode === "dark" ? (
            <Button
              variant="contained"
              color="secondary"
              endIcon={<DeleteIcon />}
              onClick={() => deleteRowPair(pairIndex)}
            >
              Eliminar Actividad
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<DeleteIcon />}
              onClick={() => deleteRowPair(pairIndex)}
            >
              Eliminar Actividad
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {rowPairs.length < 1 ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          {theme.palette.mode === "dark" ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={addRow}
              startIcon={<AddCircleOutlinedIcon />}
              sx={{ width: "300px", height: "70px" }}
            >
              <Typography fontWeight="bold">Añadir Actividades</Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={addRow}
              startIcon={<AddCircleOutlinedIcon />}
              sx={{ width: "300px", height: "70px" }}
            >
              <Typography fontWeight="bold">Añadir Actividades</Typography>
            </Button>
          )}
        </Box>
      ) : (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {theme.palette.mode === "dark" ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={addRow}
                  startIcon={<AddCircleOutlinedIcon />}
                >
                  Añadir Actividades
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addRow}
                  startIcon={<AddCircleOutlinedIcon />}
                >
                  Añadir Actividades
                </Button>
              )}
            </Box>
            <Box>
              {rowPairs.length > 1 && theme.palette.mode === "dark" && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleOpenPopupAlert}
                  endIcon={<DeleteIcon />}
                >
                  Eliminar todos
                </Button>
              )}
              {rowPairs.length > 1 && theme.palette.mode === "light" && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenPopupAlert}
                  endIcon={<DeleteIcon />}
                >
                  Eliminar todos
                </Button>
              )}
            </Box>
          </Box>
          <Box
            marginTop="20px"
            marginBottom="20px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              overflow="auto"
              width={isNonMobile ? "90vw" : "90vw"}
              height={isNonMobile ? "53vh" : "40vh"}
              paddingX="30px"
            >
              {rowPairs.map((_, index) => renderRowPair(index))}
            </Box>
          </Box>
        </Box>
      )}
      <PopupFormActividad
        open={openPopupFormulario}
        handleSubmit={handleClosePopupFormulario}
        onClose={handleClosePopupFormulario}
        maxWidth="xl"
      >
        <FormularioActividadP
          key={idActividadP}
          actividadID={idActividadP}
          onClickFinalizar={handleClosePopupFormulario}
        />
      </PopupFormActividad>
      <PopupAlert
        open={openPopupAlert}
        handleSubmitSi={handleSubmitPopupAlertYes}
        handleSubmitNo={handleSubmitPopupAlertNo}
        title={
          popupAlertTitle
            ? popupAlertTitle
            : "¿Está seguro/a de borrar todas las actividades?"
        }
      >
        {popupAlertMessage
          ? popupAlertMessage
          : "Se eliminarán todas las actividades y sus formularios."}
      </PopupAlert>
    </Box>
  );
};

export default DynamicRowActividad;
