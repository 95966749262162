import { Box, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDataContext} from "../../../context/DataContext";
import TooltipInfo from "../../../components/TooltipInfo";

const DetalleDeLaAccion = ({
  actividadID,
  touchedDiagnostico,
  errorsDiagnostico,
  touchedDescripcion,
  errorsDescripcion,
  touchedResultadosEsperados,
  errorsResultadosEsperados,
  touchedObjetivoGeneral,
  errorsObjetivoGeneral,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { rowPairs, setRowPairs } =
  useDataContext();
  const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);

  

  const onChangeResultadosEsperados = (e) => {
    const newValue = e.target.value;
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? { ...actividad, valuesResultadoEsperado: newValue }
          : actividad
      )
    );
  };

  const onChangeBreveDescripcion = (e) => {
    const newValue = e.target.value;
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? { ...actividad, valuesBreveDescripcion: newValue }
          : actividad
      )
    );
  };

  const onChangeObjetivoGeneral = (e) => {
    const newValue = e.target.value;
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? { ...actividad, valuesObjetivoGeneral: newValue }
          : actividad
      )
    );
  };

  return (
    <Box
      height={isNonMobile ? "55vh" : isNonMobileMedium ? "55vh" : "50vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "0px" : "10px"}
      display="grid"
      rowGap={1}
      gridTemplateColumns="repeat(1, 1fr)"
      sx={{
        "& > div": {
          gridColumn: isNonMobile ? undefined : "span 4",
        },
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" columnGap={4}>
        <Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h4" : "h5"}
              color={colors.grey[100]}
              marginBottom={isNonMobile ? "10px" : "5px"}
              fontWeight="bold"
            >
              8. Breve Descripción
              <TooltipInfo
                title={
                  <p>
                    <Typography fontWeight="bold" variant="h7">
                      Descripción general:
                    </Typography>
                    <br></br>Breve exposición que contextualiza la actividad,
                    resaltando su propósito, participantes involucrados, ámbito
                    de desarrollo y su relevancia en el contexto específico.
                    (máximo 5.000 caracteres)<br></br>
                  </p>
                }
              />
            </Typography>
          </Box>
          <Box>
            <Box>
              <TextField
                fullWidth
                multiline
                rows={isNonMobile ? 4 : 3}
                variant="outlined"
                type="text"
                label="Descripción"
                onChange={onChangeBreveDescripcion}
                value={actividadData.valuesBreveDescripcion}
                name="diagnostico"
                inputProps={{ maxLength: 5000 }}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography
              variant={isNonMobile ? "h4" : "h5"}
              color={colors.grey[100]}
              marginBottom={isNonMobile ? "10px" : "5px"}
              fontWeight="bold"
            >
              9. Objetivo General{" "}
              <TooltipInfo
                title={
                  <p>
                    <Typography fontWeight="bold" variant="h7">
                      Objetivo General:
                    </Typography>
                    <br></br>Redactar de manera clara y precisa lo que se busca
                    lograr al realizar la actividad. Los objetivos deben ser
                    específicos, medibles, alcanzables, relevantes y con un
                    tiempo determinado. (SMART, por sus siglas en inglés)
                    (máximo 5.000 caracteres)<br></br>
                  </p>
                }
              />
            </Typography>
          </Box>
          <Box>
            <TextField
              fullWidth
              multiline
              rows={isNonMobile ? 4 : 3}
              variant="outlined"
              type="text"
              label="Objetivo General"
              onChange={onChangeObjetivoGeneral}
              value={actividadData.valuesObjetivoGeneral}
              name="diagnostico"
              inputProps={{ maxLength: 5000 }}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "5px"}
            fontWeight="bold"
          >
            10. Resultados Esperados{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Resultados esperados:
                  </Typography>
                  <br></br>Descripción de los logros o cambios que se anticipan
                  como consecuencia directa de la actividad. Estos resultados
                  deben ser coherentes con los objetivos planteados y pueden ser
                  cualitativos o cuantitativos. (Máximo 7.000 caracteres)
                  <br></br>
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(1, 1fr)",
          }}
        >
          <Box>
            <TextField
              fullWidth
              multiline
              rows={isNonMobile ? 4 : 3}
              variant="outlined"
              type="text"
              label="Resultados Esperados"
              onChange={onChangeResultadosEsperados}
              value={actividadData.valuesResultadoEsperado}
              name="resultadoEsperado"
              error={
                !!touchedResultadosEsperados && !!errorsResultadosEsperados
              }
              helperText={
                touchedResultadosEsperados && errorsResultadosEsperados
              }
              inputProps={{ maxLength: 7000 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DetalleDeLaAccion;
