import {
  Box,
  Button,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React, { useState } from "react";
import Popup from "../../../components/Popup";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDataContext} from "../../../context/DataContext";
import TableAmbitosActividadP from "../../../tables/TableAmbitosActividadP";
import TooltipInfo from "../../../components/TooltipInfo";

const CoherenciaInterna = ({actividadID}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopupAmbito, setOpenPopupAmbito] = useState(false);

  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");

  const { rowPairs, setRowPairs,  tipoCicloFormativo,
    cicloFormativo
   } =
    useDataContext();
  const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);

  const handlePopupOpenAmbito = () => {
    setOpenPopupAmbito(true);
  };

const handlePopupCloseAmbito = (selectedAmbito) => {
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? { ...actividad, selectedAmbito }
          : actividad
      )
    );
    setOpenPopupAmbito(false);
  };

  const handleAmbitosSelectionChange = (newSelectedRows) => {
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? { ...actividad, selectedAmbito: newSelectedRows }
          : actividad
      )
    );
  };

  const handlePopupSubmitAmbito = (selectedAmbito) => {
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? { ...actividad, selectedAmbito, isAmbitoSelected: selectedAmbito.length > 0 }
          : actividad
      )
    );
    setOpenPopupAmbito(false);
  };

  const handleCicloFormativoChange = (event, cicloId) => {
    const updatedSelectedCicloFormativo = event.target.checked
      ? [...actividadData.selectedCicloFormativo, cicloId]
      : actividadData.selectedCicloFormativo.filter((id) => id !== cicloId);

    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? { ...actividad, selectedCicloFormativo: updatedSelectedCicloFormativo }
          : actividad
      )
    );
  };

  const tipoCicloFormativoCicloBasico = tipoCicloFormativo.filter(
    (tipoCicloFormativo) => tipoCicloFormativo.id === 1
  );

  const tipoCicloFormativoCicloProfesional = tipoCicloFormativo.filter(
    (tipoCicloFormativo) => tipoCicloFormativo.id === 2
  );

  const tipoCicloFormativoCicloSintesis = tipoCicloFormativo.filter(
    (tipoCicloFormativo) => tipoCicloFormativo.id === 3
  );

  const cicloFormativoCicloBasico = cicloFormativo.filter(
    (cicloFormativo) => cicloFormativo.id_tipoCiclo === 1
  );

  const cicloFormativoCicloProfesional = cicloFormativo.filter(
    (cicloFormativo) => cicloFormativo.id_tipoCiclo === 2
  );

  const cicloFormativoCicloSintesis = cicloFormativo.filter(
    (cicloFormativo) => cicloFormativo.id_tipoCiclo === 3
  );

  return (
    <Box
      height={isNonMobile ? "55vh" : isNonMobileMedium ? "55vh" : "50vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "0px" : "10px"}
      display="grid"
      gridTemplateRows={
        isNonMobile ? undefined : isNonMobileMedium ? undefined : "70% 30%"
      }
      rowGap={isNonMobile ? 1 : 1}
      sx={{
        "& > div": {
          gridColumn: isNonMobile ? undefined : "span 4",
        },
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
            fontWeight="bold"
          >
            3. Ciclo Formativo{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Ciclo formativo:
                  </Typography>
                  <br></br>En consonancia con el Modelo Formativo de la
                  Universidad USEK, cada actividad de VcM debe estar vinculada a
                  uno o más los tres Ciclos Formativos que estructuran los
                  programas educativos. Estos ciclos poseen una caracterización
                  general y objetivos específicos diseñados para guiar la
                  formación de los estudiantes a lo largo de su trayectoria
                  universitaria.
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <Box>
            <FormControl component="fieldset" variant="standard">
              {tipoCicloFormativoCicloBasico.map((tipoCicloFormativo, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "3px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {tipoCicloFormativo.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                {cicloFormativoCicloBasico.map((cicloFormativo, id) => (
                  <FormControlLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    key={cicloFormativo.id}
                    control={
                      <Checkbox
                        color="default"
                        checked={actividadData.selectedCicloFormativo.includes(
                          cicloFormativo.id
                        )}
                        onChange={(event) =>
                          handleCicloFormativoChange(event, cicloFormativo.id)
                        }
                        value={cicloFormativo.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                        {cicloFormativo.descripcion}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {tipoCicloFormativoCicloProfesional.map(
                (tipoCicloFormativo, id) => (
                  <FormLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "3px" }}
                  >
                    <Typography
                      variant={isNonMobile ? "h5" : "h6"}
                      fontWeight="bold"
                      color={colors.grey[100]}
                    >
                      {tipoCicloFormativo.descripcion}
                    </Typography>
                  </FormLabel>
                )
              )}
              <FormGroup>
                {cicloFormativoCicloProfesional.map((cicloFormativo, id) => (
                  <FormControlLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    key={cicloFormativo.id}
                    control={
                      <Checkbox
                        color="default"
                        checked={actividadData.selectedCicloFormativo.includes(
                          cicloFormativo.id
                        )}
                        onChange={(event) =>
                          handleCicloFormativoChange(event, cicloFormativo.id)
                        }
                        value={cicloFormativo.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                        {cicloFormativo.descripcion}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {tipoCicloFormativoCicloSintesis.map((tipoCicloFormativo, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "3px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {tipoCicloFormativo.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                {cicloFormativoCicloSintesis.map((cicloFormativo, id) => (
                  <FormControlLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    key={cicloFormativo.id}
                    control={
                      <Checkbox
                        color="default"
                        checked={actividadData.selectedCicloFormativo.includes(
                          cicloFormativo.id
                        )}
                        onChange={(event) =>
                          handleCicloFormativoChange(event, cicloFormativo.id)
                        }
                        value={cicloFormativo.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                        {cicloFormativo.descripcion}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            fontWeight="bold"
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
          >
            4. Política VCM USEK
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            columnGap: 3,
            alignItems: "center",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom={isNonMobile ? "10px" : "5px"}
              >
                4.1. Ámbitos
              </Typography>
            </Box>
            {!actividadData.isAmbitoSelected ? (
              <Box display="flex" alignItems="center">
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "20%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenAmbito}
                    color="secondary"
                    variant="outlined"
                  >
                    Seleccionar Ámbitos
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "20%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenAmbito}
                    color="secondary"
                    variant="contained"
                  >
                    Seleccionar Ámbitos
                  </Button>
                )}
              </Box>
            ) : (
              <Box display="flex" alignItems="center" columnGap="20px">
                {theme.palette.mode === "dark" ? (
                  <Button
                    sx={{
                      width: "20%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenAmbito}
                    color="secondary"
                    variant="outlined"
                  >
                    Seleccionar Ámbitos
                  </Button>
                ) : (
                  <Button
                    sx={{
                      width: "20%",
                      height: isNonMobile ? undefined : "30px",
                    }}
                    onClick={handlePopupOpenAmbito}
                    color="secondary"
                    variant="contained"
                  >
                    Seleccionar Ámbitos
                  </Button>
                )}
                <CheckCircleIcon color="success" />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Popup
        open={openPopupAmbito}
        onClose={() => handlePopupCloseAmbito(actividadData.selectedAmbito)}
        title={"Ámbitos"}
        buttonName={"Seleccionar"}
        handleSubmit={() => handlePopupSubmitAmbito(actividadData.selectedAmbito)}
      >
        <TableAmbitosActividadP
          selectedRows={actividadData.selectedAmbito}
          onSelectionchange={handleAmbitosSelectionChange}
        />
      </Popup>
    </Box>
  );
};

export default CoherenciaInterna;
