import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {
  Box,
  Checkbox,
  IconButton,
  useTheme,
  Typography,
  CardActionArea,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { tokens } from "../theme";
import { useState, useEffect } from "react";
import Popup from "./Popup";
import { useDataContext } from "../context/DataContext";
import useMediaQuery from "@mui/material/useMediaQuery";

const CardsODS = ({
  image,
  alt,
  titleODS,
  descODS,
  imgODS,
  altODS,
  titInfoODS,
  fuenteODS,
  odsKey,
  valueCheckbox,
  checkboxChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopup, setOpenPopup] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { isODSchecked, setIsODSchecked, setIsODSSelected, setSelectedODS } =
    useDataContext();

  const isChecked = isODSchecked[odsKey] || false;

  useEffect(() => {
    const anyChecked = Object.values(isODSchecked).some((value) => value);
    setIsODSSelected(anyChecked);
  }, [isODSchecked, setIsODSSelected]);

  useEffect(() => {
    const selectedKeys = Object.keys(isODSchecked).filter(
      (key) => isODSchecked[key]
    );
    setSelectedODS(selectedKeys);
  }, [isODSchecked, setSelectedODS]);

  // useEffect(() => {
  //   console.log("Selected ods: ", selectedODS);
  // }, [selectedODS]);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleChange = () => {
    // Update the shared state for the specific odsKey
    setIsODSchecked((prev) => ({
      ...prev,
      [odsKey]: !isChecked,
    }));
  };

  const handleSubmitPopupODS = () => {
    // Update the shared state for the specific odsKey
    setIsODSchecked((prev) => ({
      ...prev,
      [odsKey]: !isChecked,
    }));

    setOpenPopup(false);
  };

  return (
    <Card
      square
      sx={{
        border: isChecked ? "5px solid" : "none",
        boxShadow: 5,
        borderColor: isChecked ? colors.greenAccent[500] : "transparent",
        position: "relative",
        width: isNonMobile ? 160 : 130,
        transition: "transform 0.20s ease-in-out",
        "&:hover": {
          transform: "scale3d(1.20, 1.20, 1.20)",
          zIndex: 1,
        },
      }}
    >
      <CardActionArea
        onClick={handleChange}
        sx={{
          "& .MuiCardActionArea-focusHighlight": { background: "transparent" },
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt={alt}
          sx={{
            objectFit: "fill",
          }}
        />
        <Box
          display="flex"
          position="absolute"
          bottom={isNonMobile ? "0px" : "0px"}
          left={isNonMobile ? "-3px" : "-7px"}
        >
          <Checkbox
            disableRipple
            color="default"
            style={{ color: "white" }}
            checked={isChecked}
            value={valueCheckbox}
            onChange={checkboxChange}
          />
        </Box>
      </CardActionArea>
      {isChecked ? (
        <IconButton
          onClick={handleOpenPopup}
          color="secondary"
          sx={{
            color: "white",
            display: "flex",
            position: "absolute",
            bottom: isNonMobile ? "0px" : "0px",
            left: isNonMobile ? "118px" : "90px",
            zIndex: 3,
          }}
        >
          <InfoIcon />
        </IconButton>
      ) : (
        <IconButton
          onClick={handleOpenPopup}
          color="secondary"
          sx={{
            color: "white",
            display: "flex",
            position: "absolute",
            bottom: isNonMobile ? "0px" : "0px",
            left: isNonMobile ? "124px" : "98px",
          }}
        >
          <InfoIcon />
        </IconButton>
      )}

      <Popup
        open={openPopup}
        onClose={handleClosePopup}
        handleSubmit={handleSubmitPopupODS}
        buttonName={!isChecked ? "Seleccionar" : "Deseleccionar"}
        maxWidth={isNonMobile ? "lg" : isNonMobileMedium ? "lg" : "md"}
        title={titleODS}
      >
        <Card sx={{ border: "none", boxShadow: "none" }}>
          <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
            <Box>
              <CardMedia
                component="img"
                height={
                  isNonMobile ? "100%" : isNonMobileMedium ? "100%" : "445vh"
                }
                image={imgODS}
                alt={altODS}
                sx={{ objectFit: "contain" }}
              />
            </Box>
            <Box>
              <CardContent
                sx={{
                  height: "100%",
                  display: "grid",
                  gridTemplateColumns: "100%",
                  gridTemplateRows: " 5% 85% 5%",
                }}
              >
                <Box>
                  <Typography
                    variant="h4"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    align="center"
                  >
                    {titInfoODS}
                  </Typography>
                </Box>
                <Box
                  overflow="auto"
                  height={
                    isNonMobile
                      ? undefined
                      : isNonMobileMedium
                      ? undefined
                      : "45vh"
                  }
                  marginTop={
                    isNonMobile
                      ? undefined
                      : isNonMobileMedium
                      ? undefined
                      : "15px"
                  }
                  paddingX={
                    isNonMobile
                      ? undefined
                      : isNonMobileMedium
                      ? undefined
                      : "10px"
                  }
                >
                  {descODS}
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    color={colors.grey[100]}
                    align="center"
                  >
                    {fuenteODS}
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Card>
      </Popup>
    </Card>
  );
};

export default CardsODS;
