import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React, { useEffect } from 'react';
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import { useDataContext} from "../../../context/DataContext";
import { useDataContextActividadP} from "../../../context/DataContextActividadP";
const AlcanceGrupoInteresRelevante = ({
  actividadID,
  touchedCiudad,
  errorsCiudad,
  touchedPais,
  errorsPais,
  touchedDireccion,
  errorsDireccion,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const {
    rowPairs, setRowPairs,
 
  } = useDataContext();

  const {
    pais,
    region,
    ciudad,
    setSelectedPais,
    setSelectedRegion,
    setSelectedCiudad,
    alcanceTerritorial,
    tipoGrupoInteresRelevante,
    grupoInteresRelevante,
  } = useDataContextActividadP();
  
  const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);
  useEffect(() => {
    if (actividadData && actividadData.selectedPais) {
      setSelectedPais(actividadData.selectedPais);
    }
  }, [actividadData, setSelectedPais]);

  useEffect(() => {
    if (actividadData && actividadData.selectedRegion) {
      setSelectedRegion(actividadData.selectedRegion);
    }
  }, [actividadData, setSelectedRegion]);
  
  useEffect(() => {
     if (actividadData && actividadData.selectedCiudad) {
      setSelectedCiudad(actividadData.selectedCiudad);
    }
  }, [actividadData, setSelectedCiudad]);

  const tipoGrupoInterno = tipoGrupoInteresRelevante.filter(
    (tipoGrupoInteresRelevante) => tipoGrupoInteresRelevante.id === 1
  );
  const tipoGrupoExterno = tipoGrupoInteresRelevante.filter(
    (tipoGrupoInteresRelevante) => tipoGrupoInteresRelevante.id === 2
  );

  const grupoInterno = grupoInteresRelevante.filter(
    (grupoInteresRelevante) => grupoInteresRelevante.id_tipoGrupo === 1
  );
  const grupoExterno = grupoInteresRelevante.filter(
    (grupoInteresRelevante) => grupoInteresRelevante.id_tipoGrupo === 2
  );

  const handlePaisChange = (event) => {
    setSelectedPais(event.target.value);
    setSelectedRegion(""); 
    setSelectedCiudad("");
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? {
              ...actividad,
              selectedPais: event.target.value,
              selectedRegion: "",
              selectedCiudad: "",
             
            }
          : actividad
      )
    );
  };

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedCiudad(""); 
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? {
              ...actividad,
              selectedRegion: event.target.value,
              selectedCiudad: "",
           
            }
          : actividad
      )
    );
  };

  const handleCiudadChange = (event) => {
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? {
              ...actividad,
              selectedCiudad: event.target.value,
            
            }
          : actividad
      )
    );
  };

  const handleAlcanceTerritorialChange = (event, alcanceTerritorialId) => {
    const updatedSelectedAlcanceTerritorial = event.target.checked
      ? [...actividadData.selectedAlcanceTerritorial, alcanceTerritorialId]
      : actividadData.selectedAlcanceTerritorial.filter(
          (id) => id !== alcanceTerritorialId
        );

    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? {
              ...actividad,
              selectedAlcanceTerritorial: updatedSelectedAlcanceTerritorial,
            }
          : actividad
      )
    );
  };

  const handleGrupoInteresRelevanteChange = (
    event,
    grupoInteresRelevanteId
  ) => {
    const updatedSelectedGrupoInteresRelevante = event.target.checked
      ? [
          ...actividadData.selectedGrupoInteresRelevante,
          grupoInteresRelevanteId,
        ]
      : actividadData.selectedGrupoInteresRelevante.filter(
          (id) => id !== grupoInteresRelevanteId
        );

    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? {
              ...actividad,
              selectedGrupoInteresRelevante: updatedSelectedGrupoInteresRelevante,
            }
          : actividad
      )
    );
  };

  const onChangeDireccion = (e) => {
    const newValue = e.target.value;
    setRowPairs((prevRowPairs) =>
      prevRowPairs.map((actividad) =>
        actividad.id === actividadID
          ? { ...actividad, valuesDireccion: newValue }
          : actividad
      )
    );
  };

  return (
    <Box
      height={isNonMobile ? "55vh" : isNonMobileMedium ? "55vh" : "50vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "0px" : "10px"}
      display="grid"
      rowGap={1}
      gridTemplateColumns="repeat(1, 1fr)"
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-disabled fieldset": {
            borderColor: "inherit",
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            fontWeight="bold"
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
          >
            5. Alcance
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                5.1. Alcance Terrirorial
              </Typography>
            </Box>
            <Box>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {alcanceTerritorial.map((alcanceTerritorial, id) => (
                    <Box
                      display="grid"
                      gridTemplateRows={
                        isNonMobile ? undefined : "repeat(4, 7px)"
                      }
                      alignItems="center"
                    >
                      <FormControlLabel
                        key={alcanceTerritorial.id}
                        control={
                          <Checkbox
                            color="default"
                            checked={actividadData.selectedAlcanceTerritorial.includes(
                              alcanceTerritorial.id
                            )}
                            onChange={(event) =>
                              handleAlcanceTerritorialChange(
                                event,
                                alcanceTerritorial.id
                              )
                            }
                            value={alcanceTerritorial.id}
                            style={{
                              color: colors.grey[100],
                              marginRight: "5px",
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant={isNonMobile ? "h6" : "h7"}
                            color={colors.grey[100]}
                          >
                            {alcanceTerritorial.descripcion}
                          </Typography>
                        }
                      />
                    </Box>
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                5.2. Geolocalización
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                columnGap: 3,
                rowGap: 2,
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: isNonMobile ? undefined : "repeat(4, 25px)",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant={isNonMobile ? "h5" : "h6"}
                  color={colors.grey[100]}
                >
                  País
                </Typography>
              </Box>
              <Box>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  select
                  SelectProps={{
                    sx: { fontSize: isNonMobile ? undefined : "12px" },
                  }}
                  onChange={handlePaisChange}
                  value={actividadData.selectedPais}
                  name="nombrePrograma"
                  error={!!touchedPais && !!errorsPais}
                  helperText={touchedPais && errorsPais}
                >
                  {pais.map((pais) => (
                    <MenuItem key={pais.id} value={pais.id}>
                      {pais.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <Typography
                  variant={isNonMobile ? "h5" : "h6"}
                  color={colors.grey[100]}
                >
                  Estado/Región
                </Typography>
              </Box>
              <Box>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  select
                  SelectProps={{
                    sx: { fontSize: isNonMobile ? undefined : "12px" },
                  }}
                  onChange={handleRegionChange}
                  value={actividadData.selectedRegion}
                  name="nombrePrograma"
                  disabled={!actividadData.selectedPais}
                  error={!!touchedCiudad && !!errorsCiudad}
                  helperText={touchedCiudad && errorsCiudad}
                >
                  {region.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <Typography
                  variant={isNonMobile ? "h5" : "h6"}
                  color={colors.grey[100]}
                >
                  Ciudad/Comuna
                </Typography>
              </Box>
              <Box>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  select
                  SelectProps={{
                    sx: { fontSize: isNonMobile ? undefined : "12px" },
                  }}
                  onChange={handleCiudadChange}
                  value={actividadData.selectedCiudad}
                  name="nombrePrograma"
                  disabled={!actividadData.selectedRegion}
                  error={!!touchedCiudad && !!errorsCiudad}
                  helperText={touchedCiudad && errorsCiudad}
                >
                  {ciudad.map((ciudad) => (
                    <MenuItem key={ciudad.id} value={ciudad.id}>
                      {ciudad.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <Typography
                  variant={isNonMobile ? "h5" : "h6"}
                  color={colors.grey[100]}
                >
                  Dirección
                </Typography>
              </Box>
              <Box>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="text"
                  InputProps={{
                    sx: { fontSize: isNonMobile ? undefined : "12px" },
                  }}
                  onChange={onChangeDireccion}
                  value={actividadData.valuesDireccion}
                  disabled={!actividadData.selectedCiudad}
                  name="nombrePrograma"
                  placeholder={
                    actividadData.selectedCiudad ? "Ej. Fernando Manterola 0789" : undefined
                  }
                  error={!!touchedDireccion && !!errorsDireccion}
                  helperText={touchedDireccion && errorsDireccion}
                  inputProps={{ maxLength: 250 }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
            fontWeight="bold"
          >
            6. Grupo de Interés Relevante
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Box>
            <FormControl component="fieldset" variant="standard">
              {tipoGrupoInterno.map((tipoGrupoInteresRelevante, id) => (
                <FormLabel>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginBottom="10px"
                  >
                    {tipoGrupoInteresRelevante.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                {grupoInterno.map((grupoInteresRelevante, id) => (
                  <Box
                    display="grid"
                    gridTemplateRows={
                      isNonMobile ? undefined : "repeat(4, 7px)"
                    }
                  >
                    <FormControlLabel
                      key={grupoInteresRelevante.id}
                      control={
                        <Checkbox
                          color="default"
                          checked={actividadData.selectedGrupoInteresRelevante.includes(
                            grupoInteresRelevante.id
                          )}
                          onChange={(event) =>
                            handleGrupoInteresRelevanteChange(
                              event,
                              grupoInteresRelevante.id
                            )
                          }
                          value={grupoInteresRelevante.id}
                          style={{
                            color: colors.grey[100],
                            marginRight: "5px",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={isNonMobile ? "h6" : "h7"}
                          color={colors.grey[100]}
                        >
                          {grupoInteresRelevante.descripcion}
                        </Typography>
                      }
                    />
                  </Box>
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {tipoGrupoExterno.map((tipoGrupoInteresRelevante, id) => (
                <FormLabel>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginBottom={isNonMobile ? "10px" : "0px"}
                  >
                    {tipoGrupoInteresRelevante.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridTemplateRows: isNonMobile
                      ? undefined
                      : "repeat(3, 45px)",
                  }}
                >
                  {grupoExterno.map((grupoInteresRelevante, id) => (
                    <Box display="flex" flexDirection="column">
                      <FormControlLabel
                        key={grupoInteresRelevante.id}
                        control={
                          <Checkbox
                            checked={actividadData.selectedGrupoInteresRelevante.includes(
                              grupoInteresRelevante.id
                            )}
                            onChange={(event) =>
                              handleGrupoInteresRelevanteChange(
                                event,
                                grupoInteresRelevante.id
                              )
                            }
                            value={grupoInteresRelevante.id}
                            color="default"
                            style={{
                              color: colors.grey[100],
                              marginRight: "5px",
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant={isNonMobile ? "h6" : "h7"}
                            color={colors.grey[100]}
                          >
                            {grupoInteresRelevante.descripcion}
                          </Typography>
                        }
                      />
                    </Box>
                  ))}
                </Box>
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AlcanceGrupoInteresRelevante;
