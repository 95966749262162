import * as React from "react";
import Card from "@mui/material/Card";
import {
  Box,
  Checkbox,
  useTheme,
  Typography,
  CardActionArea,
  CardContent,
} from "@mui/material";
import { tokens } from "../theme";
import { useDataContext} from "../context/DataContext";
import { useEffect } from "react";

const CardsEvalActividadP = ({
  actividadID,
  evalTitle,
  evalIcon,
  bottomIcon,
  bottomTitle,
  bottomCheckbox,
  titleLeft,
  valueCheckbox,
  evalKey,
  variant,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {   setIsInstrumentoEvaluacionChecked,
    setIsInstrumentoEvaluacionSelected,
    setSelectedInstrumentoEvaluacion,rowPairs, setRowPairs } = useDataContext();
  const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);

  const isChecked = actividadData.isInstrumentoEvaluacionChecked[evalKey] || false;
  useEffect(() => {
    const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);
    if (actividadData) {
      const anyChecked = Object.values(actividadData.isInstrumentoEvaluacionChecked).some(
        (value) => value
      );
      setIsInstrumentoEvaluacionSelected(anyChecked);
    }
  }, [rowPairs, actividadID, setIsInstrumentoEvaluacionSelected]);
  
  useEffect(() => {
    const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);
    if (actividadData) {
      const selectedKeys = Object.keys(actividadData.isInstrumentoEvaluacionChecked).filter(
        (key) => actividadData.isInstrumentoEvaluacionChecked[key]
      );
      setSelectedInstrumentoEvaluacion(selectedKeys);
    }
  }, [rowPairs, actividadID, setSelectedInstrumentoEvaluacion]);


  const handleChange = () => {
    const updatedActividadData = {
      ...actividadData,
      isInstrumentoEvaluacionChecked: {
        ...actividadData.isInstrumentoEvaluacionChecked,
        [evalKey]: !isChecked,
      },
    };
  
    const updatedIsInstrumentoEvaluacionSelected = Object.values(
      updatedActividadData.isInstrumentoEvaluacionChecked
    ).some((value) => value);
  
    const updatedRowPairs = rowPairs.map((actividad) =>
      actividad.id === actividadID
        ? {
            ...updatedActividadData,
            isInstrumentoEvaluacionSelected: updatedIsInstrumentoEvaluacionSelected,
          }
        : actividad
    );
  
    setRowPairs(updatedRowPairs);
  };

  return (
    <Card
      sx={{
        width: "180px",
        height: "180px",
        border: "2px solid",
        borderColor: isChecked ? colors.orangeAccent[400] : colors.grey[500],
        transition: "transform 0.20s ease-in-out",
        "&:hover": {
          transform: "scale3d(1.20, 1.20, 1.20)",
          zIndex: 1,
          borderColor: colors.orangeAccent[400],
        },
      }}
    >
      <CardActionArea
        onClick={handleChange}
        sx={{ "& .MuiCardActionArea-focusHighlight": { background: "transparent" } }}
      >
        <CardContent>
          <Box display="flex" position="relative" left={titleLeft} bottom={bottomTitle}>
            <Typography variant={variant} fontWeight="bold" color={colors.grey[100]} align="center">
              {evalTitle}
            </Typography>
          </Box>
          <Box display="flex" position="relative" justifyContent="center" bottom={bottomIcon}>
            {evalIcon}
          </Box>
          <Box display="flex" position="relative" bottom={bottomCheckbox} right="13px">
            <Checkbox
              disableRipple
              color="default"
              style={{ color: colors.grey[100] }}
              checked={isChecked}
              value={valueCheckbox}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardsEvalActividadP;
