import { Box, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardsODS from "../../../components/CardsODS";
import { ODSDescripcion } from "../../../data/odsDescripcion";
import { useDataContext } from "../../../context/DataContext";
import TooltipInfo from "../../../components/TooltipInfo";

const ODS = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { ods } = useDataContext();

  const idODS1 = ods.filter((ods) => ods.id === 1);
  const idODS2 = ods.filter((ods) => ods.id === 2);
  const idODS3 = ods.filter((ods) => ods.id === 3);
  const idODS4 = ods.filter((ods) => ods.id === 4);
  const idODS5 = ods.filter((ods) => ods.id === 5);
  const idODS6 = ods.filter((ods) => ods.id === 6);
  const idODS7 = ods.filter((ods) => ods.id === 7);
  const idODS8 = ods.filter((ods) => ods.id === 8);
  const idODS9 = ods.filter((ods) => ods.id === 9);
  const idODS10 = ods.filter((ods) => ods.id === 10);
  const idODS11 = ods.filter((ods) => ods.id === 11);
  const idODS12 = ods.filter((ods) => ods.id === 12);
  const idODS13 = ods.filter((ods) => ods.id === 13);
  const idODS14 = ods.filter((ods) => ods.id === 14);
  const idODS15 = ods.filter((ods) => ods.id === 15);
  const idODS16 = ods.filter((ods) => ods.id === 16);
  const idODS17 = ods.filter((ods) => ods.id === 17);

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      rowGap={1}
      gridTemplateColumns="repeat(1, 1fr)"
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Typography
          variant={isNonMobile ? "h4" : "h5"}
          color={colors.grey[100]}
          marginBottom={
            isNonMobile ? "20px" : isNonMobileMedium ? "25px" : "5px"
          }
          fontWeight="bold"
        >
          18. Objetivos de Desarrollo Sostenible (Agenda 2030){" "}
          <TooltipInfo
            title={
              <p>
                <Typography fontWeight="bold" variant="h7">
                  ODS:
                </Typography>
                <br></br>Se requiere determinar la contribución de su iniciativa de
                VcM a los objetivos de desarrollo sostenible (ODS) de la Agenda
                2030.
              </p>
            }
          />
        </Typography>
        <Box
          sx={{
            display: "grid",
            rowGap: 1,
            gridTemplateColumns: "repeat(1, 1fr)",
          }}
        >
          <Box
            marginTop="10px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" columnGap={1}>
              {idODS1.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-01.png"
                  alt="ods01"
                  imgODS="../assets/ods/S-WEB-Goal-01.png"
                  altODS="ods01"
                  titleODS="ODS 01: Fin de la Pobreza"
                  titInfoODS="Fin de la pobreza"
                  odsKey={ods.id}
                  valueCheckbox={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS01,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS2.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-02.png"
                  alt="ods02"
                  imgODS="../assets/ods/S-WEB-Goal-02.png"
                  altODS="ods02"
                  titleODS="ODS 02: Hambre Cero"
                  titInfoODS="Hambre Cero"
                  odsKey={ods.id}
                  valueCheckbox={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS02,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}

              {idODS3.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-03.png"
                  alt="ods03"
                  imgODS="../assets/ods/S-WEB-Goal-03.png"
                  altODS="ods03"
                  titleODS="ODS 03: Salud y Bienestar"
                  titInfoODS="Salud y Bienestar"
                  odsKey={ods.id}
                  valueCheckbox={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS03,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS4.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-04.png"
                  alt="ods04"
                  imgODS="../assets/ods/S-WEB-Goal-04.png"
                  altODS="ods04"
                  titleODS="ODS 04: Educación de Calidad"
                  odsKey={ods.id}
                  titInfoODS="Educación de Calidad"
                  valueCheckbox={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS04,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS5.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-05.png"
                  alt="ods05"
                  imgODS="../assets/ods/S-WEB-Goal-05.png"
                  altODS="ods05"
                  titleODS="ODS 05: Igualdad de Género"
                  titInfoODS="Igualdad de Género"
                  odsKey={ods.id}
                  valueCheckbox={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS05,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS6.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-06.png"
                  alt="ods06"
                  imgODS="../assets/ods/S-WEB-Goal-06.png"
                  altODS="ods06"
                  titleODS="ODS 06: Agua potable y Saneamiento"
                  titInfoODS="Agua potable y Saneamiento"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS06,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
            </Stack>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack direction="row" columnGap={1}>
              {idODS7.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-07.png"
                  alt="ods07"
                  imgODS="../assets/ods/S-WEB-Goal-07.png"
                  altODS="ods07"
                  titleODS="ODS 07: Energía Asequible y No Contaminante"
                  titInfoODS="Energía Asequible y No Contaminante"
                  odsKey={ods.id}
                  valueCheckbox={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS07,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS8.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-08.png"
                  alt="ods08"
                  imgODS="../assets/ods/S-WEB-Goal-08.png"
                  altODS="ods08"
                  titleODS="ODS 08: Trabajo Decente y Crecimiento Económico"
                  titInfoODS="Trabajo Decente y Crecimiento Económico"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS08,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS9.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-09.png"
                  alt="ods09"
                  imgODS="../assets/ods/S-WEB-Goal-09.png"
                  altODS="ods09"
                  titleODS="ODS 09: Industria, Innovación e Infraestructura"
                  titInfoODS="Industria, Innovación e Infraestructura"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS09,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS10.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-10.png"
                  alt="ods10"
                  imgODS="../assets/ods/S-WEB-Goal-10.png"
                  altODS="ods10"
                  titleODS="ODS 10: Reducción de las Desigualdades"
                  titInfoODS="Reducción de las Desigualdades"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS10,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS11.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-11.png"
                  alt="ods11"
                  imgODS="../assets/ods/S-WEB-Goal-11.png"
                  altODS="ods11"
                  titleODS="ODS 11: Ciudades y Comunidades Sostenibles"
                  titInfoODS="Ciudades y Comunidades Sostenibles"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS11,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS12.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-12.png"
                  alt="ods12"
                  imgODS="../assets/ods/S-WEB-Goal-12.png"
                  altODS="ods12"
                  titleODS="ODS 12: Producción y Consumo Responsable"
                  titInfoODS="Producción y Consumo Responsable"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS12,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
            </Stack>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack direction="row" columnGap={1}>
              {idODS13.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-13.png"
                  alt="ods13"
                  imgODS="../assets/ods/S-WEB-Goal-13.png"
                  altODS="ods13"
                  titleODS="ODS 13: Acción por el Clima"
                  titInfoODS="Acción por el Clima"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS13,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS14.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-14.png"
                  alt="ods14"
                  imgODS="../assets/ods/S-WEB-Goal-14.png"
                  altODS="ods14"
                  titleODS="ODS 14: Vida Submarina"
                  titInfoODS="Vida Submarina"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS14,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS15.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-15.png"
                  alt="ods15"
                  imgODS="../assets/ods/S-WEB-Goal-15.png"
                  altODS="ods15"
                  titleODS="ODS 15: Vida de Ecosistemas Terrestres"
                  titInfoODS="Vida de Ecosistemas Terrestres"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS15,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS16.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-16.png"
                  alt="ods16"
                  imgODS="../assets/ods/S-WEB-Goal-16.png"
                  altODS="ods16"
                  titleODS="ODS 16: Paz, Justicia e Instituciones Sólidas"
                  titInfoODS="Paz, Justicia e Instituciones Sólidas"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS16,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
              {idODS17.map((ods, id) => (
                <CardsODS
                  image="../assets/ods/S-WEB-Goal-17.png"
                  alt="ods17"
                  imgODS="../assets/ods/S-WEB-Goal-17.png"
                  altODS="ods17"
                  titleODS="ODS 17: Alianza para Lograr los Objetivos"
                  titInfoODS="Alianza para Lograr los Objetivos"
                  valueCheckbox={ods.id}
                  odsKey={ods.id}
                  descODS={
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      align="center"
                    >
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: ODSDescripcion.ODS17,
                        }}
                      />
                    </Typography>
                  }
                  fuenteODS={<p>Fuente: Naciones Unidas</p>}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ODS;
