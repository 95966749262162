import { Box, Button, Tab, Stack } from "@mui/material";
import { Formik } from "formik";
import Header from "../components/Header";
import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import InformacionGeneral from "./tabs/actividadP/InformacionGeneral";
import CoherenciaInterna from "./tabs/actividadP/CoherenciaInterna";
import AlcanceGrupoInteresRelevante from "./tabs/actividadP/AlcanceGrupoInteresRelevante";
import GestionDeLaAccion from "./tabs/actividadP/GestionDeLaAccion";
import DetalleDeLaAccion from "./tabs/actividadP/DetalleDeLaAccion";
import ParticipacionEsperada from "./tabs/actividadP/ParticipacionEsperada";
import Contribucion from "./tabs/actividadP/Contribucion";
import InstrumentoDeEvaluacion from "./tabs/actividadP/InstrumentoDeEvaluacion";
import Presupuesto from "./tabs/actividadP/Presupuesto";
import { userSchemaActividadP } from "../schemas";
import { useDataContext} from "../context/DataContext";
import useMediaQuery from "@mui/material/useMediaQuery";

const FormularioActividadP = ({ actividadID,onClickFinalizar }) => {
  const [value, setValue] = useState(1);
  const { rowPairs, setRowPairs } =
    useDataContext();
  const actividadData = rowPairs.find((actividad) => actividad.id === actividadID);
  console.log(actividadData);
  const isNonMobile = useMediaQuery("(min-width:1920px)");

  
  const handleNextTab = () => {
    setValue((prevValue) => prevValue + 1);
  };
  const handlePrevTab = () => {
    setValue((prevValue) => prevValue - 1);
  };
  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const tabNames = [
    "Información general",
    "Coherencia interna",
    "Alcance / grupo de interés relevante",
    "Gestión de la acción",
    "Detalle de la acción",
    "Participación esperada",
    "Instrumento de evaluación"
  ];

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      margin={isNonMobile ? "20px" : "0px 0px 0px 00px"}
    >
     <Header
  title={actividadData.valuesNombreActividadP !=="" ? actividadData.valuesNombreActividadP : "Nueva Actividad"}
  subtitle={actividadData.valuesResponsableActividadP !==""? `Responsable: ${actividadData.valuesResponsableActividadP}` : "Crea una nueva actividad"}
/>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={actividadData}
        validationSchema={userSchemaActividadP}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Box marginTop={isNonMobile ? "5px" : "0px"}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", width: isNonMobile ? undefined : "90vw"}}>
                <TabList
                  aria-label="Tabs example"
                  // onChange={handleChangeTab}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={false}
                  sx={{ height: "60px" }}
                >
                  {tabNames.map((tabName, index) => (
                    <Tab
                      disableRipple
                      key={index}
                      label={tabName}
                      icon={
                        index < value - 1 ? ( // Revisa si la pestaña esta antes de la pestaña actual
                          <CheckCircleIcon fontSize="24px" color="success" />
                        ) : index === value - 1 ? ( // Revisa si la pestaña es la pestaña actual
                          <EditIcon fontSize="24" />
                        ) : (
                          <></> // Icono vacio para las pestañas siguientes a la pestaña actual
                        )
                      }
                      iconPosition="end"
                      value={index + 1}
                    />
                  ))}
                </TabList>
              </Box>
              <Box>
                <TabPanel value={1}>
                  <form onSubmit={handleSubmit}>
                    <InformacionGeneral actividadID={actividadID} />
                    <Box display="flex" justifyContent="end" marginTop={isNonMobile ? "10px" : "14px"}>
                      <Button
                        onClick={handleNextTab}
                        color="secondary"
                        variant="contained"
                        endIcon={<EastOutlinedIcon />}
                        disabled={
                          //actividadData.valuesFechaInicio === actividadData.valuesFechaTermino ||
                          //actividadData.valuesFechaTermino < actividadData.valuesFechaInicio ||
                          actividadData.valuesNombreActividadP.length === 0 ||
                          actividadData.valuesResponsableActividadP.length === 0
                        }
                      >
                        Siguiente
                      </Button>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={2}>
                  <form onSubmit={handleSubmit}>
                    <CoherenciaInterna actividadID={actividadID} />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                            !actividadData.isAmbitoSelected ||
                            actividadData.selectedCicloFormativo.length === 0
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={3}>
                  <form onSubmit={handleSubmit}>
                    <AlcanceGrupoInteresRelevante actividadID={actividadID} />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                            actividadData.selectedAlcanceTerritorial.length === 0 ||
                            actividadData.selectedPais.length === 0 ||
                            actividadData.selectedRegion.length === 0 ||
                            actividadData.selectedCiudad.length === 0 ||
                            actividadData.valuesDireccion.length === 0 ||
                            actividadData.selectedGrupoInteresRelevante.length === 0
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={4}>
                  <form onSubmit={handleSubmit}>
                    <GestionDeLaAccion actividadID={actividadID} />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={actividadData.selectedEtapaParticipa.length === 0}
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={5}>
                  <form onSubmit={handleSubmit}>
                    <DetalleDeLaAccion actividadID={actividadID} />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                            actividadData.valuesBreveDescripcion.length === 0 ||
                            actividadData.valuesObjetivoGeneral.lenght === 0 ||
                            actividadData.valuesResultadoEsperado.length === 0
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={6}>
                  <form onSubmit={handleSubmit}>
                    <ParticipacionEsperada actividadID={actividadID} />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                             actividadData.selectedParticipantesEsperados.length === 0
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={7}>
                  <form onSubmit={handleSubmit}>
                    <InstrumentoDeEvaluacion actividadID={actividadID} />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                               onClick={onClickFinalizar}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={!actividadData.isInstrumentoEvaluacionSelected}
                        >
                            Finalizar
                        </Button>
                      </Stack>
                    </Box>
                  </form>
                </TabPanel>
             
              </Box>
            </TabContext>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default FormularioActividadP;
