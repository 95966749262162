import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Breadcrump from "../../components/Breadcrump";
import PopupFS from "../../components/PopupFS";
import { useState } from "react";
import FormularioIniciativa from "../../forms/FormularioIniciativa";
import TableIniciativas from "../../tables/TableIniciativas";
import TableIniciativasPlantilla from "../../tables/TableIniciativasPlantilla";
import { DataProviderActividadP } from "../../context/DataContextActividadP";
import { ToastContainer } from "react-toastify";
import { useDataContext } from "../../context/DataContext";
import "react-toastify/dist/ReactToastify.css";
import BackdropLoading from "../../components/Backdrop";
import PopupAlert from "../../components/PopupAlert";
import Popup from "../../components/Popup";
import useMediaQuery from "@mui/material/useMediaQuery";
import editarFetch from "../../api/iniciativa/editarFetch";
import plantillaFetch from "../../api/iniciativa/plantillaFetch";
import continuarFetch from "../../api/iniciativa/continuarFetch";
import update from "../../api/iniciativa/update";
import updateIncompleto from "../../api/iniciativa/updateIncompleto";
import submitIncompleto from "../../api/iniciativa/submitIncompleto";
import submit from "../../api/iniciativa/submit";
import enviar from "../../api/iniciativa/enviar";
import updateCompletado from "../../api/iniciativa/updateCompletado";
import retroalimentacionFetch from "../../api/iniciativa/retroalimentacionFetch";
import Retroalimentacion from "../../components/Retroalimentacion";
import TableRetroalimentacionIniciativa from "../../tables/TableRetroalimentacionIniciativa";
import { useDataContextSession } from "../../context/DataContextSession";

const Emergentes = () => {
  const [openPopupCrear, setOpenPopupCrear] = useState(false);
  const [openPopupEditar, setOpenPopupEditar] = useState(false);
  const [openPopupContinuar, setOpenPopupContinuar] = useState(false);
  const [openPopupAlert, setOpenPopupAlert] = useState(false);
  const [openPopupPlantilla, setOpenPopupPlantilla] = useState(false);
  const [openPopupVer, setOpenPopupVer] = useState(false);
  const [openPopupEnviar, setOpenPopupEnviar] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openPopupRetroalimentacion, setOpenPopupRetroalimentacion] =
    useState(false);
  const [openPopupObservacion, setOpenPopupObservacion] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:1920px)");

  const theme = useTheme();
  const { mail, idUsuario, username } = useDataContextSession();
  const {
    valuesNombrePrograma,
    valuesResponsablePrograma,
    valuesDiagnostico,
    valuesDireccion,
    valuesRecursosMateriales,
    valuesTransporteAlimentacion,
    valuesOtrosRecursos,
    valuesTotal,
    valuesResultadoEsperado,
    selectedFuenteFinanciamiento,
    isLineaSubSelected,
    isAsignaturaSelected,
    selectedFocoPrioritario,
    selectedCicloFormativo,
    selectedLineaDeAccionRows,
    selectedPEI,
    selectedPais,
    selectedRegion,
    selectedCiudad,
    selectedAlcanceTerritorial,
    selectedGrupoInteresRelevante,
    selectedEtapaParticipa,
    selectedParticipantesEsperados,
    selectedTipoParticipantes,
    selectedContribucionInterna,
    selectedContribucionExterna,
    selectedFacultadRows,
    selectedCarreraRows,
    selectedAmbitoRows,
    selectedLineaSubRows,
    selectedAsignaturaRows,
    selectedActorParticipa,
    selectedODS,
    selectedInstrumentoEvaluacion,
    id,
    setID,
    getIniciativa,
    setSelectedFacultadRows,
    setValuesNombrePrograma,
    setValuesResponsablePrograma,
    setValuesDireccion,
    setValuesDiagnostico,
    setValuesRecursosMateriales,
    setValuesTransporteAlimentacion,
    setValuesOtrosRecursos,
    setValuesTotal,
    setValuesResultadoEsperado,
    setSelectedCarreraRows,
    setIsFacultadSelected,
    setIsCarreraSelected,
    setSelectedFuenteFinanciamiento,
    setSelectedCicloFormativo,
    setSelectedFocoPrioritario,
    setSelectedAmbitoRows,
    setIsAmbitoSelected,
    setSelectedLineaSubRows,
    setSelectedLineaDeAccionRows,
    setIsLineaDeAccionSelected,
    setIsLineaSubSelected,
    setSelectedAsignaturaRows,
    setIsAsignaturaSelected,
    setHasNoRows,
    setSelectedPEI,
    setSelectedAlcanceTerritorial,
    setSelectedPais,
    setSelectedRegion,
    setSelectedCiudad,
    setSelectedGrupoInteresRelevante,
    setSelectedActorParticipa,
    setSelectedEtapaParticipa,
    setSelectedTipoParticipantes,
    setSelectedParticipantesEsperados,
    setSelectedContribucionInterna,
    setSelectedContribucionExterna,
    setIsODSchecked,
    setIsInstrumentoEvaluacionChecked,
    setIsRowObjetivoAdded,
    rowProgramaID,
    setRowProgramaID,
    existingFuenteFinanciamiento,
    setExistingFuenteFinanciamiento,
    existingFacultad,
    setExistingFacultad,
    existingCarrera,
    setExistingCarrera,
    existingAmbito,
    setExistingAmbito,
    existingLineaAccion,
    setExistingLineaAccion,
    existingLineaSub,
    setExistingLineaSub,
    existingAsignatura,
    setExistingAsignatura,
    existingCicloFormativo,
    setExistingCicloFormativo,
    existingPEI,
    setExistingPEI,
    existingAlcanceTerritorial,
    setExistingAlcanceTerritorial,
    existingGrupoInteresRelevante,
    setExistingGrupoInteresRelevante,
    existingEtapaParticipa,
    setExistingEtapaParticipa,
    existingTipoParticipantes,
    setExistingTipoParticipantes,
    existingContribucionInterna,
    setExistingContribucionInterna,
    existingContribucionExterna,
    setExistingContribucionExterna,
    existingODS,
    setExistingODS,
    existingInstrumentoEvaluacion,
    setExistingInstrumentoEvaluacion,
    valuesFechaInicio,
    setValuesFechaInicio,
    valuesFechaTermino,
    setValuesFechaTermino,
    valuesObjetivoGeneralActividad,
    valuesBreveDescripcionActividad,
    setValuesObjetivoGeneralActividad,
    setValuesBreveDescripcionActividad,
    setSelectedODS,
    setSelectedInstrumentoEvaluacion,
    setValuesRetroalimentacion,
    valuesRetroalimentacion,
    valuesIdUsuario,
    setValuesIdUsuario,
    valuesMail,
    setValuesMail,
    valuesUsername,
    setValuesUsername,
    usernameTemp,
    setUsernameTemp,
    nombreProgramaTemp,
    setNombreProgramaTemp,
    idProgramaTemp,
    setIdProgramaTemp,
    responsableProgramaTemp,
    setResponsableProgramaTemp,
    mailTemp,
    setMailTemp,
  } = useDataContext();

  const generateUniqueId = () => {
    const staticString = "INIC-";
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hour = String(currentDate.getHours()).padStart(2, "0");
    const minute = String(currentDate.getMinutes()).padStart(2, "0");
    const second = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    const datetimeString = `${year}${month}${day}${hour}${minute}${second}${milliseconds}`;
    const uniqueId = staticString + datetimeString;

    return uniqueId;
  };

  const uniqueKey = generateUniqueId();

  const clearValues = () => {
    setValuesNombrePrograma("");
    setValuesResponsablePrograma("");
    setSelectedFacultadRows([]);
    setSelectedCarreraRows([]);
    setSelectedFuenteFinanciamiento([]);
    setValuesFechaInicio(null);
    setValuesFechaTermino(null);
    setSelectedCicloFormativo([]);
    setSelectedFocoPrioritario("1");
    setSelectedAmbitoRows([]);
    setSelectedLineaDeAccionRows([]);
    setSelectedLineaSubRows([]);
    setSelectedAsignaturaRows([]);
    setSelectedPEI([]);
    setSelectedAlcanceTerritorial([]);
    setSelectedPais("44");
    setSelectedRegion("2824");
    setSelectedCiudad("148523");
    setValuesDireccion("");
    setSelectedGrupoInteresRelevante([]);
    setSelectedActorParticipa([1]);
    setSelectedEtapaParticipa([]);
    setValuesDiagnostico("");
    setValuesObjetivoGeneralActividad("");
    setValuesBreveDescripcionActividad("");
    setValuesResultadoEsperado("");
    setSelectedTipoParticipantes([]);
    setSelectedParticipantesEsperados("1");
    setSelectedContribucionInterna([]);
    setSelectedContribucionExterna([]);
    setIsODSchecked(false);
    setSelectedODS([]);
    setIsInstrumentoEvaluacionChecked(false);
    setSelectedInstrumentoEvaluacion([]);
    setValuesRecursosMateriales("");
    setValuesTransporteAlimentacion("");
    setValuesOtrosRecursos("");
    setValuesTotal("");
    setIsFacultadSelected(false);
    setIsCarreraSelected(false);
    setIsAmbitoSelected(false);
    setIsLineaDeAccionSelected(false);
    setIsLineaSubSelected(false);
    setIsAsignaturaSelected(false);
    setIsRowObjetivoAdded(false);
    setHasNoRows(true);
  };

  const clearValuesTemp = () => {
    setUsernameTemp([]);
    setNombreProgramaTemp("");
    setIdProgramaTemp("");
    setResponsableProgramaTemp("");
    setMailTemp([]);
  };

  const handlePopupAlertOpen = () => {
    setOpenPopupAlert(true);
  };

  const handlePopupCrearClose = () => {
    setOpenPopupCrear(false);
    setOpenPopupAlert(false);
    clearValues();
  };

  const handlePopupPlantillaOpen = () => {
    setOpenPopupPlantilla(true);
  };

  const handlePopupPlantillaClose = () => {
    setOpenPopupPlantilla(false);
  };

  const handlePopupCrearOpen = () => {
    setOpenPopupCrear(true);
    setID(uniqueKey);
    setValuesIdUsuario(idUsuario);
    setValuesMail(mail);
    setValuesUsername(username);
  };

  const handlePopupEnviarOpen = async (rowID) => {
    setOpenBackdrop(true);
    await editarFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedInstrumentoEvaluacion,
      setSelectedODS,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupEnviar(true);
  };

  const handlePopupEnviarClose = () => {
    setOpenPopupEnviar(false);
    clearValues();
  };

  const handlePopupVerClose = () => {
    setOpenPopupVer(false);
    clearValues();
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  const handlePopupEditarClose = () => {
    setOpenPopupEditar(false);
    clearValues();
  };

  const handleEditarFetch = async (rowID) => {
    setOpenBackdrop(true);
    await editarFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedInstrumentoEvaluacion,
      setSelectedODS,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupEditar(true);
  };

  const handlePlantillaFetch = async (rowID) => {
    setOpenPopupPlantilla(false);
    setOpenBackdrop(true);
    await plantillaFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedInstrumentoEvaluacion,
      setSelectedODS,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername
    );
    setOpenBackdrop(false);
    handlePopupCrearOpen();
  };

  const handleContinuarFetch = async (rowID) => {
    setOpenBackdrop(true);
    await continuarFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedInstrumentoEvaluacion,
      setSelectedODS,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername
    );
    setOpenBackdrop(false);
    setOpenPopupContinuar(true);
  };

  const handleUpdate = async () => {
    setOpenPopupEditar(false);
    setOpenBackdrop(true);
    await update(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getIniciativa,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername
    );
  };

  const handleUpdateCompletado = async () => {
    setOpenPopupContinuar(false);
    setOpenBackdrop(true);
    await updateCompletado(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getIniciativa,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername
    );
  };

  const handleUpdateIncompleto = async () => {
    setOpenPopupContinuar(false);
    setOpenBackdrop(true);
    await updateIncompleto(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getIniciativa,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      valuesIdUsuario,
      valuesMail,
      valuesUsername
    );
  };

  const handleSubmit = async () => {
    setOpenPopupCrear(false);
    setOpenBackdrop(true);
    await submit(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      isLineaSubSelected,
      isAsignaturaSelected,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      id,
      getIniciativa,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername
    );
  };

  const handleSubmitIncompleto = async () => {
    setOpenPopupAlert(false);
    setOpenPopupCrear(false);
    setOpenBackdrop(true);
    await submitIncompleto(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      isLineaSubSelected,
      isAsignaturaSelected,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      id,
      getIniciativa,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername
    );
  };

  const handleEnviar = async () => {
    setOpenPopupEnviar(false);
    setOpenBackdrop(true);
    await enviar(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getIniciativa,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handleVer = async (rowID) => {
    setOpenBackdrop(true);
    await editarFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedInstrumentoEvaluacion,
      setSelectedODS,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupVer(true);
  };

  const handlePopupObservacionClose = () => {
    setValuesRetroalimentacion("");
    setOpenPopupObservacion(false);
  };

  const handleRetroalimentacionFetch = async (rowID) => {
    setOpenBackdrop(true);
    await retroalimentacionFetch(rowID, setValuesRetroalimentacion);
    setOpenBackdrop(false);
    setOpenPopupObservacion(true);
  };

  const handlePopupRetroalimentacionOpen = (rowID) => {
    setRowProgramaID(rowID);
    setOpenPopupRetroalimentacion(true);
  };

  const handlePopupRetroalimentacionClose = () => {
    setOpenPopupRetroalimentacion(false);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      margin={isNonMobile ? "0px 20px 20px 20px" : "0px 20px 20px 20px"}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Iniciativas Emergentes"
          subtitle="Lista de iniciativas emergentes"
        />
        <Breadcrump page="Iniciativas Emergentes" />
      </Box>
      <Box>
        <TableIniciativas
          handlePopupCrearOpen={handlePopupCrearOpen}
          onClickEditar={handleEditarFetch}
          onClickContinuar={handleContinuarFetch}
          onClickDropdown={handlePopupPlantillaOpen}
          onClickEnviar={handlePopupEnviarOpen}
          onClickVer={handleVer}
          onClickRetroalimentacion={handlePopupRetroalimentacionOpen}
        />
      </Box>
      <PopupFS open={openPopupCrear} onClose={handlePopupAlertOpen}>
        <DataProviderActividadP>
          <FormularioIniciativa onClickFinalizar={handleSubmit} />
        </DataProviderActividadP>
      </PopupFS>
      <PopupFS open={openPopupEditar} onClose={handlePopupEditarClose}>
        <DataProviderActividadP>
          <FormularioIniciativa onClickFinalizar={handleUpdate} />
        </DataProviderActividadP>
      </PopupFS>
      <PopupFS open={openPopupContinuar} onClose={handleUpdateIncompleto}>
        <DataProviderActividadP>
          <FormularioIniciativa onClickFinalizar={handleUpdateCompletado} />
        </DataProviderActividadP>
      </PopupFS>
      <PopupFS open={openPopupVer} onClose={handlePopupVerClose}>
        <DataProviderActividadP>
          <FormularioIniciativa onClickFinalizar={handlePopupVerClose} />
        </DataProviderActividadP>
      </PopupFS>
      <PopupAlert
        open={openPopupAlert}
        handleSubmitNo={handlePopupCrearClose}
        handleSubmitSi={handleSubmitIncompleto}
        title="¿Desea guardar su progreso antes de salir del formulario?"
      >
        Su progreso quedar guardado en la plataforma para su posterior
        continuidad.
      </PopupAlert>
      <PopupAlert
        open={openPopupEnviar}
        handleSubmitNo={handlePopupEnviarClose}
        handleSubmitSi={handleEnviar}
        onClose={handlePopupEnviarClose}
        title="¿Desea enviar el formulario a revisión?"
      >
        Recuerde que no podra editar el formulario una vez enviado.
      </PopupAlert>
      <Popup
        open={openPopupPlantilla}
        onClose={handlePopupPlantillaClose}
        handleSubmit={handlePopupPlantillaClose}
        buttonName={"Cerrar"}
        title={"Seleccione iniciativa para usar como plantilla"}
        maxWidth={"lg"}
      >
        <TableIniciativasPlantilla onClickSeleccionar={handlePlantillaFetch} />
      </Popup>
      <Popup
        open={openPopupRetroalimentacion}
        onClose={handlePopupRetroalimentacionClose}
        handleSubmit={handlePopupRetroalimentacionClose}
        buttonName={"Cerrar"}
        title={"Retroalimentación"}
      >
        <TableRetroalimentacionIniciativa
          idIniciativa={rowProgramaID}
          onClickSeleccionar={handleRetroalimentacionFetch}
        />
      </Popup>
      <Popup
        open={openPopupObservacion}
        onClose={handlePopupObservacionClose}
        buttonName={"Cerrar"}
        handleSubmit={handlePopupObservacionClose}
        maxWidth={"lg"}
        title={"Ingrese la retroalimentación para el formulario"}
      >
        <Retroalimentacion
          nombreAccion={"Retroalimentación de la iniciativa"}
          valuesRetroalimentacion={valuesRetroalimentacion}
          readonly={true}
        />
      </Popup>
      <ToastContainer
        toastStyle={{
          ...(theme.palette.mode === "dark" && { backgroundColor: "#292929" }),
        }}
      />
      <BackdropLoading open={openBackdrop} handleClose={handleBackdropClose} />
    </Box>
  );
};

export default Emergentes;
