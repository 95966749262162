import { Box, Button, Stack } from "@mui/material";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { useDataContext } from "../context/DataContext";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import VisibilityIcon from "@mui/icons-material/Visibility";

const TableRetroalimentacionActividad = ({ onClickSeleccionar, idActividad }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const { retroalimentacion, isLoading } = useDataContext();

  const processedData = retroalimentacion.map((item) => {
    const date = new Date(item.fechaIngreso);
    date.setHours(date.getHours() + 3);

    return {
      ...item,
      fechaIngresoDate: date.toLocaleDateString(),
      fechaIngresoTime: date.toLocaleTimeString(),
    };
  });

  const actividadRetroalimentacion = processedData.filter(
    (retroalimentacion) => retroalimentacion.id_actividad === idActividad
  );

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "fechaIngresoDate",
      headerName: "Fecha",
      flex: 0.5,
    },
    // {
    //   field: "fechaIngresoTime",
    //   headerName: "Hora",
    //   flex: 0.5,
    // },
    {
      headerName: "",
      flex: 0.5,
      align: "right",
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box>
            {theme.palette.mode === "dark" ? (
              <Stack direction="row" spacing={1} display="flex">
                <Button
                  size="small"
                  color="secondary"
                  variant="outlined"
                  startIcon={<VisibilityIcon />}
                  onClick={() => onClickSeleccionar(rowId)}
                >
                  Ver
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" spacing={1}>
                {/* <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  disableElevation
                  startIcon={<AddCircleOutlinedIcon />}
                >
                  Boton 1
                </Button> */}

                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  disableElevation
                  startIcon={<VisibilityIcon />}
                  onClick={() => onClickSeleccionar(rowId)}
                >
                  Ver
                </Button>
              </Stack>
            )}
          </Box>
        );
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="3px"
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        marginTop: "1vh",
        height: isNonMobile ? "75vh" : "65vh",
        overflow: "auto",
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[400],
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                // backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: "white",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "white",
              },
              "& .MuiTouchRipple-root": {
                color: "white",
              },
              //   "& .name-column--cell": {
              //     color: colors.orangeAccent[400],
              //     fontWeight: "bold",
              //   },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }
          : {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[400],
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                // backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: "white",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "white",
              },
              "& .MuiTouchRipple-root": {
                color: "white",
              },
              //   "& .name-column--cell": {
              //     color: colors.orangeAccent[500],
              //     fontWeight: "bold",
              //   },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }),
      }}
    >
      {isLoading ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <DataGrid
          disableRowSelectionOnClick
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={actividadRetroalimentacion}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          autoPageSize
          rowHeight={isNonMobile ? 52 : 40}
          columnHeaderHeight={isNonMobile ? 56 : 44}
        />
      )}
    </Box>
  );
};

export default TableRetroalimentacionActividad;
