import React from "react";
import { tokens } from "../theme";
import { Box, Breadcrumbs, useTheme, Typography, Tooltip, Zoom } from "@mui/material";
import { NavLink } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const Breadcrump = ({ page }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Tooltip title={<Typography variant="h7" color={colors.grey[100]}>Inicio</Typography>} placement="top" TransitionComponent={Zoom} arrow>
          <NavLink className="nav-bar-link" to="/">
            <HomeOutlinedIcon sx={{ mr: "4px", mb: "3px" }}/>
          </NavLink>
        </Tooltip>
        <Typography color={colors.grey[100]}>{page}</Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrump;
