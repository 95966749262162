import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from 'react';
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDataContext } from "../../../context/DataContext";
import TooltipInfo from "../../../components/TooltipInfo";

const GestionDeLaAccion = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const {
    actorParticipa,
    selectedActorParticipa,
    setSelectedActorParticipa,
    etapaParticipa,
    selectedEtapaParticipa,
    setSelectedEtapaParticipa,
  } = useDataContext();

  
  const handleActorParticipaChange = (event) => {
    if(event.target.value==2)
    { setSelectedEtapaParticipa([5]);}
    setSelectedActorParticipa(event.target.value);
  };

  const handleEtapaParticipaChange = (event, etapaParticipaId) => {
    if (event.target.checked) {
      setSelectedEtapaParticipa([...selectedEtapaParticipa, etapaParticipaId]);
    } else {
      setSelectedEtapaParticipa(
        selectedEtapaParticipa.filter((id) => id !== etapaParticipaId)
      );
    }
  };

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      rowGap={1}
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "5px" : "0px"}
            fontWeight="bold"
          >
            9. Gestión de la Acción{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Gestión de la acción:
                  </Typography>
                  <br></br>En este punto se requiere determinar el nivel de
                  participación del entorno relevante en la gestión del programa
                  de vinculación con el medio que se está registrando. Por
                  favor, indique si el entorno participará activamente en alguna
                  etapa de la gestión o simplemente será beneficiario de la
                  misma.
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
              >
                9.1. ¿En este programa participará el actor/a clave del medio?
              </Typography>
            </Box>
            <Box>
              <FormControl component="fieldset" variant="standard">
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  {actorParticipa.map((actorParticipa, id) => (
                    <Box>
                      <RadioGroup
                        value={selectedActorParticipa}
                        onChange={handleActorParticipaChange}
                      >
                        <FormControlLabel
                          key={actorParticipa.id}
                          value={actorParticipa.id}
                          control={
                            <Radio
                              color="default"
                              style={{
                                color: colors.grey[100],
                                marginRight: "5px",
                              }}
                            />
                          }
                          label={
                            <Typography
                              variant={isNonMobile ? "h6" : "h7"}
                              color={colors.grey[100]}
                            >
                              {actorParticipa.descripcion}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </Box>
                  ))}
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom={isNonMobile ? "10px" : "15px"}
              >
                9.2. ¿En qué etapa de la gestión de la VCM participará el medio?
              </Typography>
            </Box>
            <Box>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {etapaParticipa.map((etapa) => (
                    <Box
                      display="grid"
                      gridTemplateRows={
                        isNonMobile ? undefined : "repeat(4, 7px)"
                      }
                      alignItems="center"
                    >
                      <FormControlLabel
                        key={etapa.id}
                        control={
                          <Checkbox
                            color="default"
                            checked={selectedEtapaParticipa.includes(
                              etapa.id
                            )}
                            onChange={(event) =>
                              handleEtapaParticipaChange(
                                event,
                                etapa.id
                              )
                            }
                            value={etapa.id}
                            disabled={selectedActorParticipa==2 && etapa.id != 5}
                            style={{
                              color: colors.grey[100],
                              marginRight: "5px",
                            }}
                          />
                        }
                        label={etapa.descripcion}
                      />
                    </Box>
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GestionDeLaAccion;
