import {
  Box,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import { useDataContext } from "../../../context/DataContext";

const ParticipacionEsperada = ({
  onChangeParticipacionEsperada,
  valuesParticipacionEsperada,
  touchedParticipacionEsperada,
  errorsParticipacionEsperada,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const {
    participantesEsperados,
    selectedParticipantesEsperados,
    setSelectedParticipantesEsperados,
    tipoParticipantes,
    selectedTipoParticipantes,
    setSelectedTipoParticipantes,
  } = useDataContext();

  const handleParticipacionEsperadaChange = (event) => {
    setSelectedParticipantesEsperados(event.target.value);
  };

  const handleTipoParticipantesChange = (event, tipoParticipantesId) => {
    if (event.target.checked) {
      setSelectedTipoParticipantes([
        ...selectedTipoParticipantes,
        tipoParticipantesId,
      ]);
    } else {
      setSelectedTipoParticipantes(
        selectedTipoParticipantes.filter((id) => id !== tipoParticipantesId)
      );
    }
  };

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh " : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      rowGap={1}
      sx={{
        "& > div": {
          gridColumn: isNonMobile ? undefined : "span 4",
        },
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
            fontWeight="bold"
          >
            14. Participación Esperada
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom={isNonMobile ? undefined : "15px"}
              >
                14.1. Tipo de Participantes
              </Typography>
            </Box>
            <Box>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {tipoParticipantes.map((tipoParticipantes, id) => (
                    <Box
                      display="grid"
                      gridTemplateRows={
                        isNonMobile ? undefined : "repeat(4, 7px)"
                      }
                      alignItems="center"
                    >
                      <FormControlLabel
                        key={tipoParticipantes.id}
                        control={
                          <Checkbox
                            color="default"
                            checked={selectedTipoParticipantes.includes(
                              tipoParticipantes.id
                            )}
                            onChange={(event) =>
                              handleTipoParticipantesChange(
                                event,
                                tipoParticipantes.id
                              )
                            }
                            value={tipoParticipantes.id}
                            style={{
                              color: colors.grey[100],
                              marginRight: "5px",
                            }}
                          />
                        }
                        label={tipoParticipantes.descripcion}
                      />
                    </Box>
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                14.2. Número de participantes esperados
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                select
                SelectProps={{
                  sx: { fontSize: isNonMobile ? undefined : "12px" },
                }}
                label="Participantes esperados"
                onChange={handleParticipacionEsperadaChange}
                value={selectedParticipantesEsperados}
                name="participacionEsperada"
                error={
                  !!touchedParticipacionEsperada &&
                  !!errorsParticipacionEsperada
                }
                helperText={
                  touchedParticipacionEsperada && errorsParticipacionEsperada
                }
              >
                {participantesEsperados.map((participantesEsperados) => (
                  <MenuItem
                    key={participantesEsperados.id}
                    value={participantesEsperados.id}
                  >
                    {participantesEsperados.descripcion}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ParticipacionEsperada;
