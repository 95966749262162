import * as React from "react";
import {
  Body,
  Button,
  Container,
  Head,
  Hr,
  Html,
  Img,
  Preview,
  Section,
  Text,
} from "@react-email/components";

const vimeMail = ({
  userFirstname,
  nombrePrograma,
  tipoAccion,
  tipoAccion2,
  idPrograma,
  responsablePrograma,
  descAccion,
  descAccionStrong,
  linkVIME,
  preview
}) => {
  const main = {
    backgroundColor: "#ffffff",
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
  };
  const container = {
    margin: "0 auto",
    padding: "20px 0 48px",
  };
  const logo = {
    margin: "0 auto",
  };
  const paragraph = {
    fontSize: "16px",
    lineHeight: "26px",
    color: "#000000",
  };
  const paragraphColor = {
    fontSize: "16px",
    lineHeight: "10px",
    color: "#ff5912",
    fontWeight: "bold",
  };

  const paragraphBold = {
    fontSize: "16px",
    lineHeight: "0px",
    color: "#000000",
    fontWeight: "bold",
  };

  const btnContainer = {
    textAlign: "center",
    marginTop: "25px",
  };
  const button = {
    backgroundColor: "#ff5912",
    borderRadius: "3px",
    color: "#fff",
    fontSize: "16px",
    textDecoration: "none",
    textAlign: "center",
    display: "block",
    padding: "12px 12px",
  };
  const hr = {
    borderColor: "#cccccc",
    margin: "20px 0",
  };
  const footer = {
    color: "#8898aa",
    fontSize: "12px",
  };
  return (
    <Html>
      <Head />
      <Preview>
        {tipoAccion} "{nombrePrograma}" {preview}
      </Preview>
      <Body style={main}>
        <Container style={container}>
          <Img
            src={"https://i.ibb.co/80wcrrQ/VIME.png"}
            width="260"
            height="100"
            alt="VIME"
            style={logo}
          />
          <Text style={paragraph}>
            Estimado/a <strong style={paragraphColor}>{userFirstname}</strong>,
          </Text>
          <Text style={paragraph}>
            Le informamos que {tipoAccion2} con los datos:
            <Text style={paragraphColor}>
              <strong style={paragraphBold}>ID: </strong>
              {idPrograma}
            </Text>
            <Text style={paragraphColor}>
              <strong style={paragraphBold}>Nombre: </strong>"{nombrePrograma}"
            </Text>
            <Text style={paragraphColor}>
              <strong style={paragraphBold}>Responsable: </strong>
              {responsablePrograma}
            </Text>
            <Text style={paragraph}>
              {descAccion}{" "}
              <strong style={paragraphColor}>{descAccionStrong}</strong>.
            </Text>
          </Text>
          <Section style={btnContainer}>
            <Button style={button} href={linkVIME}>
              Ir a VIME
            </Button>
          </Section>
          <Text style={paragraph}>
            Atentamente,
            <br />
            VIME USEK
          </Text>
          <Hr style={hr} />
          <Text style={footer}>
            Fernando Manterola 0789 / Santa María 0760, Providencia
          </Text>
        </Container>
      </Body>
    </Html>
  );
};

export default vimeMail;
