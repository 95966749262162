import axios from "axios";
import { toast } from "react-toastify";

const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
function formatDateToSQLCompatible() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

const formattedDate = formatDateToSQLCompatible();

const submitIncompleto = async (
  valuesNombrePrograma,
  valuesResponsablePrograma,
  valuesDiagnostico,
  valuesDireccion,
  valuesRecursosMateriales,
  valuesTransporteAlimentacion,
  valuesOtrosRecursos,
  valuesTotal,
  valuesResultadoEsperado,
  selectedFuenteFinanciamiento,
  isLineaSubSelected,
  isAsignaturaSelected,
  selectedFocoPrioritario,
  selectedCicloFormativo,
  selectedLineaDeAccionRows,
  selectedPEI,
  selectedPais,
  selectedRegion,
  selectedCiudad,
  selectedAlcanceTerritorial,
  selectedGrupoInteresRelevante,
  selectedEtapaParticipa,
  selectedParticipantesEsperados,
  selectedTipoParticipantes,
  selectedContribucionInterna,
  selectedContribucionExterna,
  selectedFacultadRows,
  selectedCarreraRows,
  selectedAmbitoRows,
  selectedLineaSubRows,
  selectedAsignaturaRows,
  selectedActorParticipa,
  selectedODS,
  selectedInstrumentoEvaluacion,
  valuesObjetivoGeneral,
  valuesObjetivoEspecifico,
  idObjetivoGeneral,
  id,
  getProyecto,
  valuesFechaInicio,
  valuesFechaTermino,
  clearValues,
  setOpenBackdrop,
  theme,
  valuesIdUsuario,
  valuesMail,
  valuesUsername
) => {
  try {
    await axios.post("https://vime.usek.cl/api/proyecto", {
      id: id,
      id_tipoAccion: 2,
      nombreProyecto: valuesNombrePrograma,
      nombreResponsable: valuesResponsablePrograma,
      fechaInicio: valuesFechaInicio,
      fechaTermino: valuesFechaTermino,
      diagnostico: valuesDiagnostico,
      direccion: valuesDireccion,
      presupuestoRM:
        parseFloat(removeNonNumeric(valuesRecursosMateriales)) || 0,
      presupuestoTA:
        parseFloat(removeNonNumeric(valuesTransporteAlimentacion)) || 0,
      presupuestoOR: parseFloat(removeNonNumeric(valuesOtrosRecursos)) || 0,
      presupuestoTotal: parseFloat(removeNonNumeric(valuesTotal)) || 0,
      id_usuario: valuesIdUsuario,
      id_estado: 2,
      resultadoEsperado: valuesResultadoEsperado,
      mailUsuario: valuesMail,
      nombreAutor: valuesUsername,
    });

    await axios.post("https://vime.usek.cl/api/facultadSeleccion", {
      id_facultad: selectedFacultadRows,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/carreraSeleccion", {
      id_carrera: selectedCarreraRows,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/fuenteFinanciamientoSeleccion", {
      id_fuente: selectedFuenteFinanciamiento,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/cicloFormativoSeleccion", {
      id_ciclo: selectedCicloFormativo,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/focoPrioritarioSeleccion", {
      id_foco: selectedFocoPrioritario,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/ambitosSeleccion", {
      id_ambito: selectedAmbitoRows,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/lineasDeAccionSeleccion", {
      id_linea: selectedLineaDeAccionRows,
      id_proyecto: id,
    });

    isLineaSubSelected &&
      (await axios.post(
        "https://vime.usek.cl/api/lineasSubCategoriaSeleccion",
        {
          id_lineaSub: selectedLineaSubRows,
          id_proyecto: id,
        }
      ));

    isAsignaturaSelected &&
      (await axios.post("https://vime.usek.cl/api/asignaturasSeleccion", {
        id_asignatura: selectedAsignaturaRows,
        id_proyecto: id,
      }));

    await axios.post("https://vime.usek.cl/api/peiSeleccion", {
      id_pei: selectedPEI,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/alcanceTerritorialSeleccion", {
      id_alcance: selectedAlcanceTerritorial,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/paisSeleccion", {
      id_pais: selectedPais,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/regionSeleccion", {
      id_region: selectedRegion,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/ciudadSeleccion", {
      id_ciudad: selectedCiudad,
      id_proyecto: id,
    });

    await axios.post(
      "https://vime.usek.cl/api/grupoInteresRelevanteSeleccion",
      {
        id_grupo: selectedGrupoInteresRelevante,
        id_proyecto: id,
      }
    );

    await axios.post("https://vime.usek.cl/api/actorParticipaSeleccion", {
      id_actor: selectedActorParticipa,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/etapaParticipaSeleccion", {
      id_etapa: selectedEtapaParticipa,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/objetivoGeneral", {
      id: idObjetivoGeneral,
      descripcion: valuesObjetivoGeneral,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/objetivoEspecifico", {
      id_objGeneral: idObjetivoGeneral,
      descripcion: valuesObjetivoEspecifico,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/tipoParticipantesSeleccion", {
      id_tipoParticipantes: selectedTipoParticipantes,
      id_proyecto: id,
    });

    await axios.post(
      "https://vime.usek.cl/api/participantesEsperadosSeleccion",
      {
        id_participantesEsperados: selectedParticipantesEsperados,
        id_proyecto: id,
      }
    );

    await axios.post("https://vime.usek.cl/api/contribucionInternaSeleccion", {
      id_contribucionInterna: selectedContribucionInterna,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/contribucionExternaSeleccion", {
      id_contribucionExterna: selectedContribucionExterna,
      id_proyecto: id,
    });

    await axios.post("https://vime.usek.cl/api/odsSeleccion", {
      id_ods: selectedODS,
      id_proyecto: id,
    });

    await axios.post(
      "https://vime.usek.cl/api/instrumentoEvaluacionSeleccion",
      {
        id_instrumentoEvaluacion: selectedInstrumentoEvaluacion,
        id_proyecto: id,
      }
    );

    getProyecto();
    clearValues();
    setOpenBackdrop(false);
    toast.success("Proyecto Creado", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: theme.palette.mode === "dark" ? "dark" : "light",
    });
  } catch (error) {
    console.error("Error al ingresar formulario:", error);
  }

  try {
    await axios.post("https://vime.usek.cl/api/auditoria", {
      Username: valuesIdUsuario,
      Fecha: formattedDate,
      Accion: "Creación",
      Opcion: "Creación de proyecto incompleto",
      Transaccion: `El usuario creo el proyecto incompleto con el ID: ${id}`,
      Sistema: "VIME",
    });
  } catch (error) {
    console.error("Error ingresando auditoria: ", error);
  }
};

export default submitIncompleto;
