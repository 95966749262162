import { Box, Button, Stack } from "@mui/material";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { useDataContext } from "../context/DataContext";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import DropdownMenu from "../components/DropdownMenu";
import EditIcon from "@mui/icons-material/Edit";
import useMediaQuery from "@mui/material/useMediaQuery";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GradingIcon from "@mui/icons-material/Grading";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDataContextSession } from "../context/DataContextSession";

const TableIniciativas = ({
  handlePopupCrearOpen,
  onClickEditar,
  onClickContinuar,
  onClickDropdown,
  onClickVer,
  onClickEnviar,
  onClickRetroalimentacion,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { idUsuario, perfil,permisos } = useDataContextSession();
  const { iniciativa, isLoading, estado } = useDataContext();

  const iniciativaPlantilla = iniciativa.filter(
    (iniciativa) =>
      iniciativa.id_estado !== 2 && iniciativa.id_usuario === idUsuario
  );

  const iniciativaUsuario = iniciativa

  const iniciativaUsuarioDVCM = iniciativa.filter(
    (iniciativa) =>
      iniciativa.id_usuario === idUsuario || 
    [1, 4, 5].includes(iniciativa.id_estado)
    
  );
  const tienePermisoEditar = (permisos) => {
    console.log(permisos.some(permiso => permiso.startsWith("VIME04") && permiso.includes("W")))
    return permisos.some(permiso => permiso.startsWith("VIME04") && permiso.includes("W"));
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: isNonMobile ? 0.7 : isNonMobileMedium ? 1 : 1,
    },
    {
      field: "nombreIniciativa",
      headerName: "Nombre de la Iniciativa",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "nombreResponsable",
      headerName: "Nombre del Responsable",
      flex: 1,
    },
    {
      field: "fechaInicio",
      headerName: "Fecha de Inicio",
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const offset = date.getTimezoneOffset() * 60 * 1000; // Get timezone offset in milliseconds
        const adjustedDate = new Date(date.getTime() + offset); // Adjust date by adding the offset

        const day = String(adjustedDate.getDate()).padStart(2, "0"); // Ensure two digits for day
        const month = String(adjustedDate.getMonth() + 1).padStart(2, "0"); // Ensure two digits for month
        const year = adjustedDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
      },
    },
    {
      field: "fechaTermino",
      headerName: "Fecha de Termino",
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const offset = date.getTimezoneOffset() * 60 * 1000; // Get timezone offset in milliseconds
        const adjustedDate = new Date(date.getTime() + offset); // Adjust date by adding the offset

        const day = String(adjustedDate.getDate()).padStart(2, "0"); // Ensure two digits for day
        const month = String(adjustedDate.getMonth() + 1).padStart(2, "0"); // Ensure two digits for month
        const year = adjustedDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
      },
    },
    {
      field: "presupuestoTotal",
      headerName: "Presupuesto",
      flex: 0.5,
      cellClassName: "email-column--cell",
      renderCell: (params) => (
        <Box textAlign="right">
          {"$ " + new Intl.NumberFormat("es-ES").format(params.value)}
        </Box>
      ),
    },
    {
      field: "id_estado",
      headerName: "Estado",
      flex: 1,
      headerAlign: "center",
      align: "center",
      cellClassName: "email-column--cell",
      renderCell: (params) => {
        const estadoId = params.value;
        const estadoObject = estado.find((e) => e.id === estadoId);

        // Determine the icon based on the estadoId value
        let icon = null;
        if (estadoId === 1) {
          icon = <CheckCircleIcon color="success" />;
        } else if (estadoId === 2) {
          icon = <WarningIcon sx={{ color: "#E9D502" }} />;
        } else if (estadoId === 3) {
          icon = <GradingIcon />;
        } else if (estadoId === 4) {
          icon = <ThumbUpAltIcon color="success" />;
        } else if (estadoId === 5) {
          icon = <CancelIcon color="error" />;
        }

        // Display the icon and estado description
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            columnGap={1}
          >
            {icon}
            <span>{estadoObject ? estadoObject.descripcion : ""}</span>
          </Box>
        );
      },
    },
    {
      headerName: "",
      flex: isNonMobile ? 0.9 : isNonMobileMedium ? 1.2 : 1.2,
      align: "right",
      renderCell: (params) => {
        const rowId = params.row.id;
        const estadoRow = params.row.id_estado;
        return (
          <Box>
            {theme.palette.mode === "dark" ? (
              <Box display="flex">
                {estadoRow === 1 && (
                  <Stack direction="row" spacing={1} display="flex">
                        {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => onClickEditar(rowId)}
                    >
                      Editar
                    </Button>)}
                    {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<SendIcon />}
                      onClick={() => onClickEnviar(rowId)}
                    >
                      Enviar
                    </Button>)}
                  </Stack>
                )}
                {estadoRow === 2 && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => onClickContinuar(rowId)}
                  >
                    Continuar
                  </Button>
                )}
                {(estadoRow === 3 || estadoRow === 4) && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<VisibilityIcon />}
                    onClick={() => onClickVer(rowId)}
                  >
                    Ver
                  </Button>
                )}
                {estadoRow === 5 && (
                  <Stack direction="row" spacing={1} display="flex">
                        {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => onClickEditar(rowId)}
                    >
                      Editar
                    </Button>
                        )}
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      onClick={() => onClickRetroalimentacion(rowId)}
                    >
                      Observaciones
                    </Button>
                  </Stack>
                )}
              </Box>
            ) : (
              <Box display="flex">
                {estadoRow === 1 && (
                  <Stack direction="row" spacing={1}>
                        {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<EditIcon />}
                      onClick={() => onClickEditar(rowId)}
                    >
                      Editar
                    </Button>)}
                    {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<SendIcon />}
                      onClick={() => onClickEnviar(rowId)}
                    >
                      Enviar
                    </Button>)}
                  </Stack>
                )}
                {estadoRow === 2 && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                    disableElevation
                    onClick={() => onClickContinuar(rowId)}
                  >
                    Continuar
                  </Button>
                )}
                {(estadoRow === 3 || estadoRow === 4) && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    startIcon={<VisibilityIcon />}
                    disableElevation
                    onClick={() => onClickVer(rowId)}
                  >
                    Ver
                  </Button>
                )}
                {estadoRow === 5 && (
                  <Stack direction="row" spacing={1}>
                        {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<EditIcon />}
                      onClick={() => onClickEditar(rowId)}
                    >
                      Editar
                    </Button>)}
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      onClick={() => onClickRetroalimentacion(rowId)}
                    >
                      Observaciones
                    </Button>
                  </Stack>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const columnsDVCM = [
    {
      field: "id",
      headerName: "ID",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 1.2 : 1.5,
    },
    {
      field: "nombreIniciativa",
      headerName: "Nombre de la Iniciativa",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 1 : 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "nombreResponsable",
      headerName: "Nombre del Responsable",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 1 : 1,
    },
    {
      field: "fechaInicio",
      headerName: "Fecha de Inicio",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 0.7 : 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const offset = date.getTimezoneOffset() * 60 * 1000; // Get timezone offset in milliseconds
        const adjustedDate = new Date(date.getTime() + offset); // Adjust date by adding the offset

        const day = String(adjustedDate.getDate()).padStart(2, "0"); // Ensure two digits for day
        const month = String(adjustedDate.getMonth() + 1).padStart(2, "0"); // Ensure two digits for month
        const year = adjustedDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
      },
    },
    {
      field: "fechaTermino",
      headerName: "Fecha de Termino",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 0.8 : 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const offset = date.getTimezoneOffset() * 60 * 1000; // Get timezone offset in milliseconds
        const adjustedDate = new Date(date.getTime() + offset); // Adjust date by adding the offset

        const day = String(adjustedDate.getDate()).padStart(2, "0"); // Ensure two digits for day
        const month = String(adjustedDate.getMonth() + 1).padStart(2, "0"); // Ensure two digits for month
        const year = adjustedDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
      },
    },
    {
      field: "presupuestoTotal",
      headerName: "Presupuesto",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 0.6 : 1,
      cellClassName: "email-column--cell",
      renderCell: (params) => (
        <Box textAlign="right">
          {"$ " + new Intl.NumberFormat("es-ES").format(params.value)}
        </Box>
      ),
    },
    {
      field: "nombreAutor",
      headerName: "Nombre del Autor",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 1 : 1,
    },
    {
      field: "mailUsuario",
      headerName: "Email del Autor",
      flex: isNonMobile ? 1.2 : isNonMobileMedium ? 1.4 : 1,
    },
    {
      field: "id_estado",
      headerName: "Estado",
      flex: isNonMobile ? 1 : isNonMobileMedium ? 1 : 1,
      headerAlign: "center",
      align: "center",
      cellClassName: "email-column--cell",
      renderCell: (params) => {
        const estadoId = params.value;
        const estadoObject = estado.find((e) => e.id === estadoId);

        // Determine the icon based on the estadoId value
        let icon = null;
        if (estadoId === 1) {
          icon = <CheckCircleIcon color="success" />;
        } else if (estadoId === 2) {
          icon = <WarningIcon sx={{ color: "#E9D502" }} />;
        } else if (estadoId === 3) {
          icon = <GradingIcon />;
        } else if (estadoId === 4) {
          icon = <ThumbUpAltIcon color="success" />;
        } else if (estadoId === 5) {
          icon = <CancelIcon color="error" />;
        }

        // Display the icon and estado description
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            columnGap={1}
          >
            {icon}
            <span>{estadoObject ? estadoObject.descripcion : ""}</span>
          </Box>
        );
      },
    },
    {
      headerName: "",
      flex: isNonMobile ? 1.2 : isNonMobileMedium ? 1.5 : 1.8,
      align: "right",
      renderCell: (params) => {
        const rowId = params.row.id;
        const estadoRow = params.row.id_estado;
        return (
          <Box>
            {theme.palette.mode === "dark" ? (
              <Box display="flex">
                {estadoRow === 1 && (
                  <Stack direction="row" spacing={1} display="flex">
                        {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => onClickEditar(rowId)}
                    >
                      Editar
                    </Button>)}
                    {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<SendIcon />}
                      onClick={() => onClickEnviar(rowId)}
                    >
                      Enviar
                    </Button>)}
                  </Stack>
                )}
                {estadoRow === 2 && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => onClickContinuar(rowId)}
                  >
                    Continuar
                  </Button>
                )}
                {(estadoRow === 3 || estadoRow === 4) && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    startIcon={<VisibilityIcon />}
                    onClick={() => onClickVer(rowId)}
                  >
                    Ver
                  </Button>
                )}
                {estadoRow === 5 && (
                  <Stack direction="row" spacing={1} display="flex">
                        {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<EditIcon />}
                      onClick={() => onClickEditar(rowId)}
                    >
                      Editar
                    </Button>)}
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      onClick={() => onClickRetroalimentacion(rowId)}
                    >
                      Observaciones
                    </Button>
                  </Stack>
                )}
              </Box>
            ) : (
              <Box display="flex">
                {estadoRow === 1 && (
                  <Stack direction="row" spacing={1}>
                        {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<EditIcon />}
                      onClick={() => onClickEditar(rowId)}
                    >
                      Editar
                    </Button>)}
                    {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<SendIcon />}
                      onClick={() => onClickEnviar(rowId)}
                    >
                      Enviar
                    </Button>)}
                  </Stack>
                )}
                {estadoRow === 2 && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                    disableElevation
                    onClick={() => onClickContinuar(rowId)}
                  >
                    Continuar
                  </Button>
                )}
                {(estadoRow === 3 || estadoRow === 4) && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    startIcon={<VisibilityIcon />}
                    disableElevation
                    onClick={() => onClickVer(rowId)}
                  >
                    Ver
                  </Button>
                )}
                {estadoRow === 5 && (
                  <Stack direction="row" spacing={1}>
                        {tienePermisoEditar(permisos) && (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      startIcon={<EditIcon />}
                      onClick={() => onClickEditar(rowId)}
                    >
                      Editar
                    </Button>)}
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      onClick={() => onClickRetroalimentacion(rowId)}
                    >
                      Observaciones
                    </Button>
                  </Stack>
                )}
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="3px"
      >
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
        {theme.palette.mode === "dark" ? (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <DropdownMenu
              disabled={iniciativaPlantilla.length === 0}
              onClick1={onClickDropdown}
            />
                {tienePermisoEditar(permisos) && (
            <Button
              onClick={handlePopupCrearOpen}
              color="secondary"
              variant="outlined"
              startIcon={<AddCircleOutlinedIcon />}
            >
              Nueva Iniciativa
            </Button>)}
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <DropdownMenu
              disabled={iniciativaPlantilla.length === 0}
              onClick1={onClickDropdown}
            />
                {tienePermisoEditar(permisos) && (
            <Button
              onClick={handlePopupCrearOpen}
              color="secondary"
              variant="contained"
              startIcon={<AddCircleOutlinedIcon />}
            >
              Nueva Iniciativa
            </Button>)}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        marginTop: "1vh",
        height: isNonMobile ? "80vh" : isNonMobileMedium ? "80vh" : "75vh",
        overflow: "auto",
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[400],
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                // backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: "white",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "white",
              },
              "& .MuiTouchRipple-root": {
                color: "white",
              },
              "& .name-column--cell": {
                color: colors.orangeAccent[400],
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }
          : {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[400],
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                // backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: "white",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "white",
              },
              "& .MuiTouchRipple-root": {
                color: "white",
              },
              "& .name-column--cell": {
                color: colors.orangeAccent[500],
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }),
      }}
    >
      {isLoading ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <DataGrid
          disableRowSelectionOnClick
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={perfil === "DVCM" || perfil === "1" ? iniciativaUsuarioDVCM : iniciativaUsuario}
          columns={perfil === "DVCM" || perfil === "1" ? columnsDVCM : columns}
          slots={{ toolbar: CustomToolbar }}
          autoPageSize
          rowHeight={isNonMobile ? 52 : isNonMobileMedium ? 45 : 40}
          columnHeaderHeight={isNonMobile ? 56 : 44}
        />
      )}
    </Box>
  );
};

export default TableIniciativas;
