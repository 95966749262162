import * as React from "react";
import Backdrop from "@mui/material/Backdrop";

const BackdropSimple = ({ open, handleClose }) => {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: 5, backgroundColor: "rgba(0, 0, 0, 0.9)" }}
        open={open}
        onClick={handleClose}
      ></Backdrop>
    </div>
  );
};

export default BackdropSimple;
