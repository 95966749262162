import axios from "axios";

const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

const continuarFetch = async (
  rowID,
  setSelectedFacultadRows,
  setValuesNombrePrograma,
  setValuesResponsablePrograma,
  setValuesDireccion,
  setValuesDiagnostico,
  setValuesRecursosMateriales,
  setValuesTransporteAlimentacion,
  setValuesOtrosRecursos,
  setValuesTotal,
  setValuesResultadoEsperado,
  setSelectedCarreraRows,
  setIsFacultadSelected,
  setIsCarreraSelected,
  setSelectedFuenteFinanciamiento,
  setSelectedCicloFormativo,
  setSelectedFocoPrioritario,
  setSelectedAmbitoRows,
  setIsAmbitoSelected,
  setSelectedLineaSubRows,
  setSelectedLineaDeAccionRows,
  setIsLineaDeAccionSelected,
  setIsLineaSubSelected,
  setSelectedAsignaturaRows,
  setIsAsignaturaSelected,
  setHasNoRows,
  setSelectedPEI,
  setSelectedAlcanceTerritorial,
  setSelectedPais,
  setSelectedRegion,
  setSelectedCiudad,
  setSelectedGrupoInteresRelevante,
  setSelectedActorParticipa,
  setSelectedEtapaParticipa,
  setSelectedTipoParticipantes,
  setSelectedParticipantesEsperados,
  setSelectedContribucionInterna,
  setSelectedContribucionExterna,
  setIsODSchecked,
  setIsInstrumentoEvaluacionChecked,
  setRowProgramaID,
  setExistingFuenteFinanciamiento,
  setExistingFacultad,
  setExistingCarrera,
  setExistingAmbito,
  setExistingLineaAccion,
  setExistingLineaSub,
  setExistingAsignatura,
  setExistingCicloFormativo,
  setExistingPEI,
  setExistingAlcanceTerritorial,
  setExistingGrupoInteresRelevante,
  setExistingEtapaParticipa,
  setExistingTipoParticipantes,
  setExistingContribucionInterna,
  setExistingContribucionExterna,
  setExistingODS,
  setExistingInstrumentoEvaluacion,
  setValuesFechaInicio,
  setValuesFechaTermino,
  setValuesObjetivoGeneralActividad,
  setValuesBreveDescripcionActividad,
  setSelectedInstrumentoEvaluacion,
  setSelectedODS,
  setValuesIdUsuario,
  setValuesMail,
  setValuesUsername
) => {
  try {
    const programResponse = await axios.get(
      `https://vime.usek.cl/api/iniciativa/${rowID}`
    );
    const programData = programResponse.data;

    setValuesNombrePrograma(programData.nombreIniciativa);
    setValuesResponsablePrograma(programData.nombreResponsable);
    setValuesFechaInicio(programData.fechaInicio);
    setValuesFechaTermino(programData.fechaTermino);
    setValuesDireccion(programData.direccion);
    setValuesDiagnostico(programData.diagnostico);
    setValuesBreveDescripcionActividad(programData.descripcion);
    setValuesObjetivoGeneralActividad(programData.objetivoGeneral);
    setValuesRecursosMateriales(
      addCommas(removeNonNumeric(programData.presupuestoRM))
    );
    setValuesTransporteAlimentacion(
      addCommas(removeNonNumeric(programData.presupuestoTA))
    );
    setValuesOtrosRecursos(
      addCommas(removeNonNumeric(programData.presupuestoOR))
    );
    setValuesTotal(addCommas(removeNonNumeric(programData.presupuestoTotal)));
    setValuesResultadoEsperado(programData.resultadoEsperado);
    setValuesIdUsuario(programData.id_usuario);
    setValuesMail(programData.mailUsuario);
    setValuesUsername(programData.nombreAutor);

    const facultadesSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/facultadSeleccion"
    );
    const facultadesSeleccionData = facultadesSeleccionResponse.data;

    const selectedFacultadIds = facultadesSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_facultad);

    setSelectedFacultadRows(selectedFacultadIds);
    setExistingFacultad(selectedFacultadIds);
    if (selectedFacultadIds.length > 0) {
      setIsFacultadSelected(true);
    } else {
      setIsFacultadSelected(false);
    }

    const carrerasSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/carreraSeleccion"
    );
    const carrerasSeleccionData = carrerasSeleccionResponse.data;

    const selectedCarrerasIds = carrerasSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_carrera);

    setSelectedCarreraRows(selectedCarrerasIds);
    setExistingCarrera(selectedCarrerasIds);
    if (selectedCarrerasIds.length > 0) {
      setIsCarreraSelected(true);
    } else {
      setIsCarreraSelected(false);
    }

    const fuenteFinanciamientoSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/fuenteFinanciamientoSeleccion"
    );
    const fuenteFinanciamientoSeleccionData =
      fuenteFinanciamientoSeleccionResponse.data;

    const selectedFuenteFinanciamientoIds = fuenteFinanciamientoSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_fuente);

    setSelectedFuenteFinanciamiento(selectedFuenteFinanciamientoIds);
    setExistingFuenteFinanciamiento(selectedFuenteFinanciamientoIds);

    const cicloFormativoSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/cicloFormativoSeleccion"
    );
    const cicloFormativoSeleccionData = cicloFormativoSeleccionResponse.data;

    const selectedCicloFormativoIds = cicloFormativoSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_ciclo);

    setSelectedCicloFormativo(selectedCicloFormativoIds);
    setExistingCicloFormativo(selectedCicloFormativoIds);

    const focoPrioritarioSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/focoPrioritarioSeleccion"
    );
    const focoPrioritarioSeleccionData = focoPrioritarioSeleccionResponse.data;

    const selectedFocoPrioritarioIds = focoPrioritarioSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_foco);

    setSelectedFocoPrioritario(selectedFocoPrioritarioIds);

    const ambitosSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/ambitosSeleccion"
    );
    const ambitosSeleccionData = ambitosSeleccionResponse.data;

    const selectedAmbitoIds = ambitosSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_ambito);

    setSelectedAmbitoRows(selectedAmbitoIds);
    setExistingAmbito(selectedAmbitoIds);
    if (selectedAmbitoIds.length > 0) {
      setIsAmbitoSelected(true);
    } else {
      setIsAmbitoSelected(false);
    }

    const lineaAccionSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/lineasDeAccionSeleccion"
    );
    const lineaAccionSeleccionData = lineaAccionSeleccionResponse.data;

    const selectedLineaAccionIds = lineaAccionSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_linea);

    setSelectedLineaDeAccionRows(selectedLineaAccionIds);
    setExistingLineaAccion(selectedLineaAccionIds);
    if (selectedLineaAccionIds.length > 0) {
      setIsLineaDeAccionSelected(true);
    } else {
      setIsLineaDeAccionSelected(false);
    }

    const lineaSubSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/lineasSubCategoriaSeleccion"
    );
    const lineaSubSeleccionData = lineaSubSeleccionResponse.data;

    const selectedlineaSubIds = lineaSubSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_lineaSub);

    setSelectedLineaSubRows(selectedlineaSubIds);
    setExistingLineaSub(selectedlineaSubIds);

    if (selectedlineaSubIds.length > 0) {
      setIsLineaSubSelected(true);
    } else {
      setIsLineaSubSelected(false);
    }

    const asignaturaSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/asignaturasSeleccion"
    );
    const asignaturaSeleccionData = asignaturaSeleccionResponse.data;

    const selectedAsignaturaIds = asignaturaSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_asignatura);

    setSelectedAsignaturaRows(selectedAsignaturaIds);
    setExistingAsignatura(selectedAsignaturaIds);

    if (selectedAsignaturaIds.length > 0) {
      setIsAsignaturaSelected(true);
      setHasNoRows(false);
    } else {
      setIsAsignaturaSelected(false);
      setHasNoRows(true);
    }

    const peiSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/peiSeleccion"
    );
    const peiSeleccionData = peiSeleccionResponse.data;

    const selectedPeiIds = peiSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_pei);

    setSelectedPEI(selectedPeiIds);
    setExistingPEI(selectedPeiIds);

    const alcanceTerritorialSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/alcanceTerritorialSeleccion"
    );
    const alcanceTerritorialSeleccionData =
      alcanceTerritorialSeleccionResponse.data;

    const selectedAlcanceTerritorialIds = alcanceTerritorialSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_alcance);

    setSelectedAlcanceTerritorial(selectedAlcanceTerritorialIds);
    setExistingAlcanceTerritorial(selectedAlcanceTerritorialIds);

    const paisSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/paisSeleccion"
    );
    const paisSeleccionData = paisSeleccionResponse.data;

    const selectedPaisIds = paisSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_pais);

    setSelectedPais(selectedPaisIds);

    const regionSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/regionSeleccion"
    );
    const regionSeleccionData = regionSeleccionResponse.data;

    const selectedRegionIds = regionSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_region);

    setSelectedRegion(selectedRegionIds);

    const ciudadSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/ciudadSeleccion"
    );
    const ciudadSeleccionData = ciudadSeleccionResponse.data;

    const selectedCiudadIds = ciudadSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_ciudad);

    setSelectedCiudad(selectedCiudadIds);

    const grupoInteresRelevanteSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/grupoInteresRelevanteSeleccion"
    );
    const grupoInteresRelevanteSeleccionData =
      grupoInteresRelevanteSeleccionResponse.data;

    const selectedGrupoInteresRelevanteIds = grupoInteresRelevanteSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_grupo);

    setSelectedGrupoInteresRelevante(selectedGrupoInteresRelevanteIds);
    setExistingGrupoInteresRelevante(selectedGrupoInteresRelevanteIds);

    const actorParticipaSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/actorParticipaSeleccion"
    );
    const actorParticipaSeleccionData = actorParticipaSeleccionResponse.data;

    const selectedActorParticipaIds = actorParticipaSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_actor);

    setSelectedActorParticipa(selectedActorParticipaIds);

    const etapaParticipaSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/etapaParticipaSeleccion"
    );
    const etapaParticipaSeleccionData = etapaParticipaSeleccionResponse.data;

    const selectedEtapaParticipaIds = etapaParticipaSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_etapa);

    setSelectedEtapaParticipa(selectedEtapaParticipaIds);
    setExistingEtapaParticipa(selectedEtapaParticipaIds);

    const tipoParticipantesSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/tipoParticipantesSeleccion"
    );
    const tipoParticipantesSeleccionData =
      tipoParticipantesSeleccionResponse.data;

    const selectedTipoParticipantesIds = tipoParticipantesSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_tipoParticipantes);

    setSelectedTipoParticipantes(selectedTipoParticipantesIds);
    setExistingTipoParticipantes(selectedTipoParticipantesIds);

    const participantesEsperadosSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/participantesEsperadosSeleccion"
    );
    const participantesEsperadosSeleccionData =
      participantesEsperadosSeleccionResponse.data;

    const selectedparticipantesEsperadosIds =
      participantesEsperadosSeleccionData
        .filter((item) => item.id_iniciativa === rowID)
        .map((item) => item.id_participantesEsperados);

    setSelectedParticipantesEsperados(selectedparticipantesEsperadosIds);

    const contribucionInternaSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/contribucionInternaSeleccion"
    );
    const contribucionInternaSeleccionData =
      contribucionInternaSeleccionResponse.data;

    const selectedContribucionInternaIds = contribucionInternaSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_contribucionInterna);

    setSelectedContribucionInterna(selectedContribucionInternaIds);
    setExistingContribucionInterna(selectedContribucionInternaIds);

    const contribucionExternaSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/contribucionExternaSeleccion"
    );
    const contribucionExternaSeleccionData =
      contribucionExternaSeleccionResponse.data;

    const selectedContribucionExternaIds = contribucionExternaSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_contribucionExterna);

    setSelectedContribucionExterna(selectedContribucionExternaIds);
    setExistingContribucionExterna(selectedContribucionExternaIds);

    const odsSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/odsSeleccion"
    );
    const odsSeleccionData = odsSeleccionResponse.data;
    const selectedOdsIds = odsSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_ods);

    // Update the state variable that handles the checkbox status
    const initialCheckboxes = {}; // Initialize an object to track checkbox status
    odsSeleccionData.forEach((item) => {
      initialCheckboxes[item.id_ods] = selectedOdsIds.includes(item.id_ods);
    });
    setIsODSchecked(initialCheckboxes);
    setSelectedODS(selectedOdsIds);
    setExistingODS(selectedOdsIds);

    const instrumentoEvaluacionSeleccionResponse = await axios.get(
      "https://vime.usek.cl/api/instrumentoEvaluacionSeleccion"
    );
    const instrumentoEvaluacionSeleccionData =
      instrumentoEvaluacionSeleccionResponse.data;
    const selectedInstrumentoEvaluacionIds = instrumentoEvaluacionSeleccionData
      .filter((item) => item.id_iniciativa === rowID)
      .map((item) => item.id_instrumentoEvaluacion);

    // Update the state variable that handles the checkbox status
    const initialCheckboxesIE = {}; // Initialize an object to track checkbox status
    instrumentoEvaluacionSeleccionData.forEach((item) => {
      initialCheckboxesIE[item.id_instrumentoEvaluacion] =
        selectedInstrumentoEvaluacionIds.includes(
          item.id_instrumentoEvaluacion
        );
    });
    setIsInstrumentoEvaluacionChecked(initialCheckboxesIE);
    setSelectedInstrumentoEvaluacion(selectedInstrumentoEvaluacionIds);
    setExistingInstrumentoEvaluacion(selectedInstrumentoEvaluacionIds);
    setRowProgramaID(rowID);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export default continuarFetch;
