import { Box, Button, Tab, Stack,Typography } from "@mui/material";
import { Formik } from "formik";
import Header from "../components/Header";
import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import InformacionGeneral from "./tabs/proyecto/InformacionGeneral";
import CoherenciaInterna from "./tabs/proyecto/CoherenciaInterna";
import ArticulacionPEI from "./tabs/proyecto/ArticulacionPEI";
import AlcanceGrupoInteresRelevante from "./tabs/proyecto/AlcanceGrupoInteresRelevante";
import GestionDeLaAccion from "./tabs/proyecto/GestionDeLaAccion";
import DetalleDeLaAccion from "./tabs/proyecto/DetalleDeLaAccion";
import Actividades from "./tabs/proyecto/Actividades";
import ParticipacionEsperada from "./tabs/proyecto/ParticipacionEsperada";
import Contribucion from "./tabs/proyecto/Contribucion";
import ODS from "./tabs/proyecto/ODS";
import InstrumentoDeEvaluacion from "./tabs/proyecto/InstrumentoDeEvaluacion";
import Presupuesto from "./tabs/proyecto/Presupuesto";
import { useDataContext } from "../context/DataContext";
import useMediaQuery from "@mui/material/useMediaQuery";

const FormularioProyecto = ({ onClickFinalizar }) => {
  const [value, setValue] = useState(1);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const {
    periodos,
    isFacultadSelected,
    isCarreraSelected,
    selectedFuenteFinanciamiento,
    valuesNombrePrograma,
    valuesResponsablePrograma,
    isAmbitoSelected,
    isLineaDeAccionSelected,
    isLineaSubSelected,
    isAsignaturaSelected,
    selectedFocoPrioritario,
    selectedCicloFormativo,
    selectedLineaDeAccionRows,
    hasNoRows,
    selectedPEI,
    selectedPais,
    selectedRegion,
    selectedCiudad,
    selectedAlcanceTerritorial,
    selectedGrupoInteresRelevante,
    valuesDireccion,
    selectedEtapaParticipa,
    valuesDiagnostico,
    valuesResultadoEsperado,
    isRowObjetivoAdded,
    selectedParticipantesEsperados,
    selectedTipoParticipantes,
    selectedContribucionInterna,
    selectedContribucionExterna,
    isODSSelected,
    isInstrumentoEvaluacionSelected,
    valuesRecursosMateriales,
    valuesTransporteAlimentacion,
    valuesOtrosRecursos,
    valuesTotal,
    selectedLineaSubRows,
    valuesFechaTermino,
    valuesFechaInicio,
  } = useDataContext();

  //  const handleChangeTab = (event, newValue) => {
  //    setValue(newValue);
  //  };

  const handleNextTab = () => {
    setValue((prevValue) => prevValue + 1);
  };

  const handlePrevTab = () => {
    setValue((prevValue) => prevValue - 1);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const tabNames = [
    "Información general",
    "Coherencia interna",
    "Articulación P.E.I",
    "Alcance / grupo de interés relevante",
    "Gestión de la acción",
    "Detalle de la acción",
    "Actividades",
    "Participación esperada",
    "Contribución",
    "ODS",
    "Instrumento de evaluación",
    "Presupuesto",
  ];
  const activePeriod = periodos.find(p => p.ESTADO === "ACTIVO");
  return (
    <Box display="grid" gridTemplateColumns="repeat(1, 1fr)" margin={isNonMobile ? "20px" : "0px 0px 0px 00px"}>
      <Header
  title={valuesNombrePrograma !=="" ? valuesNombrePrograma : "Nueva Proyecto"}
  subtitle={valuesResponsablePrograma !==""? `Responsable: ${valuesResponsablePrograma}` : "Crea un nuevo Proyecto"}
/>
<Typography
        variant={isNonMobile ? "h5" : "h6"}
        marginBottom="5px"
      >
    Periodo Academico: <span style={{ fontWeight: 'bold',fontSize:"18px" }}>{activePeriod.ANOACAD}/{activePeriod.PERIODOACAD}</span>
      </Typography>
      <Formik
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <Box marginTop={isNonMobile ? "5px" : "0px"}>
            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", width: isNonMobile ? undefined : "96vw"}}>
                <TabList
                  aria-label="Tabs example"
                  // onChange={handleChangeTab}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={false}
                  sx={{ height: "60px" }}
                >
                  {tabNames.map((tabName, index) => (
                    <Tab
                      disableRipple
                      key={index}
                      label={tabName}
                      icon={
                        index < value - 1 ? ( // Revisa si la pestaña esta antes de la pestaña actual
                          <CheckCircleIcon fontSize="24px" color="success" />
                        ) : index === value - 1 ? ( // Revisa si la pestaña es la pestaña actual
                          <EditIcon fontSize="24" />
                        ) : (
                          <></> // Icono vacio para las pestañas siguientes a la pestaña actual
                        )
                      }
                      iconPosition="end"
                      value={index + 1}
                    />
                  ))}
                </TabList>
              </Box>

              <Box>
                <form onSubmit={handleSubmit}>
                  <TabPanel value={1}>
                    <InformacionGeneral />
                    <Box display="flex" justifyContent="end" marginTop={isNonMobile ? "10px" : "14px"}>
                      <Button
                        onClick={handleNextTab}
                        color="secondary"
                        variant="contained"
                        endIcon={<EastOutlinedIcon />}
                        disabled={
                         // !isCarreraSelected ||
                          !isFacultadSelected ||
                          valuesNombrePrograma.length === 0 ||
                          valuesResponsablePrograma.length === 0 ||
                          valuesFechaInicio === null ||
                          valuesFechaTermino === null ||
                          selectedFuenteFinanciamiento.length === 0
                        }
                      >
                        Siguiente
                      </Button>
                    </Box>
                  </TabPanel>
                  <TabPanel value={2}>
                    <CoherenciaInterna />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                            !isAmbitoSelected ||
                            !isLineaDeAccionSelected ||
                            selectedFocoPrioritario.length === 0 ||
                            selectedCicloFormativo.length === 0 ||
                            ((selectedLineaDeAccionRows.includes(1) ||
                              selectedLineaDeAccionRows.includes(4)) &&
                              !isLineaSubSelected) ||
                            (isLineaSubSelected &&
                              selectedLineaSubRows.includes(2) &&
                              !hasNoRows &&
                              !isAsignaturaSelected)
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={3}>
                    <ArticulacionPEI />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={selectedPEI.length === 0}
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={4}>
                    <AlcanceGrupoInteresRelevante />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                            selectedAlcanceTerritorial.length === 0 ||
                            selectedPais.length === 0 ||
                            selectedRegion.length === 0 ||
                            selectedCiudad.length === 0 ||
                            valuesDireccion.length === 0 ||
                            selectedGrupoInteresRelevante.length === 0
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={5}>
                    <GestionDeLaAccion />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={selectedEtapaParticipa.length === 0}
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={6}>
                    <DetalleDeLaAccion />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                            valuesDiagnostico.length === 0 ||
                            !isRowObjetivoAdded ||
                            valuesResultadoEsperado.length === 0
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={7}>
                    <Actividades />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={8}>
                    <ParticipacionEsperada />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                            selectedTipoParticipantes.length === 0 ||
                            selectedParticipantesEsperados.length === 0
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={9}>
                    <Contribucion />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={
                            selectedContribucionInterna.length === 0 &&
                            selectedContribucionExterna.length === 0
                          }
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>

                  <TabPanel value={10}>
                    <ODS />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={!isODSSelected}
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={11}>
                    <InstrumentoDeEvaluacion />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          onClick={handleNextTab}
                          color="secondary"
                          variant="contained"
                          endIcon={<EastOutlinedIcon />}
                          disabled={!isInstrumentoEvaluacionSelected}
                        >
                          Siguiente
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel value={12}>
                    <Presupuesto />
                    <Box marginTop={isNonMobile ? "10px" : "14px"}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          onClick={handlePrevTab}
                          color="secondary"
                          variant="contained"
                          startIcon={<WestOutlinedIcon />}
                        >
                          Anterior
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={onClickFinalizar}
                          disabled={
                            valuesRecursosMateriales.length === 0 ||
                            valuesTransporteAlimentacion.length === 0 ||
                            valuesOtrosRecursos.length === 0 ||
                            valuesTotal === "0"
                          }
                        >
                          Finalizar
                        </Button>
                      </Stack>
                    </Box>
                  </TabPanel>
                </form>
              </Box>
            </TabContext>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default FormularioProyecto;
