import * as React from "react";
import Card from "@mui/material/Card";
import {
  Box,
  Checkbox,
  useTheme,
  Typography,
  CardActionArea,
  CardContent,
} from "@mui/material";
import { tokens } from "../theme";
import { useDataContext } from "../context/DataContext";
import { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

const CardsEval = ({
  evalTitle,
  evalIcon,
  bottomIcon,
  bottomTitle,
  bottomCheckbox,
  titleLeft,
  valueCheckbox,
  evalKey,
  variant,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    isInstrumentoEvaluacionChecked,
    setIsInstrumentoEvaluacionChecked,
    setIsInstrumentoEvaluacionSelected,
    setSelectedInstrumentoEvaluacion } =
    useDataContext();
 console.log(isInstrumentoEvaluacionChecked)
  const isNonMobile = useMediaQuery("(min-width:1920px)");

  const isChecked = isInstrumentoEvaluacionChecked[evalKey] || false;

  // Listen for changes in the shared state whenever it changes
  useEffect(() => {
    // Calculate the overall selection status based on the isODSchecked object
    const anyChecked = Object.values(isInstrumentoEvaluacionChecked).some(
      (value) => value
    );
    setIsInstrumentoEvaluacionSelected(anyChecked);

    // Update setIsODSSelected based on the overall selection status
  }, [isInstrumentoEvaluacionChecked, setIsInstrumentoEvaluacionSelected]);

  useEffect(() => {
    const selectedKeys = Object.keys(isInstrumentoEvaluacionChecked).filter(
      (key) => isInstrumentoEvaluacionChecked[key]
    );
    setSelectedInstrumentoEvaluacion(selectedKeys);
  }, [isInstrumentoEvaluacionChecked, setSelectedInstrumentoEvaluacion]);

  // useEffect(() => {
  //   console.log("Selected ods: ", selectedInstrumentoEvaluacion);
  // }, [selectedInstrumentoEvaluacion]);

  const handleChange = () => {
    // Update the shared state for the specific odsKey
    setIsInstrumentoEvaluacionChecked((prev) => ({
      ...prev,
      [evalKey]: !isChecked,
    }));
  };

  return (
    <Card
      sx={{
        width: isNonMobile ? "180px" : "160px",
        height: isNonMobile ? "180px" : "160px",
        border: "2px solid",
        borderColor: isChecked ? colors.orangeAccent[400] : colors.grey[500],
        transition: "transform 0.20s ease-in-out",
        "&:hover": {
          transform: "scale3d(1.20, 1.20, 1.20)",
          zIndex: 1,
          borderColor: colors.orangeAccent[400],
        },
      }}
    >
      <CardActionArea
        onClick={handleChange}
        sx={{
          "& .MuiCardActionArea-focusHighlight": { background: "transparent" },
        }}
      >
        <CardContent>
          <Box
            display="flex"
            position="relative"
            left={titleLeft}
            bottom={bottomTitle}
          >
            <Typography
              variant={variant}
              fontWeight="bold"
              color={colors.grey[100]}
              align="center"
            >
              {evalTitle}
            </Typography>
          </Box>
          <Box
            display="flex"
            position="relative"
            justifyContent="center"
            bottom={bottomIcon}
          >
            {evalIcon}
          </Box>
          <Box
            display="flex"
            position="relative"
            bottom={bottomCheckbox}
            right="13px"
          >
            <Checkbox
              disableRipple
              color="default"
              style={{ color: colors.grey[100] }}
              checked={isChecked}
              value={valueCheckbox}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardsEval;
