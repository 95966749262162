import { useTheme } from "@mui/material";
import { ResponsiveChoropleth } from "@nivo/geo";
import { geoFeatures } from "../data/mockGeoFeatures";
import { scaleQuantize } from "d3-scale";
import { tokens } from "../theme";

// Transforma la data para que sea compatible con el gráfico
const transformData = (data) => {
  if(data)
  {return data.map(item => ({
    id: item.descripcion, // Asegúrate de que 'descripcion' coincide con los nombres de los países en geoFeatures
    value: item.NumeroProyectos + item.NumeroProgramas + item.NumeroActividades + item.NumeroIniciativas,
    Proyectos:item.NumeroProyectos,
    Programas:item.NumeroProgramas,
    Actividades:item.NumeroActividades,
    Iniciativas:item.NumeroIniciativas
  }));}else{
    return [];
  }
};

const GeographyChart = ({ data, isDashboard = false }) => {
  console.log(data)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const transformedData = transformData(data);
  const maxValue = Math.max(...transformedData.map(d => d.value));
 
  const getColor = scaleQuantize().domain([1]).range(["#ededed", "orange"]);
  return (
    <ResponsiveChoropleth
      data={transformedData}
      colors={getColor}
      features={geoFeatures.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      domain={[0, maxValue]}
      unknownColor="#d3cece"
      label="properties.name"
      valueFormat=".2s"
      projectionScale={130}
      projectionTranslation={[0.5, 0.5]}
      projectionRotation={[0, 0, 0]}
      borderWidth={0.5}
      borderColor={{ theme: "background" }}
      tooltip={({ feature }) => {
        const countryData = transformedData.find(d => d.id === feature.id);
        return countryData ? (
          <div style={{ color: colors.grey[100], backgroundColor: colors.primary[400], padding: "5px", borderRadius: "5px" }}>
            <strong>{feature.properties.name}</strong>
            <br />
            <strong>Total: {countryData.value}</strong>
            <br />
            Proyectos: {countryData.Proyectos}
            <br />
            Programas: {countryData.Programas}
            <br />
            Actividades: {countryData.Actividades}
            <br />
            Iniciativa: {countryData.Iniciativas}
          </div>
        ) : (
          <div style={{ color: colors.grey[100], backgroundColor: colors.primary[400], padding: "5px", borderRadius: "5px" }}>
            <strong>{feature.properties.name}</strong>
            <br />
            Cantidad: 0
          </div>
        );
      }}
    />
  );
};

export default GeographyChart;