import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Breadcrump from "../../components/Breadcrump";
import PopupFS from "../../components/PopupFS";
import { useState } from "react";
import FormularioPrograma from "../../forms/FormularioPrograma";
import FormularioProyecto from "../../forms/FormularioProyecto";
import FormularioActividad from "../../forms/FormularioActividad";
import FormularioIniciativa from "../../forms/FormularioIniciativa";
import TableAcciones from "../../tables/TableAcciones";
import { DataProviderActividadP } from "../../context/DataContextActividadP";
import { ToastContainer } from "react-toastify";
import { useDataContext } from "../../context/DataContext";
import "react-toastify/dist/ReactToastify.css";
import BackdropLoading from "../../components/Backdrop";
import useMediaQuery from "@mui/material/useMediaQuery";
import verActividadFetch from "../../api/revision/verActividadFetch";
import verIniciativaFetch from "../../api/revision/verIniciativaFetch";
import verProgramaFetch from "../../api/revision/verProgramaFetch";
import verProyectoFetch from "../../api/revision/verProyectoFetch";
import aprobarPrograma from "../../api/revision/aprobarPrograma";
import aprobarProyecto from "../../api/revision/aprobarProyecto";
import aprobarActividad from "../../api/revision/aprobarActividad";
import aprobarIniciativa from "../../api/revision/aprobarIniciativa";
import rechazarPrograma from "../../api/revision/rechazarPrograma";
import rechazarProyecto from "../../api/revision/rechazarProyecto";
import rechazarActividad from "../../api/revision/rechazarActividad";
import rechazarIniciativa from "../../api/revision/rechazarIniciativa";
import PopupRevision from "../../components/PopupRevision";
import Popup from "../../components/Popup";
import Retroalimentacion from "../../components/Retroalimentacion";

const Revision = () => {
  const [openPopupVerPrograma, setOpenPopupVerPrograma] = useState(false);
  const [openPopupVerProyecto, setOpenPopupVerProyecto] = useState(false);
  const [openPopupVerActividad, setOpenPopupVerActividad] = useState(false);
  const [openPopupVerIniciativa, setOpenPopupVerIniciativa] = useState(false);
  const [openPopupEvaluarPrograma, setOpenPopupEvaluarPrograma] =
    useState(false);
  const [openPopupEvaluarProyecto, setOpenPopupEvaluarProyecto] =
    useState(false);
  const [openPopupEvaluarActividad, setOpenPopupEvaluarActividad] =
    useState(false);
  const [openPopupEvaluarIniciativa, setOpenPopupEvaluarIniciativa] =
    useState(false);

  const [
    openPopupRetroalimentacionPrograma,
    setOpenPopupRetroalimentacionPrograma,
  ] = useState(false);
  const [
    openPopupRetroalimentacionProyecto,
    setOpenPopupRetroalimentacionProyecto,
  ] = useState(false);
  const [
    openPopupRetroalimentacionActividad,
    setOpenPopupRetroalimentacionActividad,
  ] = useState(false);
  const [
    openPopupRetroalimentacionIniciativa,
    setOpenPopupRetroalimentacionIniciativa,
  ] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:1920px)");

  const theme = useTheme();
  const {
    selectedFocoPrioritario,
    selectedCicloFormativo,
    selectedLineaDeAccionRows,
    selectedPEI,
    selectedPais,
    selectedRegion,
    selectedCiudad,
    selectedAlcanceTerritorial,
    selectedGrupoInteresRelevante,
    selectedEtapaParticipa,
    selectedParticipantesEsperados,
    selectedTipoParticipantes,
    selectedContribucionInterna,
    selectedContribucionExterna,
    selectedFacultadRows,
    selectedCarreraRows,
    selectedAmbitoRows,
    selectedLineaSubRows,
    selectedAsignaturaRows,
    selectedActorParticipa,
    selectedODS,
    selectedInstrumentoEvaluacion,
    getPrograma,
    rowProgramaID,
    existingFuenteFinanciamiento,
    existingFacultad,
    existingCarrera,
    existingAmbito,
    existingLineaAccion,
    existingLineaSub,
    existingAsignatura,
    existingCicloFormativo,
    existingPEI,
    existingAlcanceTerritorial,
    existingGrupoInteresRelevante,
    existingEtapaParticipa,
    existingTipoParticipantes,
    existingContribucionInterna,
    existingContribucionExterna,
    rowPairsObjetivo,
    existingODS,
    existingInstrumentoEvaluacion,
    valuesNombrePrograma,
    valuesResponsablePrograma,
    valuesDiagnostico,
    valuesDireccion,
    valuesRecursosMateriales,
    valuesTransporteAlimentacion,
    valuesOtrosRecursos,
    valuesTotal,
    valuesResultadoEsperado,
    selectedFuenteFinanciamiento,
    setSelectedFacultadRows,
    setValuesNombrePrograma,
    setValuesResponsablePrograma,
    setValuesDireccion,
    setValuesDiagnostico,
    setValuesRecursosMateriales,
    setValuesTransporteAlimentacion,
    setValuesOtrosRecursos,
    setValuesTotal,
    setValuesResultadoEsperado,
    setSelectedCarreraRows,
    setIsFacultadSelected,
    setIsCarreraSelected,
    setSelectedFuenteFinanciamiento,
    setSelectedCicloFormativo,
    setSelectedFocoPrioritario,
    setSelectedAmbitoRows,
    setIsAmbitoSelected,
    setSelectedLineaSubRows,
    setSelectedLineaDeAccionRows,
    setIsLineaDeAccionSelected,
    setIsLineaSubSelected,
    setSelectedAsignaturaRows,
    setIsAsignaturaSelected,
    setHasNoRows,
    setSelectedPEI,
    setSelectedAlcanceTerritorial,
    setSelectedPais,
    setSelectedRegion,
    setSelectedCiudad,
    setSelectedGrupoInteresRelevante,
    setSelectedActorParticipa,
    setSelectedEtapaParticipa,
    setSelectedTipoParticipantes,
    setSelectedParticipantesEsperados,
    setSelectedContribucionInterna,
    setSelectedContribucionExterna,
    setRowPairsObjetivo,
    setIsODSchecked,
    setIsInstrumentoEvaluacionChecked,
    setIsRowObjetivoAdded,
    setRowProgramaID,
    setExistingFuenteFinanciamiento,
    setExistingFacultad,
    setExistingCarrera,
    setExistingAmbito,
    setExistingLineaAccion,
    setExistingLineaSub,
    setExistingAsignatura,
    setExistingCicloFormativo,
    setExistingPEI,
    setExistingAlcanceTerritorial,
    setExistingGrupoInteresRelevante,
    setExistingEtapaParticipa,
    setExistingTipoParticipantes,
    setExistingContribucionInterna,
    setExistingContribucionExterna,
    existingRowPairs,
    setExistingRowPairs,
    setExistingODS,
    setExistingInstrumentoEvaluacion,
    setIdObjetivoGeneral,
    setSelectedODS,
    setSelectedInstrumentoEvaluacion,
    setValuesFechaInicio,
    setValuesFechaTermino,
    setValuesObjetivoGeneralActividad,
    setValuesBreveDescripcionActividad,
    valuesFechaInicio,
    valuesFechaTermino,
    getProyecto,
    valuesObjetivoGeneralActividad,
    valuesBreveDescripcionActividad,
    getActividad,
    getIniciativa,
    valuesRetroalimentacion,
    setValuesRetroalimentacion,
    getRetroalimentacion,
    valuesIdUsuario,
    setValuesIdUsuario,
    valuesMail,
    setValuesMail,
    valuesUsername,
    setValuesUsername,
    usernameTemp,
    setUsernameTemp,
    nombreProgramaTemp,
    setNombreProgramaTemp,
    idProgramaTemp,
    setIdProgramaTemp,
    responsableProgramaTemp,
    setResponsableProgramaTemp,
    mailTemp,
    setMailTemp,
    rowPairs, setRowPairs
  } = useDataContext();

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  const clearValues = () => {
    setValuesNombrePrograma("");
    setValuesResponsablePrograma("");
    setSelectedFacultadRows([]);
    setSelectedCarreraRows([]);
    setSelectedFuenteFinanciamiento([]);
    setSelectedCicloFormativo([]);
    setSelectedFocoPrioritario("1");
    setSelectedAmbitoRows([]);
    setSelectedLineaDeAccionRows([]);
    setSelectedLineaSubRows([]);
    setSelectedAsignaturaRows([]);
    setSelectedPEI([]);
    setSelectedAlcanceTerritorial([]);
    setSelectedPais("44");
    setSelectedRegion("2824");
    setSelectedCiudad("148523");
    setValuesDireccion("");
    setSelectedGrupoInteresRelevante([]);
    setSelectedActorParticipa([1]);
    setSelectedEtapaParticipa([]);
    setValuesDiagnostico("");
    setRowPairsObjetivo([]);
    setRowPairs([]);
    setIdObjetivoGeneral([]);
    setValuesResultadoEsperado("");
    setSelectedTipoParticipantes([]);
    setSelectedParticipantesEsperados("1");
    setSelectedContribucionInterna([]);
    setSelectedContribucionExterna([]);
    setIsODSchecked(false);
    setSelectedODS([]);
    setIsInstrumentoEvaluacionChecked(false);
    setSelectedInstrumentoEvaluacion([]);
    setValuesRecursosMateriales("");
    setValuesTransporteAlimentacion("");
    setValuesOtrosRecursos("");
    setValuesTotal("");
    setIsFacultadSelected(false);
    setIsCarreraSelected(false);
    setIsAmbitoSelected(false);
    setIsLineaDeAccionSelected(false);
    setIsLineaSubSelected(false);
    setIsAsignaturaSelected(false);
    setIsRowObjetivoAdded(false);
    setHasNoRows(true);
  };

  const clearValuesTemp = () => {
    setUsernameTemp([]);
    setNombreProgramaTemp("");
    setIdProgramaTemp("");
    setResponsableProgramaTemp("");
    setValuesRetroalimentacion("");
    setMailTemp([]);
  };

  const handleVerPrograma = async (rowID) => {
    setOpenBackdrop(true);
    await verProgramaFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setRowPairsObjetivo,
      setRowPairs,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setIsRowObjetivoAdded,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      existingRowPairs,
      setExistingRowPairs,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setSelectedODS,
      setSelectedInstrumentoEvaluacion,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupVerPrograma(true);
  };

  const handleVerProyecto = async (rowID) => {
    setOpenBackdrop(true);
    await verProyectoFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setRowPairsObjetivo,
      setRowPairs,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setIsRowObjetivoAdded,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      existingRowPairs,
      setExistingRowPairs,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setSelectedODS,
      setSelectedInstrumentoEvaluacion,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupVerProyecto(true);
  };

  const handleVerActividad = async (rowID) => {
    setOpenBackdrop(true);
    await verActividadFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedODS,
      setSelectedInstrumentoEvaluacion,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupVerActividad(true);
  };

  const handleVerIniciativa = async (rowID) => {
    setOpenBackdrop(true);
    await verIniciativaFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedODS,
      setSelectedInstrumentoEvaluacion,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupVerIniciativa(true);
  };

  const handlePopupVerProgramaClose = () => {
    setOpenPopupVerPrograma(false);
    clearValues();
  };

  const handlePopupVerProyectoClose = () => {
    setOpenPopupVerProyecto(false);
    clearValues();
  };

  const handlePopupVerActividadClose = () => {
    setOpenPopupVerActividad(false);
    clearValues();
  };

  const handlePopupVerIniciativaClose = () => {
    setOpenPopupVerIniciativa(false);
    clearValues();
  };

  const handlePopupOpenEvaluarPrograma = async (rowID) => {
    setOpenBackdrop(true);
    await verProgramaFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setRowPairsObjetivo,
      setRowPairs,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setIsRowObjetivoAdded,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      existingRowPairs,
      setExistingRowPairs,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setSelectedODS,
      setSelectedInstrumentoEvaluacion,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupEvaluarPrograma(true);
  };

  const handlePopupEvaluarProgramaClose = () => {
    clearValues();
    setOpenPopupEvaluarPrograma(false);
  };

  const handlePopupOpenEvaluarProyecto = async (rowID) => {
    setOpenBackdrop(true);
    await verProyectoFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setRowPairsObjetivo,
      setRowPairs,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setIsRowObjetivoAdded,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      existingRowPairs,
      setExistingRowPairs,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setSelectedODS,
      setSelectedInstrumentoEvaluacion,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupEvaluarProyecto(true);
  };

  const handlePopupEvaluarProyectoClose = () => {
    clearValues();
    setOpenPopupEvaluarProyecto(false);
  };

  const handlePopupOpenEvaluarActividad = async (rowID) => {
    setOpenBackdrop(true);
    await verActividadFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedODS,
      setSelectedInstrumentoEvaluacion,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupEvaluarActividad(true);
  };

  const handlePopupEvaluarActividadClose = () => {
    clearValues();
    setOpenPopupEvaluarActividad(false);
  };

  const handlePopupOpenEvaluarIniciativa = async (rowID) => {
    setOpenBackdrop(true);
    await verIniciativaFetch(
      rowID,
      setSelectedFacultadRows,
      setValuesNombrePrograma,
      setValuesResponsablePrograma,
      setValuesDireccion,
      setValuesDiagnostico,
      setValuesRecursosMateriales,
      setValuesTransporteAlimentacion,
      setValuesOtrosRecursos,
      setValuesTotal,
      setValuesResultadoEsperado,
      setSelectedCarreraRows,
      setIsFacultadSelected,
      setIsCarreraSelected,
      setSelectedFuenteFinanciamiento,
      setSelectedCicloFormativo,
      setSelectedFocoPrioritario,
      setSelectedAmbitoRows,
      setIsAmbitoSelected,
      setSelectedLineaSubRows,
      setSelectedLineaDeAccionRows,
      setIsLineaDeAccionSelected,
      setIsLineaSubSelected,
      setSelectedAsignaturaRows,
      setIsAsignaturaSelected,
      setHasNoRows,
      setSelectedPEI,
      setSelectedAlcanceTerritorial,
      setSelectedPais,
      setSelectedRegion,
      setSelectedCiudad,
      setSelectedGrupoInteresRelevante,
      setSelectedActorParticipa,
      setSelectedEtapaParticipa,
      setSelectedTipoParticipantes,
      setSelectedParticipantesEsperados,
      setSelectedContribucionInterna,
      setSelectedContribucionExterna,
      setIsODSchecked,
      setIsInstrumentoEvaluacionChecked,
      setRowProgramaID,
      setExistingFuenteFinanciamiento,
      setExistingFacultad,
      setExistingCarrera,
      setExistingAmbito,
      setExistingLineaAccion,
      setExistingLineaSub,
      setExistingAsignatura,
      setExistingCicloFormativo,
      setExistingPEI,
      setExistingAlcanceTerritorial,
      setExistingGrupoInteresRelevante,
      setExistingEtapaParticipa,
      setExistingTipoParticipantes,
      setExistingContribucionInterna,
      setExistingContribucionExterna,
      setExistingODS,
      setExistingInstrumentoEvaluacion,
      setValuesFechaInicio,
      setValuesFechaTermino,
      setValuesObjetivoGeneralActividad,
      setValuesBreveDescripcionActividad,
      setSelectedODS,
      setSelectedInstrumentoEvaluacion,
      setValuesIdUsuario,
      setValuesMail,
      setValuesUsername,
      setUsernameTemp,
      setNombreProgramaTemp,
      setIdProgramaTemp,
      setResponsableProgramaTemp,
      setMailTemp
    );
    setOpenBackdrop(false);
    setOpenPopupEvaluarIniciativa(true);
  };

  const handlePopupEvaluarIniciativaClose = () => {
    clearValues();
    setOpenPopupEvaluarIniciativa(false);
  };

  const handleAprobarPrograma = async () => {
    setOpenPopupEvaluarPrograma(false);
    setOpenBackdrop(true);
    await aprobarPrograma(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getPrograma,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingRowPairs,
      rowPairsObjetivo,
      existingODS,
      existingInstrumentoEvaluacion,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handleAprobarProyecto = async () => {
    setOpenPopupEvaluarProyecto(false);
    setOpenBackdrop(true);
    await aprobarProyecto(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getProyecto,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingRowPairs,
      rowPairsObjetivo,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handleAprobarActividad = async () => {
    setOpenPopupEvaluarActividad(false);
    setOpenBackdrop(true);
    await aprobarActividad(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getActividad,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handleAprobarIniciativa = async () => {
    setOpenPopupEvaluarIniciativa(false);
    setOpenBackdrop(true);
    await aprobarIniciativa(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getIniciativa,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handleRechazarPrograma = async () => {
    setOpenPopupRetroalimentacionPrograma(false);
    setOpenBackdrop(true);
    await rechazarPrograma(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getPrograma,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingRowPairs,
      rowPairsObjetivo,
      existingODS,
      existingInstrumentoEvaluacion,
      clearValues,
      setOpenBackdrop,
      valuesRetroalimentacion,
      theme,
      getRetroalimentacion,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handleRechazarProyecto = async () => {
    setOpenPopupRetroalimentacionProyecto(false);
    setOpenBackdrop(true);
    await rechazarProyecto(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getProyecto,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingRowPairs,
      rowPairsObjetivo,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesRetroalimentacion,
      getRetroalimentacion,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handleRechazarActividad = async () => {
    setOpenPopupRetroalimentacionActividad(false);
    setOpenBackdrop(true);
    await rechazarActividad(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getActividad,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesRetroalimentacion,
      getRetroalimentacion,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handleRechazarIniciativa = async () => {
    setOpenPopupRetroalimentacionIniciativa(false);
    setOpenBackdrop(true);
    await rechazarIniciativa(
      valuesNombrePrograma,
      valuesResponsablePrograma,
      valuesDiagnostico,
      valuesDireccion,
      valuesRecursosMateriales,
      valuesTransporteAlimentacion,
      valuesOtrosRecursos,
      valuesTotal,
      valuesResultadoEsperado,
      selectedFuenteFinanciamiento,
      selectedFocoPrioritario,
      selectedCicloFormativo,
      selectedLineaDeAccionRows,
      selectedPEI,
      selectedPais,
      selectedRegion,
      selectedCiudad,
      selectedAlcanceTerritorial,
      selectedGrupoInteresRelevante,
      selectedEtapaParticipa,
      selectedParticipantesEsperados,
      selectedTipoParticipantes,
      selectedContribucionInterna,
      selectedContribucionExterna,
      selectedFacultadRows,
      selectedCarreraRows,
      selectedAmbitoRows,
      selectedLineaSubRows,
      selectedAsignaturaRows,
      selectedActorParticipa,
      selectedODS,
      selectedInstrumentoEvaluacion,
      getIniciativa,
      rowProgramaID,
      existingFuenteFinanciamiento,
      existingFacultad,
      existingCarrera,
      existingAmbito,
      existingLineaAccion,
      existingLineaSub,
      existingAsignatura,
      existingCicloFormativo,
      existingPEI,
      existingAlcanceTerritorial,
      existingGrupoInteresRelevante,
      existingEtapaParticipa,
      existingTipoParticipantes,
      existingContribucionInterna,
      existingContribucionExterna,
      existingODS,
      existingInstrumentoEvaluacion,
      valuesFechaInicio,
      valuesFechaTermino,
      valuesObjetivoGeneralActividad,
      valuesBreveDescripcionActividad,
      clearValues,
      setOpenBackdrop,
      theme,
      valuesRetroalimentacion,
      getRetroalimentacion,
      valuesIdUsuario,
      valuesMail,
      valuesUsername,
      usernameTemp,
      nombreProgramaTemp,
      idProgramaTemp,
      responsableProgramaTemp,
      mailTemp
    );
    clearValuesTemp();
  };

  const handlePopupRetroalimentacionProgramaOpen = () => {
    setOpenPopupEvaluarPrograma(false);
    setOpenPopupRetroalimentacionPrograma(true);
  };

  const handlePopupRetroalimentacionProgramaClose = () => {
    setOpenPopupRetroalimentacionPrograma(false);
  };

  const handlePopupRetroalimentacionProyectoOpen = () => {
    setOpenPopupEvaluarProyecto(false);
    setOpenPopupRetroalimentacionProyecto(true);
  };

  const handlePopupRetroalimentacionProyectoClose = () => {
    setOpenPopupRetroalimentacionProyecto(false);
  };

  const handlePopupRetroalimentacionActividadOpen = () => {
    setOpenPopupEvaluarActividad(false);
    setOpenPopupRetroalimentacionActividad(true);
  };

  const handlePopupRetroalimentacionActividadClose = () => {
    setOpenPopupRetroalimentacionActividad(false);
  };

  const handlePopupRetroalimentacionIniciativaOpen = () => {
    setOpenPopupEvaluarIniciativa(false);
    setOpenPopupRetroalimentacionIniciativa(true);
  };

  const handlePopupRetroalimentacionIniciativaClose = () => {
    setOpenPopupRetroalimentacionIniciativa(false);
  };

  const onChangeRetroalimentacion = (e) => {
    const newValue = e.target.value;
    setValuesRetroalimentacion(newValue);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      margin={isNonMobile ? "0px 20px 20px 20px" : "0px 20px 20px 20px"}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Revisión" subtitle="Lista de acciones por revisar" />
        <Breadcrump page="Revisión" />
      </Box>
      <Box>
        <TableAcciones
          onClickVerPrograma={handleVerPrograma}
          onClickVerProyecto={handleVerProyecto}
          onClickVerActividad={handleVerActividad}
          onClickVerIniciativa={handleVerIniciativa}
          onClickEvaluarPrograma={handlePopupOpenEvaluarPrograma}
          onClickEvaluarProyecto={handlePopupOpenEvaluarProyecto}
          onClickEvaluarActividad={handlePopupOpenEvaluarActividad}
          onClickEvaluarIniciativa={handlePopupOpenEvaluarIniciativa}
        />
      </Box>
      <PopupFS
        open={openPopupVerPrograma}
        onClose={handlePopupVerProgramaClose}
      >
        <DataProviderActividadP>
          <FormularioPrograma onClickFinalizar={handlePopupVerProgramaClose} />
        </DataProviderActividadP>
      </PopupFS>
      <PopupFS
        open={openPopupVerProyecto}
        onClose={handlePopupVerProyectoClose}
      >
        <DataProviderActividadP>
          <FormularioProyecto onClickFinalizar={handlePopupVerProyectoClose} />
        </DataProviderActividadP>
      </PopupFS>
      <PopupFS
        open={openPopupVerActividad}
        onClose={handlePopupVerActividadClose}
      >
        <DataProviderActividadP>
          <FormularioActividad
            onClickFinalizar={handlePopupVerActividadClose}
          />
        </DataProviderActividadP>
      </PopupFS>
      <PopupFS
        open={openPopupVerIniciativa}
        onClose={handlePopupVerIniciativaClose}
      >
        <DataProviderActividadP>
          <FormularioIniciativa
            onClickFinalizar={handlePopupVerIniciativaClose}
          />
        </DataProviderActividadP>
      </PopupFS>
      <PopupRevision
        open={openPopupEvaluarPrograma}
        handleSubmitNo={handlePopupRetroalimentacionProgramaOpen}
        handleSubmitSi={handleAprobarPrograma}
        title="¿Como desea evaluar este programa?"
        onClose={handlePopupEvaluarProgramaClose}
      >
        Recuerde que al rechazar debera especificar una retroalimentación al
        programa.
      </PopupRevision>
      <PopupRevision
        open={openPopupEvaluarProyecto}
        handleSubmitNo={handlePopupRetroalimentacionProyectoOpen}
        handleSubmitSi={handleAprobarProyecto}
        title="¿Como desea evaluar este proyecto?"
        onClose={handlePopupEvaluarProyectoClose}
      >
        Recuerde que al rechazar debera especificar una retroalimentación al
        proyecto.
      </PopupRevision>
      <PopupRevision
        open={openPopupEvaluarActividad}
        handleSubmitNo={handlePopupRetroalimentacionActividadOpen}
        handleSubmitSi={handleAprobarActividad}
        title="¿Como desea evaluar esta actividad?"
        onClose={handlePopupEvaluarActividadClose}
      >
        Recuerde que al rechazar debera especificar una retroalimentación a la
        actividad.
      </PopupRevision>
      <PopupRevision
        open={openPopupEvaluarIniciativa}
        handleSubmitNo={handlePopupRetroalimentacionIniciativaOpen}
        handleSubmitSi={handleAprobarIniciativa}
        title="¿Como desea evaluar esta iniciativa?"
        onClose={handlePopupEvaluarIniciativaClose}
      >
        Recuerde que al rechazar debera especificar una retroalimentación a la
        iniciativa.
      </PopupRevision>

      <Popup
        open={openPopupRetroalimentacionPrograma}
        onClose={handlePopupRetroalimentacionProgramaClose}
        buttonName={"Enviar"}
        handleSubmit={handleRechazarPrograma}
        maxWidth={"lg"}
        disabled={valuesRetroalimentacion.length === 0}
        title={"Ingrese la retroalimentación para el formulario"}
      >
        <Retroalimentacion
          nombreAccion={"Retroalimentación del programa"}
          onChangeRetroalimentacion={onChangeRetroalimentacion}
          valuesRetroalimentacion={valuesRetroalimentacion}
          readonly={false}
        />
      </Popup>
      <Popup
        open={openPopupRetroalimentacionProyecto}
        onClose={handlePopupRetroalimentacionProyectoClose}
        buttonName={"Enviar"}
        handleSubmit={handleRechazarProyecto}
        maxWidth={"lg"}
        disabled={valuesRetroalimentacion.length === 0}
        title={"Ingrese la retroalimentación para el formulario"}
      >
        <Retroalimentacion
          nombreAccion={"Retroalimentación del proyecto"}
          onChangeRetroalimentacion={onChangeRetroalimentacion}
          valuesRetroalimentacion={valuesRetroalimentacion}
          readonly={false}
        />
      </Popup>
      <Popup
        open={openPopupRetroalimentacionActividad}
        onClose={handlePopupRetroalimentacionActividadClose}
        buttonName={"Enviar"}
        handleSubmit={handleRechazarActividad}
        maxWidth={"lg"}
        disabled={valuesRetroalimentacion.length === 0}
        title={"Ingrese la retroalimentación para el formulario"}
      >
        <Retroalimentacion
          nombreAccion={"Retroalimentación de la actividad"}
          onChangeRetroalimentacion={onChangeRetroalimentacion}
          valuesRetroalimentacion={valuesRetroalimentacion}
          readonly={false}
        />
      </Popup>
      <Popup
        open={openPopupRetroalimentacionIniciativa}
        onClose={handlePopupRetroalimentacionIniciativaClose}
        buttonName={"Enviar"}
        handleSubmit={handleRechazarIniciativa}
        maxWidth={"lg"}
        disabled={valuesRetroalimentacion.length === 0}
        title={"Ingrese la retroalimentación para el formulario"}
      >
        <Retroalimentacion
          nombreAccion={"Retroalimentación de la iniciativa"}
          onChangeRetroalimentacion={onChangeRetroalimentacion}
          valuesRetroalimentacion={valuesRetroalimentacion}
          readonly={false}
        />
      </Popup>

      <ToastContainer
        toastStyle={{
          ...(theme.palette.mode === "dark" && { backgroundColor: "#292929" }),
        }}
      />
      <BackdropLoading open={openBackdrop} handleClose={handleBackdropClose} />
    </Box>
  );
};

export default Revision;
