import { Box, Typography } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";


const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <Box
      display="flex"
      height="80vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center">
        <img
          src="../../assets/pictures/pngwing.com.png"
          style={{
            width: "300px",
            height: "300px",
            objectFit: "contain",
          }}
          alt="construccion"
        />
        <Typography
          variant="h2"
          fontWeight="bold"
          color={colors.orangeAccent[500]}
        >
          Sitio en construcción
        </Typography>
      </Box>
    </Box>
    );
}

export default Team;

