import React, { useState, useEffect } from "react";
import { Box, Button, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Breadcrump from "../../components/Breadcrump";
import TableSeguimiento from "../../tables/TableSeguimiento";
import Cookies from "universal-cookie";

const FormPrograma = () => {
  const [seguimiento, setSeguimiento] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    fetchActividades();
  }, []);

  const fetchActividades = async () => {
    const cookies = new Cookies();
    const myAuthCookie = cookies.get("MyAuthCookie");
    const cookieString = Object.entries(myAuthCookie)
      .map(
        ([key, value]) =>
          `${key}=${encodeURIComponent(JSON.stringify(value))}`
      )
      .join("; ");

    try {
      const response = await fetch(
        "https://vime.usek.cl/api/seguimientoactividades", {
        headers: {
          userdata: cookieString,
        },
      }
      );
      const data = await response.json();
      setSeguimiento(data);
    } catch (error) {
      console.error("Error fetching actividades:", error);
    }
  };

  const handleCrearEvaluacion = (actividadId) => {
    // Lógica para crear evaluación
    console.log("Crear evaluación para actividad:", actividadId);
  };

  return (
    <Box margin="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="REPORTE ESTADO ACTIVIDADES"
          subtitle="Lista de actividades"
        />
        <Breadcrump page="seguimiento" />
      </Box>
      <Box>
        <TableSeguimiento
          seguimiento={seguimiento}
          onCrearEvaluacion={handleCrearEvaluacion}
        />
      </Box>
    </Box>
  );
};

export default FormPrograma;
