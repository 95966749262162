import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";
import EmailIcon from "@mui/icons-material/Email";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import GeographyChart from "../../components/GeographyChart";
import GeographyChartMetro from "../../components/GeographyChartMetro";
import TableFacultad from "../../tables/TableFacultad";
import TableCarreras from "../../tables/TableCarreras";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Popup from "../../components/Popup";
import BarChart from "../../components/BarChart";
import PieChart from "../../components/PieChart";
import { useDataContext } from "../../context/DataContext";
import axios from "axios";
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null);
  const [totals, setTotals] = useState(null);
  const [openPopupFacultad, setOpenPopupFacultad] = useState(false);
  const [openPopupCarreras, setOpenPopupCarreras] = useState(false);
  const [selectedAno, setSelectedAno] = useState([]);
  const [selectedSemestre, setSelectedSemestre] = useState([]);
  const [selectedBar, setSelectedBar] = useState(null);

  const {
    facultad,
    carrera,
    selectedFacultadRows,
    setSelectedFacultadRows,
    selectedCarreraRows,
    setSelectedCarreraRows,
    isFacultadSelected,
    setIsFacultadSelected,
    isCarreraSelected,
    setIsCarreraSelected,
    inicio,
    setInicio,
    fin,
    setFin,
    periodos,
    setperiodos,
  } = useDataContext();
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const handlePopupOpenFacultad = () => {
    setOpenPopupFacultad(true);
  };
  const handleAnoChange = (event) => {
    setSelectedAno(event.target.value);
  };

  const handleSemestreChange = (event) => {
    setSelectedSemestre(event.target.value);
  };
  const handlePopupSubmitFacultad = (selectedFacultadRows) => {
    setSelectedFacultadRows(selectedFacultadRows);
    console.log("Facultades seleccionadas: ", selectedFacultadRows);
    setOpenPopupFacultad(false);
    if (selectedFacultadRows.length > 0) {
      setIsFacultadSelected(true);
    } else {
      setIsFacultadSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupSubmitCarrera = (selectedCarreraRows) => {
    setSelectedCarreraRows(selectedCarreraRows);
    console.log("Carreras seleccionadas: ", selectedCarreraRows);
    setOpenPopupCarreras(false);
    if (selectedCarreraRows.length > 0) {
      setIsCarreraSelected(true);
    } else {
      setIsCarreraSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupCloseFacultad = (selectedFacultadRows) => {
    setSelectedFacultadRows(selectedFacultadRows);
    setOpenPopupFacultad(false);
    if (selectedFacultadRows.length > 0) {
      setIsFacultadSelected(true);
    } else {
      setIsFacultadSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };

  const handlePopupOpenCarreras = () => {
    setOpenPopupCarreras(true);
  };

  const handleBarClick = (d) => {
    if (d.indexValue in data.SubLineaDeAccion) {
      setSelectedBar(d.indexValue);
    } else {
      setSelectedBar(null);
    }
  };
  const handlePopupCloseCarreras = (selectedCarreraRows) => {
    setSelectedCarreraRows(selectedCarreraRows);
    setOpenPopupCarreras(false);
    if (selectedCarreraRows.length > 0) {
      setIsCarreraSelected(true);
    } else {
      setIsCarreraSelected(false); // Optional: Set to false if length is not greater than 0
    }
  };
  const defaultColor = "hsl(0, 0%, 50%)";
  const convertDataForPieChart = (data) => {
    const total = data.reduce(
      (sum, item) =>
        sum +
        item.NumeroProyectos +
        item.NumeroProgramas +
        item.NumeroActividades +
        item.NumeroIniciativas,
      0
    );
    let datarespuest = data.map((item) => ({
      id: item.descripcion.trim(),
      label: item.descripcion.trim(),
      value: (
        ((item.NumeroProyectos +
          item.NumeroProgramas +
          item.NumeroActividades +
          item.NumeroIniciativas) /
          total) *
        100
      ).toFixed(0), // Calcular el porcentaje
      color: item.color || defaultColor,
    }));
    return datarespuest;
  };
  const handleFacultadSelectionChange = (newSelectedRows) => {
    setSelectedFacultadRows(newSelectedRows);
    setSelectedCarreraRows([]);
    setIsCarreraSelected(false);
  };
  const handleClearFilters = () => {
    setSelectedFacultadRows([]);
    setSelectedCarreraRows([]);
    setSelectedAno([]);
    setSelectedSemestre([]);
    setIsCarreraSelected(false);
    setIsFacultadSelected(true);
  };
  const handleCarreraSelectionChange = (newSelectedRows) => {
    setSelectedCarreraRows(newSelectedRows);
  };

  const fetchData = async (filters) => {
    try {
      const response = await axios.post(
        "https://vime.usek.cl/api/reporte",
        filters
      );
      const { Data, Totales } = response.data;

      setData(Data);
      setTotals(Totales);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData({
      facultades: selectedFacultadRows ? [selectedFacultadRows] : [],
      carreras: selectedCarreraRows ? [selectedCarreraRows] : [],
      inicio: inicio,
      fin: fin,
      anios: selectedAno,
      semestres: selectedSemestre,
    });
  }, [
    selectedFacultadRows,
    selectedCarreraRows,
    inicio,
    fin,
    selectedAno,
    selectedSemestre,
  ]);

  let total = 0;
  let programasPercentage = 0;
  let proyectosPercentage = 0;
  let actividadesPercentage = 0;
  let iniciativasPercentage = 0;
  const uniqueYears = [...new Set(periodos.map((p) => p.ANOACAD))].sort(
    (a, b) => b - a
  );
  const uniqueSemestre = [...new Set(periodos.map((p) => p.PERIODOACAD))].sort(
    (b, a) => b - a
  );

  if (!data || !totals) {
    return <div>Loading...</div>;
  } else {
    total =
      totals.NumeroProgramas +
      totals.NumeroProyectos +
      totals.NumeroActividades +
      totals.NumeroIniciativas;
    programasPercentage = totals.NumeroProgramas / total;
    proyectosPercentage = totals.NumeroProyectos / total;
    actividadesPercentage = totals.NumeroActividades / total;
    iniciativasPercentage = totals.NumeroIniciativas / total;
  }
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box mb="30px">
          <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            Indicadores Vime
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="rows" gap="40px" marginBottom={2}>
        <Box>
          <Typography
            variant={isNonMobile ? "h5" : "h6"}
            color={colors.grey[100]}
            marginBottom="5px"
          >
            Facultad
          </Typography>
          <TextField
            value={selectedFacultadRows}
            onClick={handlePopupOpenFacultad}
            placeholder="Seleccionar Facultad"
            fullWidth
            readOnly
            sx={{
              "& .MuiInputBase-root": {
                color: colors.grey[100],
                cursor: "pointer",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.grey[100],
              },
            }}
          />
        </Box>

        <Box>
          <Typography
            variant={isNonMobile ? "h5" : "h6"}
            color={colors.grey[100]}
            marginBottom="5px"
          >
            Carreras
          </Typography>
          <TextField
            value={selectedCarreraRows}
            onClick={handlePopupOpenCarreras}
            placeholder="Seleccionar Carreras"
            fullWidth
            readOnly
            disabled={
              !selectedFacultadRows ||
              selectedFacultadRows.length === 0 ||
              selectedFacultadRows.every((value) => value === "CAA")
            }
            sx={{
              "& .MuiInputBase-root": {
                color: colors.grey[100],
                cursor: "pointer",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.grey[100],
              },
              "&.Mui-disabled .MuiInputBase-root": {
                color: colors.grey[500],
                cursor: "not-allowed",
              },
              "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: colors.grey[500],
              },
            }}
          />
        </Box>

        <Box>
          <Typography
            variant={isNonMobile ? "h5" : "h6"}
            color={colors.grey[100]}
            marginBottom="5px"
          >
            Año
          </Typography>
          <Select
            multiple
            sx={{
              width: isNonMobile ? "auto" : "100%",
              height: isNonMobile ? undefined : "40px",
              minWidth: "120px",
            }}
            value={selectedAno}
            onChange={handleAnoChange}
            renderValue={(selected) => selected.join(", ")}
          >
            {uniqueYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box>
          <Typography
            variant={isNonMobile ? "h5" : "h6"}
            color={colors.grey[100]}
            marginBottom="5px"
          >
            Semestre
          </Typography>
          <Select
            multiple
            sx={{
              width: isNonMobile ? "auto" : "100%",
              height: isNonMobile ? undefined : "40px",
              minWidth: "120px",
            }}
            value={selectedSemestre}
            onChange={handleSemestreChange}
            renderValue={(selected) => selected.join(", ")}
          >
            {uniqueSemestre.map((semestre) => (
              <MenuItem key={semestre} value={semestre}>
                {semestre}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box marginTop="30px">
          <Button
            variant="contained"
            color="error"
            onClick={handleClearFilters}
          >
            Limpiar Filtros
          </Button>
        </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 3"
          backgroundColor={colors.orangeAccent[500]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={totals.NumeroProgramas}
            subtitle="Programas"
            progress={programasPercentage}
            increase={`${(programasPercentage * 100).toFixed(0)}%`}
            icon={<AssignmentOutlinedIcon sx={{ fontSize: "30px" }} />}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.orangeAccent[500]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={totals.NumeroProyectos}
            subtitle="Proyectos"
            progress={proyectosPercentage}
            increase={`${(proyectosPercentage * 100).toFixed(0)}%`}
            icon={<FolderCopyOutlinedIcon sx={{ fontSize: "30px" }} />}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.orangeAccent[500]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={totals.NumeroActividades}
            subtitle="Actividades"
            progress={actividadesPercentage}
            increase={`${(actividadesPercentage * 100).toFixed(0)}%`}
            icon={<LocalActivityOutlinedIcon sx={{ fontSize: "30px" }} />}
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.orangeAccent[500]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={totals.NumeroIniciativas}
            subtitle="Iniciativas Emergentes"
            progress={iniciativasPercentage}
            increase={`${(iniciativasPercentage * 100).toFixed(0)}%`}
            icon={<VolunteerActivismOutlinedIcon sx={{ fontSize: "30px" }} />}
          />
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Foco Prioritario
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary[200]}
              >
                Número de actividades de VcM por foco prioritario
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <PieChart
              data={convertDataForPieChart(data.Foco)}
              useColorMapping={false}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Línea de Acción
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary[200]}
              >
                Número de actividades de VcM por líneas de acción
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box height="350px" m="10px 0 0 0">
            <BarChart
              isDashboard={true}
              data={data.LineaDeAccion}
              onClick={handleBarClick}
            />
          </Box>
        </Box>
        {selectedBar ? (
          <Box
            ml="100px"
            gridColumn="span 12"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  {selectedBar}
                </Typography>
              </Box>
              <Box></Box>
            </Box>
            <Box height="250px" m="-20px 0 0 0">
              <BarChart
                isDashboard={true}
                data={data.SubLineaDeAccion[selectedBar]}
              />
            </Box>
          </Box>
        ) : (
          ""
        )}

        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Ámbito
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary[200]}
              >
                Número de actividades de VcM por ámbito
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box height="350px" m="10px 0 0 0">
            <BarChart isDashboard={true} data={data.Ambito} />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Eje del P.E.I
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary[200]}
              >
                Número de actividades de VcM por Eje del P.E.I
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box height="350px" m="-20px 0 0 0">
            <BarChart isDashboard={true} data={data.PEI} />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Ciclo Formativo
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary[200]}
              >
                Número de actividades de VcM por Ciclo Formativo
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <PieChart
              data={convertDataForPieChart(data.TipoCicloFormativo)}
              useColorMapping={false}
            />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                ODS
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary[200]}
              >
                Número de actividades de VcM por ODS
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box height="350px" m="20px 0 0 0">
            <PieChart
              data={convertDataForPieChart(data.ODS)}
              useColorMapping={true}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Fuentes de Financiamiento
              </Typography>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary[200]}
              >
                Número de actividades de VcM por fuentes de financiamiento
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Box height="350px" m="-20px 0 0 0">
            <BarChart isDashboard={true} data={data.FuenteFinanciamiento} />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Datos geográficos de actividades de VcM
          </Typography>
          <Box height="400px">
            <GeographyChart isDashboard={true} data={data.Pais} />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}
          >
            Datos geográficos de actividades de VcM a nivel de Región
            Metropolitan.
          </Typography>
          <Box height="400px">
            <GeographyChartMetro isDashboard={true} data={data.Comuna} />
          </Box>
        </Box>
      </Box>

      <Popup
        open={openPopupFacultad}
        onClose={() => handlePopupCloseFacultad(selectedFacultadRows)}
        title={"Facultades"}
        buttonName={"Seleccionar"}
        handleSubmit={() => handlePopupSubmitFacultad(selectedFacultadRows)}
      >
        <TableFacultad
          selectedRows={selectedFacultadRows}
          onSelectionchange={handleFacultadSelectionChange}
        />
      </Popup>
      <Popup
        open={openPopupCarreras}
        onClose={() => handlePopupCloseCarreras(selectedCarreraRows)}
        title={"Carreras/Programas"}
        buttonName={"Seleccionar"}
        handleSubmit={() => handlePopupSubmitCarrera(selectedCarreraRows)}
      >
        <TableCarreras
          selectedRows={selectedCarreraRows}
          onSelectionchange={handleCarreraSelectionChange}
        />
      </Popup>
    </Box>
  );
};

export default Dashboard;
