import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDataContext } from "../../../context/DataContext";
import TooltipInfo from "../../../components/TooltipInfo";

const ArticulacionPEI = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  const { ejePEI, pei, selectedPEI, setSelectedPEI } = useDataContext();

  const ejePEIEje1 = ejePEI.filter((ejePEI) => ejePEI.id === 1);

  const ejePEIEje2 = ejePEI.filter((ejePEI) => ejePEI.id === 2);
  const ejePEIEje3 = ejePEI.filter((ejePEI) => ejePEI.id === 3);
  const ejePEIEje4 = ejePEI.filter((ejePEI) => ejePEI.id === 4);
  const ejePEIEje5 = ejePEI.filter((ejePEI) => ejePEI.id === 5);

  const peiEje1 = pei.filter((pei) => pei.id_eje === 1);
  const peiEje2 = pei.filter((pei) => pei.id_eje === 2);
  const peiEje3 = pei.filter((pei) => pei.id_eje === 3);
  const peiEje4 = pei.filter((pei) => pei.id_eje === 4);
  const peiEje5 = pei.filter((pei) => pei.id_eje === 5);

  const handlePEIChange = (event, peiId) => {
    if (event.target.checked) {
      setSelectedPEI([...selectedPEI, peiId]);
    } else {
      setSelectedPEI(selectedPEI.filter((id) => id !== peiId));
    }
  };

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      gridTemplateColumns="repeat(1, 1fr)"
      rowGap={0}
      sx={{
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "0px"}
            fontWeight="bold"
          >
            7. Articulación P.E.I{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Articulación P.E.I:
                  </Typography>
                  <br></br>En este punto se requiere determinar la contribución
                  de su proyecto de VcM al Plan Estratégico Institucional (PEI).
                  En el entendido que es el instrumento de gestión que guía el
                  desarrollo en consonancia con la misión, visión, valores y
                  objetivos de la institución. Su objetivo es optimizar
                  recursos, orientar procesos y lograr eficiencia y eficacia
                  para cumplir con los propósitos establecidos.
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(5, 1fr)",
          }}
        >
          <Box>
            <FormControl component="fieldset" variant="standard">
              {ejePEIEje1.map((ejePEI, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "3px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {ejePEI.descripcion}
                  </Typography>
                </FormLabel>
              ))}

              <FormGroup>
                {peiEje1.map((pei, id) => (
                  <FormControlLabel
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    key={pei.id}
                    control={
                      <Checkbox
                        color="default"
                        checked={selectedPEI.includes(pei.id)}
                        onChange={(event) => handlePEIChange(event, pei.id)}
                        value={pei.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={
                          isNonMobile ? "h6" : isNonMobileMedium ? "h7" : "h8"
                        }
                        color={colors.grey[100]}
                      >
                       
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {ejePEIEje2.map((ejePEI, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {ejePEI.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                {peiEje2.map((pei, id) => (
                  <FormControlLabel
                    key={pei.id}
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    control={
                      <Checkbox
                        color="default"
                        checked={selectedPEI.includes(pei.id)}
                        onChange={(event) => handlePEIChange(event, pei.id)}
                        value={pei.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                       
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {ejePEIEje3.map((ejePEI, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {ejePEI.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                {peiEje3.map((pei, id) => (
                  <FormControlLabel
                    key={pei.id}
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    control={
                      <Checkbox
                        color="default"
                        checked={selectedPEI.includes(pei.id)}
                        onChange={(event) => handlePEIChange(event, pei.id)}
                        value={pei.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                       
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {ejePEIEje4.map((ejePEI, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {ejePEI.descripcion}
                  </Typography>
                </FormLabel>
              ))}
              <FormGroup>
                {peiEje4.map((pei, id) => (
                  <FormControlLabel
                    key={pei.id}
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    control={
                      <Checkbox
                        color="default"
                        checked={selectedPEI.includes(pei.id)}
                        onChange={(event) => handlePEIChange(event, pei.id)}
                        value={pei.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                       
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormControl component="fieldset" variant="standard">
              {ejePEIEje5.map((ejePEI, id) => (
                <FormLabel sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}>
                  <Typography
                    variant={isNonMobile ? "h5" : "h6"}
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {ejePEI.descripcion}
                  </Typography>
                </FormLabel>
              ))}

              <FormGroup>
                {peiEje5.map((pei, id) => (
                  <FormControlLabel
                    key={pei.id}
                    sx={{ marginBottom: isNonMobile ? "10px" : "5px" }}
                    control={
                      <Checkbox
                        color="default"
                        checked={selectedPEI.includes(pei.id)}
                        onChange={(event) => handlePEIChange(event, pei.id)}
                        value={pei.id}
                        style={{
                          color: colors.grey[100],
                          marginRight: "5px",
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={isNonMobile ? "h6" : "h7"}
                        color={colors.grey[100]}
                      >
                       
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticulacionPEI;
