import React from "react";
import { useTheme, Box } from "@mui/material";
import { tokens } from "../theme";
import {
  DataGrid,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";


import CircularProgress from "@mui/material/CircularProgress";
import { useDataContext } from "../context/DataContext";

const TableAmbitos = ({ selectedRows, onSelectionchange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {ambitos, isLoading} = useDataContext();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "descripcion",
      headerName: "Ámbito",
      flex: 1,
      cellClassName: "ambito-column--cell",
    },
  ];


  function CustomToolbar() {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="3px"
      >
        <GridToolbarContainer>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Box>
    );
  }

  return (
    <Box
      height="60vh"
      sx={{
        ...(theme.palette.mode === "dark"
          ? {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[400],
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                // backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: "white",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "white",
              },
              "& .MuiTouchRipple-root": {
                color: "white",
              },
              "& .name-column--cell": {
                color: colors.orangeAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }
          : {
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: 1,
                borderColor: colors.grey[600],
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-row:hover": {
                // backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiDataGrid-row.Mui-selected:hover": {
                backgroundColor: colors.orangeAccent[400],
              },
              "& .MuiCheckbox-root": {
                color: "default",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "default",
              },
              "& .MuiTouchRipple-root": {
                color: "default",
              },
              "& .name-column--cell": {
                color: colors.orangeAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }),
      }}
    >
      {isLoading ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <DataGrid
          checkboxSelection
          rows={ambitos}
          columns={columns}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          slots={{ toolbar: CustomToolbar }}
          autoPageSize
          onRowSelectionModelChange={onSelectionchange}
          rowSelectionModel={selectedRows}
        />
      )}
    </Box>
  );
};

export default TableAmbitos;
