import { Box, Button, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import React, { useState } from "react";
import Popup from "../../../components/Popup";
import useMediaQuery from "@mui/material/useMediaQuery";
import DynamicRowObjetivo from "../../../components/DynamicRowObjetivo";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TooltipInfo from "../../../components/TooltipInfo";
import { useDataContext } from "../../../context/DataContext";

const DetalleDeLaAccion = ({
  touchedDiagnostico,
  errorsDiagnostico,
  touchedResultadosEsperados,
  errorsResultadosEsperados,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openPopupObjetivos, setOpenPopupObjetivos] = useState(false);
  const {
    valuesDiagnostico,
    setValuesDiagnostico,
    valuesResultadoEsperado,
    setValuesResultadoEsperado,
    isRowObjetivoAdded,
  } = useDataContext();
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");

  const handlePopupOpenObjetivos = () => {
    setOpenPopupObjetivos(true);
  };

  const handlePopupCloseObjetivos = () => {
    setOpenPopupObjetivos(false);
  };

  const onChangeDiagnostico = (e) => {
    const newValue = e.target.value;
    setValuesDiagnostico(newValue);
  };

  const onChangeResultadosEsperados = (e) => {
    const newValue = e.target.value;
    setValuesResultadoEsperado(newValue);
  };

  return (
    <Box
      height={isNonMobile ? "65vh" : isNonMobileMedium ? "65vh" : "60vh"}
      marginTop={isNonMobile ? "10px" : isNonMobileMedium ? "10px" : "0px"}
      display="grid"
      rowGap={1}
      gridTemplateColumns="repeat(1, 1fr)"
      sx={{
        "& > div": {
          gridColumn: isNonMobile ? undefined : "span 4",
        },
        "& label.Mui-focused": {
          color: colors.grey[100],
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.grey[100],
          },
          "&:hover fieldset": {
            borderColor: colors.orangeAccent[400],
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.orangeAccent[400],
          },
        },
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "3px" : "0px"}
            fontWeight="bold"
          >
            11. Diagnóstico{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Diagnóstico:
                  </Typography>
                  <br></br>Análisis detallado y sistemático de la situación
                  actual o del contexto relevante para el proyecto. Se centra en
                  identificar fortalezas, debilidades, oportunidades del medio
                  que se relacionan con el desarrollo o los resultados del
                  proyecto. (máximo 7.000 caracteres)<br></br>
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(1, 1fr)",
          }}
        >
          <Box>
            <Box>
              <Typography
                variant={isNonMobile ? "h5" : "h6"}
                color={colors.grey[100]}
                marginBottom="10px"
              >
                11.1. Diagnóstico/Necesidades del medio
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                multiline
                rows={isNonMobile ? 4 : isNonMobileMedium ? 4 : 3}
                variant="outlined"
                type="text"
                InputProps={{
                  sx: { fontSize: isNonMobile ? undefined : "12px" },
                }}
                label="Diagnostico/Necesidades del medio"
                onChange={onChangeDiagnostico}
                value={valuesDiagnostico}
                name="diagnostico"
                error={!!touchedDiagnostico && !!errorsDiagnostico}
                helperText={touchedDiagnostico && errorsDiagnostico}
                inputProps={{ maxLength: 7000 }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            fontWeight="bold"
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "5px"}
          >
            12. Objetivos{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Objetivo General:
                  </Typography>
                  <br></br>Redactar de manera clara y precisa lo que se busca
                  lograr al realizar el proyecto. Los objetivos deben ser
                  específicos, medibles, alcanzables, relevantes y con un tiempo
                  determinado. (SMART, por sus siglas en inglés) (máximo 5.000
                  caracteres)<br></br>
                  <br />
                  <Typography fontWeight="bold" variant="h7">
                    Objetivos específicos:
                  </Typography>
                  <br></br>Los objetivos específicos se enfocan en aspectos
                  concretos que se esperan lograr durante el desarrollo del
                  proyecto, proporcionando una guía clara y detallada para
                  alcanzar los resultados deseados. (máximo 5.000 caracteres)
                </p>
              }
            />
          </Typography>
        </Box>
        {!isRowObjetivoAdded ? (
          <Box>
            {theme.palette.mode === "dark" ? (
              <Button
                sx={{
                  width: "20%",
                  height: isNonMobile
                    ? undefined
                    : isNonMobileMedium
                    ? "30px"
                    : "25px",
                }}
                onClick={handlePopupOpenObjetivos}
                color="secondary"
                variant="outlined"
              >
                Seleccionar Objetivos
              </Button>
            ) : (
              <Button
                sx={{
                  width: "20%",
                  height: isNonMobile
                    ? undefined
                    : isNonMobileMedium
                    ? "30px"
                    : "25px",
                }}
                onClick={handlePopupOpenObjetivos}
                color="secondary"
                variant="contained"
              >
                Seleccionar Objetivos
              </Button>
            )}
          </Box>
        ) : (
          <Box display="flex" alignItems="center" columnGap="25px">
            {theme.palette.mode === "dark" ? (
              <Button
                sx={{
                  width: "20%",
                  height: isNonMobile
                    ? undefined
                    : isNonMobileMedium
                    ? "30px"
                    : "25px",
                }}
                onClick={handlePopupOpenObjetivos}
                color="secondary"
                variant="outlined"
              >
                Seleccionar Objetivos
              </Button>
            ) : (
              <Button
                sx={{
                  width: "20%",
                  height: isNonMobile
                    ? undefined
                    : isNonMobileMedium
                    ? "30px"
                    : "25px",
                }}
                onClick={handlePopupOpenObjetivos}
                color="secondary"
                variant="contained"
              >
                Seleccionar Objetivos
              </Button>
            )}
            <CheckCircleIcon color="success" />
          </Box>
        )}
      </Box>
      <Box>
        <Box>
          <Typography
            variant={isNonMobile ? "h4" : "h5"}
            fontWeight="bold"
            color={colors.grey[100]}
            marginBottom={isNonMobile ? "10px" : "5px"}
          >
            13. Resultados Esperados{" "}
            <TooltipInfo
              title={
                <p>
                  <Typography fontWeight="bold" variant="h7">
                    Resultados esperados:
                  </Typography>
                  <br></br>Descripción de los logros o cambios que se anticipan
                  como consecuencia directa del proyecto. Estos resultados deben
                  ser coherentes con los objetivos planteados y pueden ser
                  cualitativos o cuantitativos. (Máximo 7.000 caracteres)
                  <br></br>
                </p>
              }
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            columnGap: 3,
            rowGap: 1,
            gridTemplateColumns: "repeat(1, 1fr)",
          }}
        >
          <Box>
            <TextField
              fullWidth
              multiline
              rows={isNonMobile ? 4 : isNonMobileMedium ? 4 : 3}
              variant="outlined"
              type="text"
              InputProps={{
                sx: { fontSize: isNonMobile ? undefined : "12px" },
              }}
              label="Resultados Esperados"
              onChange={onChangeResultadosEsperados}
              value={valuesResultadoEsperado}
              name="resultadoEsperado"
              error={
                !!touchedResultadosEsperados && !!errorsResultadosEsperados
              }
              helperText={
                touchedResultadosEsperados && errorsResultadosEsperados
              }
              inputProps={{ maxLength: 7000 }}
            />
          </Box>
        </Box>
      </Box>
      <Popup
        open={openPopupObjetivos}
        onClose={handlePopupCloseObjetivos}
        title={"Objetivos generales y específicos"}
        buttonName={isRowObjetivoAdded ? "Guardar" : "Cerrar"}
        handleSubmit={handlePopupCloseObjetivos}
        maxWidth="xl"
      >
        <DynamicRowObjetivo />
      </Popup>
    </Box>
  );
};

export default DetalleDeLaAccion;
