import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  return (
    <Box>
      <Typography
        variant={isNonMobile ? "h2" : "h3"}
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ m: isNonMobile ? "0 0 5px 0" :  "0 0 1px 0"}}
      >
        {title}
      </Typography>
      <Typography variant={isNonMobile ? "h5" : "h6"} color={colors.orangeAccent[400]} fontWeight="bold">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
