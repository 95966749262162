import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Example() {
  const isNonMobile = useMediaQuery("(min-width:1920px)");
  const isNonMobileMedium = useMediaQuery("(min-width:1500px)");
  return (
    <Carousel
      animationHandler="fade"
      showThumbs={false}
      autoPlay
      width={isNonMobile ? "1200px" : isNonMobileMedium ? "1000px" : "900px"}
      infiniteLoop
      showStatus={false}
      transitionTime={950}
    >
      <div>
        <img
          src="../../assets/pictures/Bienvenido.png"
          alt="bienvenido"
          height={isNonMobile ? "650px" : isNonMobileMedium ? "570px" : "500px"}
        />
        {/* <p className="legend">Legend 1</p> */}
      </div>
      <div>
        <img
          src="../../assets/pictures/Mision.png"
          alt="mision"
          height={isNonMobile ? "650px" : isNonMobileMedium ? "570px" : "500px"}
        />
        {/* <p className="legend">Legend 2</p> */}
      </div>
      <div>
        <img
          src="../../assets/pictures/Vision.png"
          alt="vision"
          height={isNonMobile ? "650px" : isNonMobileMedium ? "570px" : "500px"}
        />
        {/* <p className="legend">Legend 3</p> */}
      </div>
    </Carousel>
  );
}

export default Example;
